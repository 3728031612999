import React, { Component, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, useHistory, useLocation } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import LoaderWidget from '../../components/Loader';
import srxLogo from '../../assets/logos/white-p-and-gray.png';
import { UserContext } from '../../contexts/UserContext';
import { toast } from 'react-toastify';
import { SrxApi } from '../../api/SrxApi';
// import { Helmet } from "react-helmet";

const ResetPassword = props => {

    let history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { faciToken } = useContext(UserContext);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // useEffect(() => {
    //     if (user) {
    //         console.log('logged user')
    //         if(props.location.state && props.location.state.from)
    //             props.history.replace(props.location.state.from)
    //             else
    //             props.history.push('/')
    //     }
    // }, [user])

    useEffect(() => {
        if(!query.get('email') || !query.get('token'))
            history.replace('/account/login')
        if(faciToken)
            history.replace('/dashboard')
    }, [faciToken])

    /**
     * Handles the submit
     */
    const handleValidSubmit = (event, values) => {

        if(password !== confirmPassword)
            return toast.error('Passwords do not match')

        let resetPasswordPayload = {
            email: query.get('email'),
            token: query.get('token'),
            password,
            confirmPassword
        }

        let resetPasswordToast = toast.loading('Loading...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/resetPassword', resetPasswordPayload)
        .then(({data}) => {
            toast.update(resetPasswordToast, 
                { 
                    render: data.message,
                    type: "success", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            history.replace('/account/login') 
        })
        .catch(err => {
            toast.update(resetPasswordToast, 
            { 
                render: err.response.data.message,
                type: "error", 
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: null,
                closeOnClick: null,
            });
        })
    };

    return (
        <React.Fragment>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                                        <Link to="/">
                                            <span>
                                                <img src={srxLogo} alt="" height="45" />
                                            </span>
                                        </Link>
                                    </div>

                                    <CardBody className="p-4 position-relative">
                                        {/* preloader */}

                                        <div className="text-center w-75 m-auto">
                                            <h3 className="text-dark-50 text-center mt-0 font-weight-bold mb-3">
                                                Reset Password
                                            </h3>
                                        </div>

                                        <div className="text-center w-75 m-auto">
                                            <h5 className="text-center mt-0 font-weight-bold mb-3">
                                                Email: {query.get('email')}
                                            </h5>
                                        </div>

                                        <AvForm onValidSubmit={() => handleValidSubmit()}>

                                            <AvGroup>
                                                <Label for="password">New Password</Label>
                                                <AvField
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter new password"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={password}
                                                    required
                                                    validate={{
                                                        minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                                                    }}
                                                />
                                            </AvGroup>

                                            <AvGroup>
                                                <Label for="confirm-password">Confirm New Password</Label>
                                                <AvField
                                                    type="password"
                                                    name="confirm-password"
                                                    id="confirm-password"
                                                    placeholder="Confirm new password"
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    value={confirmPassword}
                                                    required
                                                    validate={{
                                                        minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                                                    }}
                                                />
                                                {/* <AvFeedback>This field is invalid</AvFeedback> */}
                                            </AvGroup>

                                            <FormGroup>
                                                <Button color="success">Reset Password</Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* <Row className="mt-1">
                            <Col className="col-12 text-center">
                                <p className="text-muted">
                                    Don't have an account?{' '}
                                    <Link to="/account/register" className="text-muted ml-1">
                                        <b>Register</b>
                                    </Link>
                                </p>
                            </Col>
                        </Row> */}
                    </Container>
                </div>
        </React.Fragment>
    );
}

export default ResetPassword;
