import axios from 'axios';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';

export const UserRolesContext = createContext();

export const UserRolesProvider = (props) => {

    let history = useHistory();

    const { faciToken, setFaciToken } = useContext(UserContext);

    const [pharmacyUserRoles, setPharmacyUserRoles] = useState(null);

    const [facilityUserRoles, setFacilityUserRoles] = useState(null)

    useEffect(() => {
        if (faciToken) {
            axios.get(process.env.REACT_APP_API_BASE_URL + '/roles', {
                headers: {
                    'Authorization': 'bearer ' + faciToken
                },
                params: {
                    entity: 'pharmacy'
                }
            })
                .then(res => {
                    setPharmacyUserRoles(res.data.roles.map(role => ({ value: role.name, label: role.label, disabled: !!(role.name.toLowerCase() === 'billing' || role.name.toLowerCase() === 'admissions') })))
                })
                .catch(err => {
                    setPharmacyUserRoles([])
                })

            axios.get(process.env.REACT_APP_API_BASE_URL + '/roles', {
                headers: {
                    'Authorization': 'bearer ' + faciToken
                },
                params: {
                    entity: 'facility'
                }
            })
                .then(res => {
                    setFacilityUserRoles(res.data.roles.map(role => ({ value: role.name, label: role.label, disabled: !!(role.name.toLowerCase() === 'don' || role.name.toLowerCase() === 'nursing' || role.name.toLowerCase() === 'businessoffice') })))
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        localStorage.removeItem('FACI_TOKEN');
                        setFaciToken(null);
                        history.replace('/account/login')
                    }
                })
        } else {
            setPharmacyUserRoles(null)
            setFacilityUserRoles(null)
        }
    }, [faciToken])

    return (
        <UserRolesContext.Provider value={{ facilityUserRoles, pharmacyUserRoles }}>
            {props.children}
        </UserRolesContext.Provider>
    );
}