import axios from 'axios';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';

export const SelectedPharmaciesContext = createContext();

export const SelectedPharmaciesProvider = (props) => {

    let history = useHistory();

    const { faciToken, setFaciToken } = useContext(UserContext);

    const [selectedPharmacies, setSelectedPharmacies] = useState([]);

    const [pharmacies, setPharmacies] = useState([])

    useEffect(() => {
        if(faciToken){
            axios.get(process.env.REACT_APP_API_BASE_URL + '/pharmacies',{
                headers: {
                    'Authorization': 'bearer ' + faciToken
                }
            })
            .then(res => {
                setPharmacies(res.data.pharmacies)
            })
            .catch(err => {
                if(err.response?.status === 401){
                    localStorage.removeItem('FACI_TOKEN');
                    setFaciToken(null);
                    history.replace('/account/login')
                }
            })
        } else {
            setSelectedPharmacies([])
        }
    }, [faciToken])
    
    return (
        <SelectedPharmaciesContext.Provider value={{ selectedPharmacies, setSelectedPharmacies, pharmacies }}>
            {props.children}
        </SelectedPharmaciesContext.Provider>
    );
}