import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export const SingleWidgetActiveTabContext = createContext();

export const SingleWidgetActiveTabProvider = (props) => {

    const history = useHistory();

    const location = useLocation();

    const [activeTab, setActiveTab] = useState('current');

    // const query = new URLSearchParams(location.search);
    
    // useEffect(() => {

    //     let tab = query.get('activeTab');

    //     if(tab){
    //         setActiveTab(tab)
    //     } else {
    //         // const locationObject = {
    //         //     pathname: location.pathname,
    //         //     search: 'activeTab=current'
    //         // }

    //         // history.push(locationObject)

    //     }
    // }, [location.search])
    
    return (
        <SingleWidgetActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
            {props.children}
        </SingleWidgetActiveTabContext.Provider>
    );
}