import React, { createContext, useEffect, useContext } from 'react';
import { UserContext } from './contexts/UserContext';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import useAlertsUserSettings from './custom-hooks/useAlertsSortOrder';
export const LogrocketContext = createContext();

export const LogrocketProvider = (props) => {
    const { user } = useContext(UserContext);
    useAlertsUserSettings()
    useEffect(() => {
        // if (process.env.REACT_APP_ENV !== 'dev') {
        //     LogRocket.init('vs8znn/srx365');
        // }
        setupLogRocketReact(LogRocket);
        if (user) {
            LogRocket.identify(user?.email, {
                name: user?.displayName,
                email: user?.email,
                userRole: user?.userRole,
                userType: user?.userType,
                betaUser: user?.betaUser
            });
        }
    }, [user])

    return (
        <LogrocketContext.Provider>
            {props.children}
        </LogrocketContext.Provider>
    );
}