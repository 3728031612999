export const SecureForms = {
    SPRX: [
        {
            label: 'OTC Override Authorization Form',
            id: 'd7df42ff',
        },
        {
            label: 'Out on Pass Medication Form',
            id: 'cd41e4fb',
        },
        {
            label: 'Reorder Medication Form',
            id: '1a3be992',
        },
        {
            label: 'Vaccine Request Form',
            id: '08c17938',
        },
    ],
    UPNY: [
        {
            label: 'OTC Override Authorization Form',
            id: 'cb8cbfb8',
        },
        {
            label: 'Out on Pass Medication Form',
            id: '561c8991',
        },
        {
            label: 'Reorder Medication Form',
            id: 'bdf14c5b',
        },
        {
            label: 'Vaccine Request Form',
            id: '921fadf6',
        },
    ],
    OHIO: [
        {
            label: 'OTC Override Authorization Form',
            id: '5543e1f1',
        },
        {
            label: 'Out on Pass Medication Form',
            id: '3e8dff08',
        },
        {
            label: 'Reorder Medication Form',
            id: '8d985c70',
        },
        {
            label: 'Vaccine Request Form',
            id: 'e3926ea4',
        },
    ],
    PARX: [
        {
            label: 'OTC Override Authorization Form',
            id: '4b8bf282',
        },
        {
            label: 'Out on Pass Medication Form',
            id: 'de6e7609',
        },
        {
            label: 'Reorder Medication Form',
            id: '1922402c',
        },
        {
            label: 'Vaccine Request Form',
            id: '14dc85f1',
        },
    ],

    FLN: [
        {
            label: 'OTC Override Authorization Form',
            id: 'bbc343cd',
        },
        {
            label: 'Out on Pass Medication Form',
            id: 'f666077f',
        },
        {
            label: 'Reorder Medication Form',
            id: '093de2a6',
        },
        {
            label: 'Vaccine Request Form',
            id: '7d38358e',
        }
    ],
    FLL: [
        {
            label: 'OTC Override Authorization Form',
            id: '6bcf4e83',
        },
        {
            label: 'Out on Pass Medication Form',
            id: 'e6c4bc39',
        },
        {
            label: 'Reorder Medication Form',
            id: '8eb11c19',
        },
        {
            label: 'Vaccine Request Form',
            id: '0f39e85e',
        }
    ],
    FLW: [
        {
            label: 'OTC Override Authorization Form',
            id: 'f43d9d90',
        },
        {
            label: 'Out on Pass Medication Form',
            id: 'cbf49024',
        },
        {
            label: 'Reorder Medication Form',
            id: 'd54443a9',
        },
        {
            label: 'Vaccine Request Form',
            id: '0cd3d320',
        }
    ],
    ALRX: [
        {
            label: 'OTC Override Authorization Form',
            id: '8e10be42',
        },
        {
            label: 'Out on Pass Medication Form',
            id: '521cd7a2',
        },
        {
            label: 'Reorder Medication Form',
            id: '04915ffd',
        },
        {
            label: 'Vaccine Request Form',
            id: '06b1866b',
        }
    ],

};
