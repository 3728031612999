import React, { useEffect } from 'react';

const FrameworkVision = props => {

    useEffect(() => {
        window.open("https://vision.srxlogin.com/FrameworkVision")
        props.history.goBack()
    }, [])

    return <></>
}

export default FrameworkVision;