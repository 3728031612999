import React, { useContext } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import classNames from 'classnames';
import notes from '../release-notes/release-notes.json';
import DOMPurify from 'dompurify';
import { UserContext } from '../contexts/UserContext';

const ReleaseNotes = props => {

    const { user } = useContext(UserContext);

    return(
        <>
        <Row>
            <Col>
                <h2>Release Notes</h2>
            </Col>
        </Row>
        <Row className='justify-content-center mt-3'>
            <Col md={10} lg={8} xl={6}>
        {/* <Card>
        <CardHeader 
            className='d-flex align-items-center justify-content-center bg-primary text-white position-relative gradient-modal' 
            style={{ borderTopLeftRadius: '11px', borderTopRightRadius: '11px',
            background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)"
            }}
            >  
            <h4>
            Release Notes
            </h4>
        </CardHeader>
            <CardBody> */}
            {notes.filter(note => !note.pharmUserOnly ? true : user?.userType === 'PharmacyUser' ? true : false).sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1).map(note => (
                <Card className="ribbon-box">
                    <CardBody>
                        <div className={classNames('ribbon', 'ribbon-' + 'secondary', 'mb-0', 'float-left')}>
                            <i className="mdi mdi-balloon mr-1"></i> {note.type} - ID: {note.id}
                        </div>
                        {note.pharmUserOnly && <div className={classNames('ribbon', 'ribbon-' + 'secondary', 'mb-0', 'float-left', 'mt-2')}>
                            Admin Only
                        </div>}
                        <h5 className={classNames('text-' + 'dark', 'mt-0', 'float-right')}>{note.title}</h5>
                        <div className="ribbon-content" style={{ paddingTop: '36px' }}>
                            <p className="mb-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.description)}}>
                            </p>
                        </div>
                        <hr />
                        <div className='text-muted'>
                            <b>{note.date}</b> - <b>By: {note.author}</b>
                        </div>
                    </CardBody>
                </Card>
            ))}
        {/* <Card className="ribbon-box">
            <CardBody>
                <div className={classNames('ribbon-two', 'ribbon-two-' + 'primary')}>
                    <span>Ribbon 1</span>
                </div>
                <p className="mb-0">
                    Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In
                    egestas mattis dui. Aliquam mattis dictum aliquet. Nulla sapien mauris, eleifend et sem ac, commodo
                    dapibus odio.
                </p>
            </CardBody>
        </Card> */}
        {/* </CardBody>
        </Card> */}
        </Col>
        </Row>
        </>
    )
}

export default ReleaseNotes;