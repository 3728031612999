import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown,
    Badge,
    InputGroup, InputGroupAddon
} from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { UserContext } from '../../contexts/UserContext';
import { SelectedFacilitiesContext } from '../../contexts/SelectedFacilitiesContext';
import { Icon } from '@iconify/react';
import roundDeleteForever from '@iconify/icons-ic/round-delete-forever';
import accountEditOutline from '@iconify/icons-mdi/account-edit-outline';
import mdiAccountCogOutline from '@iconify/icons-mdi/account-cog-outline';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import Select from 'react-select';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { SrxApi } from '../../api/SrxApi';
import officeBuildingOutline from '@iconify/icons-mdi/office-building-outline';
import lockReset from '@iconify/icons-mdi/lock-reset';
import MaskedInput from 'react-text-mask';
import { SelectedPharmaciesContext } from '../../contexts/SelectedPharmaciesContext';
import { UserRolesContext } from '../../contexts/UserRolesContext';
import cloneDeep from 'lodash.clonedeep';
import accountIcon from '@iconify/icons-mdi/account';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import { confirm } from '../../utility-components/ConfirmModal';
import { Typeahead } from 'react-bootstrap-typeahead';
import deleteIcon from '@iconify/icons-mdi/delete';
import Settings from '../UserSettings'

const PharmacyUsers = () => {

    let history = useHistory();

    const { faciToken, setFaciToken, user } = useContext(UserContext);

    useEffect(() => {
        if (user?.userType !== 'PharmacyUser') {
            history.replace('/dashboard')
        }
    }, [user])

    const [users, setUsers] = useState([]);
    const [deletedUser, setDeletedUser] = useState(null);

    const [numberPharmacies, setNumberPharmacies] = useState(user.numberPharmacies);


    const [userPharmModal, setUserPharmModal] = useState({
        isOpen: false,
        user: null
    })

    const [entityId, setEntityId] = useState('');

    const [deleteUserModal, setDeleteUserModal] = useState({
        isOpen: false,
        data: ''
    });
    
    
    function closeDeleteUserModal() {
        setDeleteUserModal({
            isOpen: false,
            data: ''
        });
    }

    function closeUserPharmModal() {
        setUserPharmModal({
            isOpen: false,
            user: null
        })
    }


    const [editUserSettingsModal, setEditUserSettingsModal] = useState({
        isOpen: false,
        data: {
            user: null
        }
    });

    function closeEditUserSettingsModal() {
        setEditUserSettingsModal({
            isOpen: false,
            data: {
                user: null
            }
        });
    }

    const [editProfileModal, setEditProfileModal] = useState({
        isOpen: false,
        data: {
            user: null,
            numberPharmacies: 0
        }
    });

    function closeEditProfileModal() {
        setEditProfileModal({
            isOpen: false,
            data: {
                user: null,
                numberPharmacies: user.numberPharmacies
            }
        });
    }

  


    const [searchTerm, setSearchTerm] = useState('');

    const [createPharmacyUserModalIsOpen, setCreatePharmacyUserModalIsOpen] = useState(false);

    const [createPharmacyUserModalKey, setCreatePharmacyUserModalKey] = useState(false);

    // this function only closes the modal and does not open it
    function closeCreatePharmacyUserModal() {
        setCreatePharmacyUserModalIsOpen(false)
        // reset the key of the modal when closing it in order to reset the data. The timeout is added for ux purposes to allow the modal to close before resetting it
        setTimeout(() => setCreatePharmacyUserModalKey(Date.now()), 500)
    }

    const [resetPasswordModal, setResetPasswordModal] = useState({
        isOpen: false,
        data: ''
    });

    const { pharmacies } = useContext(SelectedPharmaciesContext);

    function closePasswordModal() {
        setResetPasswordModal({
            isOpen: false,
            data: ''
        })
    }

    function getPharmacyUsers() {
        console.log("getPharmacyUsers function called");

        setUsers(null)
        SrxApi.get('/getpharmacyusers')
            .then(res => {
                setUsers(res.data.users)
                console.log("Updated users state:", res.data.users);
            })
            .catch(err => {
                setUsers([])
                toast.error('Error retrieving pharmacy users')
                if (err.response?.status === 401) {
                    localStorage.removeItem('FACI_TOKEN');
                    setFaciToken(null);
                    history.replace('/account/login')
                }
            })
    }

    useEffect(() => {
        console.log("useEffect is running");
        getPharmacyUsers()
    }, [])


    function searchUsers(array) {
        if (searchTerm) {
            let results = array.filter(user => (user.displayName && user.displayName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) || user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }

    const [sortBy, setSortBy] = useState({
        sortField: 'displayName',
        sortDirection: 'asc'
    })

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'email':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.email < b.email ? -1 : 1)
                else
                    return array.sort((a, b) => a.email > b.email ? -1 : 1)
            case 'displayName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.displayName < b.displayName ? -1 : 1)
                else
                    return array.sort((a, b) => a.displayName > b.displayName ? -1 : 1)
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    const canResetPassword = (user_) => {
        // console.log(users)
        // console.log(user_);
        let signedInUser = users?.find(u => u.email === user.email); //returns signed in user
        if (!signedInUser) {
            // console.log("User not found");
            return false;
        }
        let signedInUserRoles = signedInUser?.pharmRole || []; //returns pharmRole array/s of signed in user
        let selectedUserRoles = user_?.pharmRole || []; //returns pharmRole array/s of selected user
        function findMatchingPharid(signedInUserRoles, selectedUserRoles) {
            let matchingPharid = [];
            // Loop through the pharmRole array of the first user
            for (const role1 of signedInUserRoles) {
                const pharid1 = role1.pharid;

                for (const role2 of selectedUserRoles) {
                    const pharid2 = role2.pharid;

                    if (pharid1 === pharid2) {
                        matchingPharid.push(pharid1);
                    }
                }
            }

            return matchingPharid;

        }
        let hasMatchingPharid = findMatchingPharid(signedInUserRoles, selectedUserRoles) // returns common pharid of both users
        if (hasMatchingPharid.length === 0) {
            // console.log("No matching pharid found.");
            return false;
        }
        let matchingPharmacy = pharmacies?.filter((pharmacy) => hasMatchingPharid.includes(pharmacy.pharmID)); //returns common pharmacies!
        let selectedUserRole = [...new Set(user_?.pharmRole.map(r => r.role))]; //returns selected user role/s without duplicates
        let permissionRole = selectedUserRole.map(role => `user_Edit${role}`); // returns user_EditCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingPharmacy.map((pharmacy) => pharmacy.userPermissions); //returns all user permissions
        for (let key of permissionRole) {
            let hasPharmacyPermissions = allUserPermissions.some((permissions) => permissions && permissions.hasOwnProperty(key));
            let hasAnyPermission = false;
            if (matchingPharmacy && hasPharmacyPermissions) {
                console.log(`User has permission for ${permissionRole}: ${hasPharmacyPermissions}`);
                hasAnyPermission = true;
                return true;
            } else {
                // console.log("No matching pharmacies found.");
                return false;
            }
        }

    }

    const canDelete = (user_) => {
        console.log(users)
        console.log(user_);
        let signedInUser = users?.find(u => u.email === user.email); //returns signed in user
        if (!signedInUser) {
            console.log("User not found");
            return false;
        }
        let signedInUserRoles = signedInUser?.pharmRole || []; //returns pharmRole array/s of signed in user
        let selectedUserRoles = user_?.pharmRole || []; //returns pharmRole array/s of selected user
        function findMatchingPharid(signedInUserRoles, selectedUserRoles) {
            let matchingPharid = [];
            // Loop through the pharmRole array of the first user
            for (const role1 of signedInUserRoles) {
                const pharid1 = role1.pharid;

                for (const role2 of selectedUserRoles) {
                    const pharid2 = role2.pharid;

                    if (pharid1 === pharid2) {
                        matchingPharid.push(pharid1);
                    }
                }
            }

            return matchingPharid;

        }
        let hasMatchingPharid = findMatchingPharid(signedInUserRoles, selectedUserRoles) // returns common pharid of both users
        if (hasMatchingPharid.length === 0) {
            console.log("No matching pharid found.");
            return false;
        }
        let matchingPharmacy = pharmacies?.filter((pharmacy) => hasMatchingPharid.includes(pharmacy.pharmID)); //returns common pharmacies!
        let selectedUserRole = [...new Set(user_?.pharmRole.map(r => r.role))]; //returns selected user role/s without duplicates
        let permissionRole = selectedUserRole.map(role => `user_Delete${role}`); // returns user_DeleteCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingPharmacy.map((pharmacy) => pharmacy.userPermissions); //returns all user permissions
        for (let key of permissionRole) {
            let hasPharmacyPermissions = allUserPermissions.some((permissions) => permissions && permissions.hasOwnProperty(key));
            let hasAnyPermission = false;
            if (matchingPharmacy && hasPharmacyPermissions) {
                console.log(`User has permission for ${permissionRole}: ${hasPharmacyPermissions}`);
                hasAnyPermission = true;
                return true;
            } else {
                console.log("No matching pharmacies found.");
                return false;
            }
        }

    }

    const canChangeUserSettings = (user_) => {
        let thePermission = 'allowToSetSettingsForUsers'
        let signedInUser = users?.find(u => u.email === user.email); //returns signed in user

        let signedInUserRoles = signedInUser?.pharmRole || []; //returns pharmRole array/s of signed in user
        let selectedUserRoles = user_?.pharmRole || []; //returns pharmRole array/s of selected user

        function findMatchingPharid(signedInUserRoles, selectedUserRoles) {
            let matchingPharid = [];
            // Loop through the pharmRole array of the first user
            for (const role1 of signedInUserRoles) {
                const pharid1 = role1.pharid;

                for (const role2 of selectedUserRoles) {
                    const pharid2 = role2.pharid;

                    if (pharid1 === pharid2) {
                        matchingPharid.push(pharid1);
                    }
                }
            }
            return matchingPharid;
        }
        let hasMatchingPharid = findMatchingPharid(signedInUserRoles, selectedUserRoles) // returns common pharid of both users
        if (hasMatchingPharid.length === 0) {
            return false;
        }
        let matchingPharmacy = pharmacies?.filter((pharmacy) => hasMatchingPharid.includes(pharmacy.pharmID)); //returns common pharmacies!
        return (matchingPharmacy[0]?.userPermissions[thePermission])
    }

    const canEditRole = (userPharmacies, role, action) => {
        console.log(userPharmacies)
        console.log(role)
        let signedInUser = users?.find(u => u.email === user.email); //returns signed in user
        let signedInUserRoles = signedInUser?.pharmRole; //returns pharmRole array/s of signed in user
        let selectedUserRole = role.value; //returns role of selected user
        let commonPharmID = pharmacies?.find(p => p.pharmID === userPharmacies) // returns pharmacy object
        if (commonPharmID) {
            let permissions = commonPharmID.userPermissions; // returns dictionary of user permissions
            let hasPermission = false;
            if (action === 'roleChange') {
                let permissionRole = `user_Edit${selectedUserRole}`; // returns user_EditCustomerService
                for (let key in permissions) {
                    if (key === permissionRole && permissions.hasOwnProperty(key) === true) {
                        hasPermission = true;
                        break;
                    }
                }
                if (hasPermission) {
                    console.log(`User has permission for ${permissionRole}: ${hasPermission}`);
                    return true;
                } else {
                    console.log(`User does not have permission for ${permissionRole}: ${hasPermission}`);
                    return false;
                }

            } else if (action === 'delete') {
                let permissionDelete = `user_Delete${selectedUserRole}`;
                for (let key in permissions) {
                    if (key === permissionDelete && permissions.hasOwnProperty(key) === true) {
                        hasPermission = true;
                        break;
                    }
                }
                if (hasPermission) {
                    console.log(`User has permission for ${permissionDelete}: ${hasPermission}`);
                    return true;
                } else {
                    console.log(`User does not have permission for ${permissionDelete}: ${hasPermission}`);
                    return false;
                }
            }
        } else {
            console.log("No matching pharmacies found.");
            return false;
        }
    }
    console.log(user)

    if (!users)
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading Pharmacy Users...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )

    return (
        <>
            <div className='mt-3 mx-2'>
                <h2>Pharmacy Users</h2>
                <Card>
                    <CardBody>
                        {/* <h4 className="header-title">Product List</h4>
                <p className="text-muted font-14 mb-4">A list of all existing Greenbowl Products</p> */}
                        <Row className="mb-2 d-flex justify-content-between">
                            <Col xs={'auto'}>
                                <Button color='primary'
                                    onClick={() => {
                                        setCreatePharmacyUserModalIsOpen(true)
                                    }}
                                >
                                    <i className="mdi mdi-plus-circle mr-2"></i>Add Pharmacy User
                                </Button>
                            </Col>
                            <Col className="text-right" md={5}>
                                <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    <Label className='mr-2 mb-0'><b>Search:</b></Label>
                                    <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Table className="mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th onClick={() => onClickSort('displayName')} className="th-hover">Name {sortBy.sortField === 'displayName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                    <th onClick={() => onClickSort('email')} className="th-hover">Email {sortBy.sortField === 'email' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                    <th>Phone</th>
                                    <th>Pharmacies</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortByField(searchUsers(users))
                                    .map((user_, index) => {
                                        return (
                                            <tr key={index} >
                                                <th scope="row" style={{ verticalAlign: 'middle' }}>{user_.displayName}{(user_.email === user.email) && <Badge color="dark" className='ml-1' style={{ fontSize: '12px' }}>Self</Badge>}</th>
                                                <td style={{ verticalAlign: 'middle' }}>{user_.email}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{formatPhoneNumber(user_.phone)}</td>
                                                {/* <td style={{ verticalAlign: 'middle' }}><b><i>{[...new Set(user_.roles)] ? [...new Set(user_.roles)][0] : ''}</i></b></td> */}
                                                <td style={{ verticalAlign: 'middle' }} className="position-relative">
                                                    <b>{user_.numberPharmacies}</b>
                                                </td>
                                                <td className='position-relative'>
                                                    <UncontrolledButtonDropdown className="position-absolute" style={{ right: '20px', top: '5px', cursor: 'pointer' }}>
                                                        <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                                            <Icon icon={dotsHorizontal} width="23" height="23" />
                                                        </DropdownToggle>
                                                        <DropdownMenu right style={{ minWidth: 'max-content' }}>
                                                            {/* <DropdownItem className='px-3 py-1'
                                            ><Icon icon={accountDetails} width="20" height="20" className="mr-1"/>Edit Details</DropdownItem> */}
                                                            <DropdownItem className='px-3 py-1'
                                                                onClick={() => setUserPharmModal({
                                                                    isOpen: true,
                                                                    user: user_
                                                                })}
                                                            ><Icon icon={officeBuildingOutline} width="20" height="20" className="mr-1" />View / Edit Pharmacies</DropdownItem>
                                                            {canResetPassword(user_) &&
                                                                <DropdownItem className='px-3 py-1'
                                                                    //    disabled={canResetPassword(user_)}
                                                                    onClick={() => setResetPasswordModal({
                                                                        isOpen: true,
                                                                        data: user_

                                                                    })}
                                                                ><Icon icon={lockReset} width="20" height="20" className="mr-1" />Reset Password</DropdownItem>

                                                            }
                                                            {canResetPassword(user_) &&
                                                                <DropdownItem className='px-3 py-1'
                                                                    onClick={() => setEditProfileModal({
                                                                        isOpen: true,
                                                                        data: user_

                                                                    })}
                                                                ><Icon icon={accountEditOutline} width="20" height="20" className="mr-1" />Edit Name and Phone</DropdownItem>

                                                            }
                                                            {canChangeUserSettings(user_) &&
                                                                <DropdownItem className='px-3 py-1'
                                                                    onClick={() => setEditUserSettingsModal({
                                                                        isOpen: true,
                                                                        data: user_

                                                                    })}
                                                                ><Icon icon={mdiAccountCogOutline} width="20" height="20" className="mr-1" />Edit User Settings</DropdownItem>

                                                            }
                                                            {canDelete(user_) && 
                                                                        <DropdownItem className='px-3 py-1'
                                                                            onClick={() => setDeleteUserModal({
                                                                                isOpen: true,
                                                                                data: user_
                                                                            })}
                                                                        ><Icon icon={roundDeleteForever} width="20" height="20" className="mr-1" />Delete User</DropdownItem>
                                                                    }
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <CreatePharmacyUserModal isOpen={createPharmacyUserModalIsOpen} toggle={closeCreatePharmacyUserModal} key={createPharmacyUserModalKey}
                    getPharmacyUsers={getPharmacyUsers}
                />
                <ResetPasswordModal isOpen={resetPasswordModal.isOpen} toggle={closePasswordModal} user={resetPasswordModal.data} entityId={entityId} />

                <EditProfileModal isOpen={editProfileModal.isOpen} toggle={closeEditProfileModal} user={editProfileModal.data} numberPharmacies={editProfileModal.data.numberPharmacies} entityId={entityId} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} setNumberPharmacies={setNumberPharmacies} />

                <EditPharmaciesModal isOpen={userPharmModal.isOpen} user={userPharmModal.user} toggle={closeUserPharmModal} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} canEditRole={canEditRole} />

                <EditUserSettingsModal isOpen={editUserSettingsModal.isOpen} toggle={closeEditUserSettingsModal} user={editUserSettingsModal.data} entityId={entityId} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} />
                <DeleteUserModal isOpen={deleteUserModal.isOpen} toggle={closeDeleteUserModal} user={deleteUserModal.data} entityId={entityId} users={users} setUsers={setUsers} contextUser={user} deleteUserModal={deleteUserModal} setDeleteUserModal={setDeleteUserModal} closeDeleteUserModal={closeDeleteUserModal} deletedUser={deletedUser} setDeletedUser={setDeletedUser}/>
            </div>
        </>
    );
};

const DeleteUserModal = ({ isOpen, toggle, user, entityId, users, setUsers, contextUser, deletedUser, setDeletedUser, setDeleteUserModal}) => {
    const deleteUser = (deletedUser) => {
        console.log(deletedUser)
        const payload = {
          email: deletedUser.email,
          entityId: deletedUser.pharmRole[0].pharid,
          UserId: deletedUser.userId,
        };
      
        SrxApi.post('/deleteuser', payload)
          .then((res) => {
            const updatedUsers = users.filter((user) => user.email !== deletedUser.email);
            setUsers(updatedUsers);
            setDeletedUser(deletedUser); // Store the deleted user for potential undo
            SrxApi.get('/getpharmacyusers')
              .then((res) => {
                setUsers(res.data.users);
              })
              .catch((err) => {
                toast.error('Error retrieving user list');
              });
            setDeleteUserModal({ isOpen: false, data: '' });
            // setDeletedUser(null);
            toggle();
            console.log(deletedUser)
            toast.success(`${deletedUser.displayName || deletedUser.email} deleted successfully`);
          })
          .catch((err) => {
            toast.error('Error deleting user: ' + err.message);
          });
      };

      const [userPharmacies, setUserPharmacies] = useState(null);
        useEffect(() => {
            if (isOpen) {
                setUserPharmacies([]);
                SrxApi.get('/userpharmacies', {
                    params: {
                        email: user.email
                    }
                })
                    .then(({ data }) => setUserPharmacies(data.pharmacies))
                    .catch(err => {
                        toast.error('Error: ' + err.message)
                    })
            }
        }, [isOpen, user.email])
      
return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
        Delete User
      </ModalHeader>
      <ModalBody>
        <h5>Are you sure you want to delete <b>{user.displayName}</b>?</h5>
        {userPharmacies && (
        <table>
            <thead>
            <tr>
                {userPharmacies.length > 0 && <th>Pharmacies</th>}
                {/* <th >Pharmacy ID</th>
                <th>Pharmacy Name</th> */}
            </tr>
            </thead>
            <tbody>
            {userPharmacies
                .sort((a, b) => (a.pharmName > b.pharmName ? 1 : -1))
                .map((pharmacies, index) => (
                <tr key={index}>
                    <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>{pharmacies.pharmacyId}</td>
                    <td style={{ verticalAlign: 'middle', fontWeight: 'normal' }}>{pharmacies.pharmName}</td>
                </tr>
                ))
            }
            </tbody>
        </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => deleteUser(user)}>Delete</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
);
};
const EditProfileModal = ({ isOpen, toggle, user, users, setUsers, contextUser, numberPharmacies, setNumberPharmacies }) => {
    const [displayName, setDisplayName] = useState(user.displayName || '');
    const [phone, setPhone] = useState(formatPhoneNumber(user.phone || ''));
    const [phoneInput, setPhoneInput] = useState(user.phone || '');
    const [phoneError, setPhoneError] = useState('');

    const [userPharmacies, setUserPharmacies] = useState(null);


    function formatPhoneNumber(phone) {
        if (!phone) return phone;
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    function handlePhoneChange(e) {
        const inputValue = e;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        if (numericValue.length === 10) {
            setPhoneError('');
        } else {
            setPhoneError('Phone number must have 10 digits');
        }
        setPhoneInput(numericValue);
        setPhone(formatPhoneNumber(numericValue));
    }


    function handleSave() {
        console.log("displayName:", displayName);
        console.log("phone:", phone);

        if (!displayName && !phone) {
            toast.error('Display Name and Phone are required fields');
            return;
        }

        if (phoneInput && phoneInput.length !== 10) {
            setPhoneError('Phone number must have 10 digits');
            return;
        }

        let profileUpdatePayload = {
            displayName,
            phone: phoneInput.toString(),
            UserRole: user.pharmRole[0].role,
            email: user.email,
            entityId: user.pharmRole[0].pharid,
        };

        SrxApi.post('/edituserinfo', profileUpdatePayload)
            .then(async res => {
                const updatedUserIndex = users.findIndex((u) => u.email === user.email);
                if (updatedUserIndex !== -1) {
                    let updatedUsers = [
                        ...users
                    ]
                    updatedUsers[updatedUserIndex] = res.data.userInfo
                    console.log(updatedUsers)
                    let newNumberOfPharmacies = userPharmacies.length;
                    let newUserPharmacies = await SrxApi.get('/userpharmacies', {
                        params: {
                            email: user.email
                        }
                    })
                    // add new number of pharmacies to list user
                    updatedUsers.find(({ email }) => email === user.email).numberPharmacies = newNumberOfPharmacies;

                    SrxApi.get('/getpharmacyusers')
                        .then(res => {
                            setUsers(res.data.users)
                            console.log("Updated users state:", res.data.users);
                        })
                    setUsers(res.data.users)
                    setUserPharmacies(newUserPharmacies.data.pharmacies)
                    setNumberPharmacies(user.numberPharmacies);
                    setPhoneError('')
                    toast.success('Profile updated successfully');
                }
                console.log(res)
                console.log(user)
                console.log(users)
                toggle();
            })
            .catch((err) => {
                toast.error(err.message);
            })
    };
    useEffect(() => {
        if (isOpen) {
            setUserPharmacies([]);
            SrxApi.get('/userpharmacies', {
                params: {
                    email: user.email
                }
            })
                .then(({ data }) => setUserPharmacies(data.pharmacies))
                .catch(err => {
                    toast.error('Error: ' + err.message)
                })
        }
        if (!isOpen) {
            setDisplayName(user.displayName || '');
            setPhoneInput(user.phone || '');
            setPhone(formatPhoneNumber(user.phone || ''));
            setNumberPharmacies(user.numberPharmacies);
        }
    }, [isOpen, user]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">Edit Profile for <i>"{user.displayName}"</i></ModalHeader>
            <ModalBody>
                <AvForm >
                    <AvGroup className="form-group">
                        <Label for="confirm-name">Edit Name</Label>
                        <AvField
                            type="text"
                            placeholder={user.displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            value={displayName}
                            name="newName"
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Name must include a minimum of 4 characters' }
                            }}
                        />
                    </AvGroup>
                    <AvGroup className="form-group">
                        <Label for="confirm-phone">New Phone</Label>
                        <AvField
                            type="text"
                            placeholder="Enter new Phone"
                            onChange={(e) => handlePhoneChange(e.target.value)}
                            value={phone}
                            name="newPhone"
                            required
                        />
                        {phoneError && <div className="text-danger">{phoneError}</div>}
                    </AvGroup>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const EditUserSettingsModal = ({ isOpen, toggle, user, users, setUsers, contextUser, }) => {

    const handleSave = () => {
        console.log('saving...');
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ minWidth: '800px' }}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">Edit settings for <i>"{user.displayName}"</i></ModalHeader>
            <ModalBody>

                <Settings userData={user} toggle={toggle} users={users} setUsers={setUsers} />
            </ModalBody>
            {/* <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter> */}
        </Modal>
    );
};


const ResetPasswordModal = ({ isOpen, toggle, user }) => {
    console.log(user)

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function handleValidSubmit() {
        if (password !== confirmPassword)
            return toast.error('Passwords do not match')

        let resetPasswordPayload = {
            password,
            confirmPassword,
            email: user.email,
            entityId: user.pharmRole[0].pharid
        }



        SrxApi.post('/adminpwdreset', resetPasswordPayload)
            .then(res => {
                toast.success(res.data.message + ' for ' + '"' + res.data.email + '"')
                toggle()
            })
            .catch(err => toast.error(err.message))
    }

    useEffect(() => {
        if (!isOpen) {
            setPassword('');
            setConfirmPassword('');
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Reset Password for <i>"{user.displayName}"</i>
            </ModalHeader>
            <ModalBody>
                <AvForm onValidSubmit={() => handleValidSubmit()}>

                    <AvGroup>
                        <Label for="password">New Password</Label>
                        <AvField
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter new password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Password must include a minimum of 4 characters' }
                            }}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="confirm-password">Confirm New Password</Label>
                        <AvField
                            type="password"
                            name="confirm-password"
                            id="confirm-password"
                            placeholder="Confirm new password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Password must include a minimum of 4 characters' }
                            }}
                        />
                        {/* <AvFeedback>This field is invalid</AvFeedback> */}
                    </AvGroup>

                    <FormGroup>
                        <Button color="secondary">Reset Password</Button>
                    </FormGroup>
                </AvForm>
            </ModalBody>
        </Modal>
    )
};

const CreatePharmacyUserModal = ({ isOpen = false, toggle = () => { }, getPharmacyUsers }) => {

    const { pharmacies } = useContext(SelectedPharmaciesContext);

    const [validState, setValidState] = useState({
        pharmacyRole: true,
        pharmacies: true,
        displayName: true,
        email: true,
        phone: true,
        confirmPasswordRequired: true,
        confirmPasswordMatches: true,
        // title: true,
    })

    const { pharmacyUserRoles } = useContext(UserRolesContext)

    const [pharmacyUser, setPharmacyUser] = useState({
        pharmacyRole: null,
        pharmacies: [],
        displayName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        title: '',
    })
    console.log(pharmacyUser)

    const customValidateForm = () => {

        let newValidState = {
            ...validState
        }

        if (pharmacyUser.pharmacies.length < 1)
            newValidState.pharmacies = false

        if (!pharmacyUser.pharmacyRole)
            newValidState.pharmacyRole = false

        if (!pharmacyUser.confirmPassword)
            newValidState.confirmPasswordRequired = false

        if ((pharmacyUser.password && pharmacyUser.confirmPassword) && (pharmacyUser.password !== pharmacyUser.confirmPassword))
            newValidState.confirmPasswordMatches = false

        if (pharmacyUser.phone.replace(/\D/g, '').length > 0 && pharmacyUser.phone.replace(/\D/g, '').length < 10)
            newValidState.phone = false;

        let valid = true;

        for (let state in newValidState) {
            if (newValidState[state] === false)
                valid = false
        }

        setValidState(newValidState)

        return valid
    }

    const handleValidSubmit = () => {
        if (!customValidateForm()) return
        let payload = {
            ...pharmacyUser,
            pharmID: pharmacyUser.pharmacies.map(x => x.value),
            UserRole: pharmacyUser.pharmacyRole,
            phone: pharmacyUser.phone.replace(/\D/g, ''),
            UserType: 'PharmacyUser',
            title: pharmacyUser.title,
        }

        delete payload.confirmPassword

        delete payload.pharmacies

        delete payload.pharmacyRole

        let addingPharmacyUserToast = toast.loading('Adding folder...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/createpharmuser', payload)
            .then(res => {

                toast.update(addingPharmacyUserToast,
                    {
                        render: 'Pharmacy user successfully created',
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

                getPharmacyUsers()
                toggle()
            })
            .catch(err => {
                toast.update(addingPharmacyUserToast,
                    {
                        render: err.response.data.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

            })
    }

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: "flex" }}>
            <div style={{
                color: disabled ? '#C0C4C8' : 'inherit'
            }}>{label}</div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
          </div> */}
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader
                className="modal-colored-header bg-primary"
                toggle={toggle}
            >
                Create Pharmacy User
            </ModalHeader>
            <AvForm
                beforeSubmitValidation={customValidateForm}
                onValidSubmit={handleValidSubmit}>
                <ModalBody>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label style={{ color: `${!validState.pharmacies ? '#fa5c7c' : ''}` }}>Pharmacies *</Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    isMulti
                                    className={`${!validState.pharmacies ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    // name="pharmacies"
                                    loadingMessage={() => 'Loading Pharmacies'}
                                    isLoading={!pharmacies}
                                    placeholder="Select Pharmacies..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            pharmacies: true
                                        })
                                        // setPharmacyUser({
                                        //     ...pharmacyUser,
                                        //     pharmacyRole: selection.value
                                        // })
                                        setPharmacyUser({
                                            ...pharmacyUser,
                                            pharmacies: selection ? [...selection] : []
                                        })
                                    }
                                    }
                                    value={pharmacyUser.pharmacies}
                                    isSearchable={false}
                                    options={pharmacies.map(pharm => ({ value: pharm.pharmID, label: pharm.pharmID }))}
                                >
                                </Select>
                                {/* </div> */}
                                {(!validState.pharmacies) && <div className="invalid-text">Please select a pharmacy</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="pharmacy-role" style={{ color: `${!validState.pharmacyRole ? '#fa5c7c' : ''}` }}>Pharmacy Role *</Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    className={`${!validState.pharmacyRole ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    name="pharmacy-role"
                                    isLoading={!pharmacyUserRoles}
                                    placeholder="Select Role..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            pharmacyRole: true
                                        })
                                        setPharmacyUser({
                                            ...pharmacyUser,
                                            pharmacyRole: selection.value
                                        })
                                    }}
                                    formatOptionLabel={formatOptionLabel}
                                    isOptionDisabled={(option) => option.disabled}
                                    value={pharmacyUserRoles?.find(role => role.value === pharmacyUser.pharmacyRole) || ''}
                                    isSearchable={false}
                                    options={pharmacyUserRoles}
                                >
                                </Select>
                                {/* </div> */}
                                {(!validState.pharmacyRole) && <div className="invalid-text">Please select a pharmacy role</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={6}>
                            <AvField
                                name="displayName"
                                label="Display Name *"
                                placeholder="Display name..."
                                value={pharmacyUser.displayName}
                                onChange={(e) => setPharmacyUser({
                                    ...pharmacyUser,
                                    displayName: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter a display name' }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <AvField
                                type="email"
                                name="email"
                                label="Email *"
                                placeholder="Email..."
                                value={pharmacyUser.email}
                                onChange={(e) => setPharmacyUser({
                                    ...pharmacyUser,
                                    email: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter an email address' },
                                    email: { value: true, errorMessage: 'Invalid email' }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="phone" style={{ color: `${!validState.phone ? '#fa5c7c' : ''}` }}>Phone</Label>
                                {/* <div style={{ width: '200px' }}> */}
                                <MaskedInput
                                    name="phone"
                                    value={pharmacyUser.phone}
                                    onChange={(e) => {
                                        if (e.target.value.replace(/\D/g, '').length === 10) {
                                            setValidState({
                                                ...validState,
                                                phone: true
                                            })
                                        }

                                        setPharmacyUser({
                                            ...pharmacyUser,
                                            phone: e.target.value
                                        })
                                    }}
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    placeholder="(xxx) xxxx-xxxx"
                                    className={`form-control ${!validState.phone ? 'invalid' : ''}`}
                                />
                                {/* </div> */}
                                {!validState.phone ? <div className="invalid-text">Invalid phone</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={6}>
                            <AvField
                                type="password"
                                label="Password *"
                                name="password"
                                placeholder="Password..."
                                value={pharmacyUser.password}
                                onChange={(e) => setPharmacyUser({
                                    ...pharmacyUser,
                                    password: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter a password' }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="confirm-password" style={{ color: `${!validState.confirmPasswordRequired || !validState.confirmPasswordMatches ? '#fa5c7c' : ''}` }}>Confirm Password *</Label>
                                <Input type="password" name="confirm-password" id="confirm-password"
                                    className={`${!validState.confirmPasswordRequired || !validState.confirmPasswordMatches ? 'invalid' : ''}`}
                                    autoComplete='off'
                                    placeholder='Re-enter Password...'
                                    value={pharmacyUser.confirmPassword}
                                    onChange={(e) => {
                                        let target = e.target;
                                        if (target.value === pharmacyUser.password) {
                                            setValidState(prev => ({
                                                ...prev,
                                                confirmPasswordMatches: true
                                            }))
                                        }

                                        setValidState(prev => ({
                                            ...prev,
                                            confirmPasswordRequired: target.value ? true : false
                                        }))

                                        setPharmacyUser({
                                            ...pharmacyUser,
                                            confirmPassword: target.value
                                        })
                                    }}
                                />
                                {(!validState.confirmPasswordRequired) ? <div className="invalid-text">Please re-enter password</div> : ''}
                                {(validState.confirmPasswordRequired && !validState.confirmPasswordMatches) ? <div className="invalid-text">Passwords do not match</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <AvField
                                name="title"
                                label="title *"
                                placeholder="title..."
                                value={pharmacyUser.title}
                                onChange={(e) => setPharmacyUser({
                                    ...pharmacyUser,
                                    title: e.target.value
                                })}
                                // validate={{
                                //     required: { value: true, errorMessage: 'Please enter a title' }
                                // }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button color="primary">
                        Save
                    </Button>{' '}
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

const EditPharmaciesModal = ({ toggle, user, isOpen, setFaciToken, users, setUsers, contextUser, canEditRole }) => {
    console.log(user) //selected user
    console.log(contextUser) //signed-in user

    const { pharmacies } = useContext(SelectedPharmaciesContext);

    const { pharmacyUserRoles } = useContext(UserRolesContext);

    const [userPharmacies, setUserPharmacies] = useState(null);

    const [pendingUserPharmacies, setPendingUserPharmacies] = useState([]);

    const [userRoleLoading, setUserRoleLoading] = useState(false);

    const [selectedPharmacy, setSelectedPharmacy] = useState([])

    let history = useHistory();

    useEffect(() => {

        if (isOpen) {
            setUserPharmacies([]);
            SrxApi.get('/userpharmacies', {
                params: {
                    email: user.email
                }
            })
                .then(({ data }) => setUserPharmacies(data.pharmacies))
                .catch(err => {
                    if (err.response?.status === 401) {
                        localStorage.removeItem('FACI_TOKEN');
                        setFaciToken(null);
                        history.replace('/account/login')
                    }
                })
        }


    }, [isOpen])

    const canAddPharmacy = (user) => {
        console.log(users)
        let signedInUser = users?.find(u => u.email === user?.email); //returns signed in user
        if (!signedInUser) {
            console.log("User not found");
            return false;
        }
        let signedInUserRoles = signedInUser?.pharmRole || []; //returns pharmRole array/s of signed in user
        let selectedUserRoles = user?.pharmRole || []; //returns pharmRole array/s of selected user
        function findMatchingPharid(signedInUserRoles, selectedUserRoles) {
            let matchingPharid = [];
            // Loop through the pharmRole array of the first user
            for (const role1 of signedInUserRoles) {
                const pharid1 = role1.pharid;

                for (const role2 of selectedUserRoles) {
                    const pharid2 = role2.pharid;

                    if (pharid1 === pharid2) {
                        matchingPharid.push(pharid1);
                    }
                }
            }

            return matchingPharid;

        }
        let hasMatchingPharid = findMatchingPharid(signedInUserRoles, selectedUserRoles) // returns common pharid of both users
        if (hasMatchingPharid.length === 0) {
            console.log("No matching pharid found.");
            return false;
        }
        let matchingPharmacy = pharmacies?.filter((pharmacy) => hasMatchingPharid.includes(pharmacy.pharmID)); //returns common pharmacies!
        let selectedUserRole = [...new Set(user?.pharmRole.map(r => r.role))]; //returns selected user role/s without duplicates
        let userPermission = 'user_AddPharmacyToUser'; // returns user_EditCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingPharmacy.map((pharmacy) => pharmacy.userPermissions); //returns all user permissions
        let hasPermission = allUserPermissions.some((permissions) => permissions.hasOwnProperty(userPermission));

        if (matchingPharmacy && hasPermission) {
            console.log(`User has permission for ${userPermission}: ${hasPermission}`);
            return true;
        } else {
            console.log("No matching pharmacies found.");
            return false;
        }

    }

    function onPharmRoleChange(role, user, pharmacy) {
        let payload = {
            entityID: pharmacy.pharmacyId,
            email: user.email,
            userRole: role
        }

        SrxApi.post('/edituser', payload)
            .then(res => {
                let copyOfUserPharmacies = [
                    ...userPharmacies
                ]
                // console.log(res);
                console.log(copyOfUserPharmacies);
                console.log(role);
                copyOfUserPharmacies.find(userPharm => userPharm.pharmacyId === pharmacy.pharmacyId).userRole = role;

                setUserPharmacies(copyOfUserPharmacies);
                let tempUsers = [
                    ...users
                ]
                console.log(tempUsers.find(({ email }) => email === user.email).roles);
                console.log(role);
                tempUsers.find(({ email }) => email === user.email).roles = [role];


                toast.success('User role successfully changed')
                // setPharmacyUserRoles(res.data.userRole)
                // console.log(res.data.userRole);
            })
            .catch(err => {
                toast.error('Error: ' + err.message)
            })
    }

    function onPendingPharmRoleChange(role, pharmacy) {

        let copyOfPendingUserPharmacies = [
            ...pendingUserPharmacies
        ]

        copyOfPendingUserPharmacies.find(userPharmacy => userPharmacy.pharmacyId === pharmacy.pharmacyId).userRole = role;

        setPendingUserPharmacies(copyOfPendingUserPharmacies);
    }

    function addToPendingPharmacies() {
        // if(userFacilities.findIndex(faci => faci.facID === selectedFacility[0].facID) !== -1){
        //     setSelectedFacility([])
        //     return toast.error('Facility has already been added to user')
        // }

        // set default role to user
        let tempSelected = cloneDeep(selectedPharmacy[0]);

        tempSelected.userRole = null;

        setPendingUserPharmacies([
            ...pendingUserPharmacies,
            tempSelected
        ])
        setSelectedPharmacy([])
    }

    function deletePendingPharmacy(id) {
        setPendingUserPharmacies(pendingUserPharmacies.filter(({ pharmID }) => pharmID !== id))
    }

    function savePendingPharmacies() {

        if (pendingUserPharmacies.findIndex(pharm => !pharm.userRole) >= 0)
            return toast.error('All pending pharmacies must be assigned a role')

        let payload = {
            email: user.email,
            entities: pendingUserPharmacies.map(({ pharmID, userRole }) => {
                return {
                    entityid: pharmID,
                    userRole
                }
            })
        }

        SrxApi.post('/adduserpharmacies', payload)
            .then(async res => {

                let newNumberOfPharmacies = userPharmacies.length + pendingUserPharmacies.length;

                let newUserPharmacies = await SrxApi.get('/userpharmacies', {
                    params: {
                        email: user.email
                    }
                })

                setUserPharmacies(newUserPharmacies.data.pharmacies)
                setPendingUserPharmacies([])
                // add new number of facilities to list user
                let tempUsers = [
                    ...users
                ]

                tempUsers.find(({ email }) => email === user.email).numberPharmacies = newNumberOfPharmacies;
                setUsers(tempUsers)

                toast.success('User added to pharmacies')
            })
            .catch(err => toast.error('Error: ' + err.message))
    }

    async function removeUserFromSinglePharmacy(id, name) {

        if (!await confirm(`Remove <i>${user.displayName || user.email}</i> from <i>${name}</i>?`, `Removing user from pharmacy`, "Confirm", "Cancel", "primary", "primary"))
            return

        let payload = {
            email: user.email,
            pharmID: id
        }
        SrxApi.post('/removepharmacy', payload)
            .then(res => {

                let newNumberOfPharmacies = userPharmacies.filter(({ pharmacyId }) => pharmacyId !== id).length

                setUserPharmacies(userPharmacies.filter(({ pharmacyId }) => pharmacyId !== id))

                // add new number of facilities to list user
                let tempUsers = [
                    ...users
                ]

                tempUsers.find(({ email }) => email === user.email).numberPharmacies = newNumberOfPharmacies;

                setUsers(tempUsers)

                toast.success((user.displayName || user.email) + " removed from " + name)
            })
            .catch(err => toast.error('Error: ' + err.message))
    }

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: "flex" }}>
            <div style={{
                color: disabled ? '#C0C4C8' : 'inherit'
            }}>{label}</div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
          </div> */}
        </div>
    );

    async function preToggle() {
        if (pendingUserPharmacies.length && await confirm("There are unsaved pending pharmacies. Leave page without saving?", "Unsaved Changes!", "Leave", "Return to Page", "danger", "danger") === false)
            return
        setSelectedPharmacy([]);
        setPendingUserPharmacies([]);
        toggle();
    }

    // since pharmacy users only have one role across all pharmacies the only thing that needs to be determined is the active pharmacy users pharmacy role
    const checkIfUserHasPermission = () => {
        if (contextUser?.userType.toLowerCase() === 'pharmacyuser' && (contextUser?.userRole.toLowerCase() === 'SysAdmin'.toLowerCase() || contextUser?.userRole.toLowerCase() === 'superuser'.toLowerCase()))
            return true
        else
            return false
    }

    return (
        <Modal isOpen={isOpen} toggle={preToggle} size="lg" style={{ minWidth: '600px', maxWidth: 'max-content', width: '100%', minHeight: '500px' }} className="modal-dialog-scrollable">
            <ModalHeader toggle={preToggle} className="modal-colored-header bg-primary">
                View / Edit Pharmacies for <i>"{user?.displayName || user?.email}"</i>
                {(user?.email === contextUser.email) && <Badge color="dark" className='ml-1' style={{ fontSize: '14px' }}>Self</Badge>}
                {/* {isOpen && 
                    {user.displayName || user.email}
                } */}
            </ModalHeader>
            <ModalBody>
                {canAddPharmacy(user) &&
                    <FormGroup>
                        <Label for="itemType">Select Pharmacy</Label>
                        <InputGroup>
                            <Typeahead
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="pharmID"
                                disabled={!userPharmacies}
                                multiple={false}
                                options={pharmacies
                                    .filter(pharm => (pharm.userPermissions?.user_AddPharmacyToUser === true) && (userPharmacies?.findIndex(userPharm => userPharm.pharmacyId === pharm.pharmID) === -1) && (pendingUserPharmacies.findIndex(userPharm => userPharm.pharmID === pharm.pharmID) === -1))
                                    .map(faci => faci)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                selected={selectedPharmacy}
                                onChange={(selected) => setSelectedPharmacy(selected)}
                                placeholder="Select pharmacy..."
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="primary"
                                    onClick={addToPendingPharmacies}
                                    disabled={!selectedPharmacy.length}
                                >Add</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>}
                {(!!pendingUserPharmacies.length) && <>
                    <Card>
                        <CardBody className='p-2 m-2'
                            style={{
                                border: '2px dashed #BDC6CA',
                                borderRadius: '3px'
                            }}
                        >
                            <h4 className='ml-1'>Pending Pharmacies</h4>
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Pharmacy ID</th>
                                        <th>Pharmacy Name</th>
                                        <th>Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                    {pendingUserPharmacies
                                        .sort((a, b) => a.pharmID > b.pharmID ? 1 : -1)
                                        .map((pharm, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }}>{pharm.pharmID}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{pharm.pharmID}</td>
                                                    {/* <td>{user.isAdmin ?
                                    <><Icon icon={shieldAccount} width="24" height="24"/> Admin</> : 
                                    <><Icon icon={accountIcon} width="24" height="24"/> User</>}</td> */}
                                                    <td>
                                                        <div style={{ width: '140px' }}>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable={false}
                                                                onChange={(e) => onPendingPharmRoleChange(e.value, pharm)}
                                                                hideSelectedOptions
                                                                value={
                                                                    pharmacyUserRoles?.find(role => role.value === pharm.userRole) || null
                                                                }
                                                                formatOptionLabel={formatOptionLabel}
                                                                isOptionDisabled={(option) => option.disabled}
                                                                options={
                                                                    pharmacyUserRoles
                                                                }>
                                                            </Select>
                                                        </div>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <Button className="card-drop p-0 text-white"
                                                            onClick={() => deletePendingPharmacy(pharm.pharmID)}
                                                        >
                                                            <Icon icon={deleteIcon} height="24" width="24" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <hr />
                            <div className='d-flex justify-content-end'>
                                <Button color="secondary" onClick={preToggle} className="mr-2">
                                    Cancel
                                </Button>
                                <Button color="primary"
                                    disabled={pendingUserPharmacies.length < 1}
                                    onClick={savePendingPharmacies}
                                >
                                    Save Pending Pharmacies
                                </Button>{' '}
                            </div>
                        </CardBody>
                    </Card>
                    <hr />
                </>}
                <><h4 className='ml-1'>Pharmacies</h4>
                    {userPharmacies
                        ?
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>Pharmacy ID</th>
                                    <th>Pharmacy Name</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                {userPharmacies.length > 0
                                    ?
                                    // .filter(faci => facilities.find(facility => facility.facID === faci.facID).isAdmin)
                                    userPharmacies
                                        .sort((a, b) => a.pharmacyId > b.pharmacyId ? 1 : -1)
                                        .map((pharm, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }}>{pharm.pharmacyId}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{pharm.pharmName}</td>
                                                    {/* <td>{user.isAdmin ?
                                    <><Icon icon={shieldAccount} width="24" height="24"/> Admin</> : 
                                    <><Icon icon={accountIcon} width="24" height="24"/> User</>}</td> */}
                                                    <td>
                                                        <div style={{ width: '140px' }}>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                hideSelectedOptions
                                                                isSearchable={false}
                                                                isDisabled={!canEditRole(pharm.pharmacyId, pharmacyUserRoles?.find(role => { return role.value === pharm.userRole }), 'roleChange')}
                                                                onChange={(e) => onPharmRoleChange(e.value, user, pharm)}
                                                                value={pharmacyUserRoles?.find(role => { return role.value === pharm.userRole })}
                                                                formatOptionLabel={formatOptionLabel}
                                                                options={pharmacyUserRoles}
                                                                isOptionDisabled={(option) => option.disabled}
                                                            >

                                                            </Select>
                                                        </div>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <Button className="card-drop p-0"
                                                            disabled={!canEditRole(pharm.pharmacyId, pharmacyUserRoles?.find(role => { return role.value === pharm.userRole }), 'delete')}
                                                            onClick={() => removeUserFromSinglePharmacy(pharm.pharmacyId, pharm.pharmName)}
                                                        >
                                                            <Icon icon={deleteIcon} height="24" width="24" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    :
                                    <tr>
                                        <td colSpan={3} className='text-center'><b>No User Pharmacies</b></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        :
                        <div className='w-100 d-flex justify-content-center'>
                            <div>
                                <h4>Loading User Pharmacies...</h4>
                                <div className="bouncing-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    }</>
            </ModalBody>
            {/* <ModalFooter>
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
                <Button color="primary"
                disabled={pendingUserFacilities.length < 1}
                onClick={savePendingFacilities}
                >
                    Save Pending Facilities
                </Button>{' '}
            </ModalFooter> */}
        </Modal>
    )
}

export default PharmacyUsers;