import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';

const useMediprocityScript = () => {

  const { user, faciToken } = useContext(UserContext);

  useEffect(() => {
    // const script = document.createElement('script');

    // script.src = "https://client.mediprocity.com/assets/widgets/embed.js";
    // script.async = true;

    // document.head.appendChild(script);

    function Mediprocity(options) {
      console.log('jhjd');
      return ({
        options: options,

        init: function () {
          var self = this;
          this.createIframe();
          document.addEventListener('DOMContentLoaded', function (event) {
            self.createIframe();
          });
        },

        login: function (contentWindow) {
          contentWindow.postMessage(this.options, this.options.url);
        },

        createIframe: function () {
          var self = this;
          var container = document.querySelector(this.options.container);
          this.cleanupIframes();
          if (!container) {
            return
          }
          let iframe = document.createElement('iframe');
          iframe.setAttribute('src', this.getLoginUrl());
          iframe.setAttribute('width', '780');
          iframe.setAttribute('id', 'mediprocity-frame');
          iframe.setAttribute('height', '640');
          iframe.setAttribute('frameborder', '0');
          container.appendChild(iframe);
          iframe.onload = function () {
            self.login(iframe.contentWindow);
          };
        },

        cleanupIframes: function () {
          var iframes = document.querySelectorAll('iframe#mediprocity-frame');
          for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
          };
        },

        getLoginUrl: function () {
          return this.getHostUrl() + '/widget/login?widget=true';
        },

        getHostUrl: function () {
          var link = document.createElement('a');
          link.href = this.options.url;
          return link.origin || (link.protocol + '//' + link.host);
        }
      });
    };

    if (user.mediprocityUser) {

      let options = {};

      options.email = user.mediprocityInfo.user;
      options.password = user.mediprocityInfo.password;
      options.encryption_key = user.mediprocityInfo.encryptionKey;
      //this is the container where the iframe chat widget is going to be rendered
      options.container = '#chat-panel';
      //mediprocity's website url
      options.url = 'https://secure.specialtyrxnow.com/';


      let client = new Mediprocity(options);
      client.init();
    } else {
      let options = {};

      options.email = '123456';
      options.password = '123456';
      options.encryption_key = '123456';
      //this is the container where the iframe chat widget is going to be rendered
      options.container = '#chat-panel';
      //mediprocity's website url
      options.url = 'https://secure.specialtyrxnow.com/';


      let client = new Mediprocity(options);
      client.init();
    }

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, [user.mediprocityUser, faciToken]);
};

export default useMediprocityScript;