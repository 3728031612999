// @flow
import React from 'react';
import Routes from './routes/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './contexts/UserContext';
import { SelectedFacilitiesProvider } from './contexts/SelectedFacilitiesContext';

// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes
import './assets/scss/Saas.scss';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import IdleTimerContainer from './utility-components/IdleTimerContainer';
import { ActiveAlertProvider } from './contexts/ActiveAlertContext';
import { CurrentTimeProvider } from './contexts/CurrentTimeContext';
import { SingleWidgetActiveTabProvider } from './contexts/SingleWidgetActiveTabContext';
import { ShowResolvedAlertsProvider } from './contexts/ShowResolvedAlertsContexts';
import { UserRolesProvider } from './contexts/UserRolesContext';
import { LogrocketProvider } from './logrocketSetup';
import { SelectedPharmaciesProvider } from './contexts/SelectedPharmaciesContext';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
// configureFakeBackend();

type AppProps = {};

const ContextProviders = (props) => {
    return(
        <UserProvider>
            <ShowResolvedAlertsProvider>
                <CurrentTimeProvider>
                    <ActiveAlertProvider>
                        <SingleWidgetActiveTabProvider>
                            <SelectedPharmaciesProvider>
                                <SelectedFacilitiesProvider>
                                    <UserRolesProvider>
                                        <LogrocketProvider>
                                            {props.children}
                                        </LogrocketProvider>
                                    </UserRolesProvider>
                                </SelectedFacilitiesProvider>
                            </SelectedPharmaciesProvider>
                        </SingleWidgetActiveTabProvider>
                    </ActiveAlertProvider>
                </CurrentTimeProvider>
            </ShowResolvedAlertsProvider>
        </UserProvider>  
    )
}

/**
 * Main app component
 */
const  App = () =>  {

    // axios.interceptors.response.use(function (response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data

    //     return response;
    //   }, function (error) {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     toast.error(`${error.response.status}: ${error.response.data.errors[0].msg}`)

    //     return Promise.reject(error);
    //   });

    return (
        <BrowserRouter 
        // basename='/srx/faci'
        >
            <ContextProviders>
                <Routes></Routes>
                <IdleTimerContainer />
                <ToastContainer
                    position="bottom-right"
                    theme="light"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    icon
                    draggable
                    pauseOnHover
                />
            </ContextProviders>
        </BrowserRouter>
    )
}

export default App;
