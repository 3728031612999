import React, { useRef, useState, useContext, useEffect } from 'react';
import IdleTimer from "react-idle-timer";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { UserContext } from '../contexts/UserContext';

const IdleTimerContainer = () => {

    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);

    const { user, logoutUser } = useContext(UserContext);

    const toggle = () => setModal(!modal);

    const [modal, setModal] = useState(false);

    const onIdle = () => {
        if (user){
            setModal(true);
            sessionTimeoutRef.current = setTimeout(logOut, 600 * 1000);
        }
    }

    const stayActive = () => {
        setModal(false);
        clearTimeout(sessionTimeoutRef.current);
    }

    const logOut = () => {
        setModal(false);
        logoutUser();
    }

    const IdleModal = () => {

        const [seconds, setSeconds] = useState(600);

        const modalTimeoutRef = useRef(null);

        useEffect(() => {
            if(modal){
                modalTimeoutRef.current =  setTimeout(() => {
                    setSeconds(seconds -1);
                }, 1000)

                return () => clearTimeout(modalTimeoutRef.current)
            }

            return
        }, [seconds])

        return(
            <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalHeader className="modal-colored-header bg-warning">
                    You've been idle for 2 hours
                </ModalHeader>
                <ModalBody>
                    <p>You will be logged out in <strong>{Math.floor(seconds / 60)}:{(seconds % 60) < 10 ? '0' : ''}{seconds % 60}</strong></p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={logOut}>
                        Log out now
                    </Button>{' '}
                    <Button color="secondary" onClick={stayActive}>
                        Stay Active
                    </Button>
                </ModalFooter>
            </Modal>
        )
}

    return(
        <>
            <IdleModal />
            <IdleTimer
            ref={idleTimerRef}
            timeout={1000 * 60 * 60 * 2}
            onIdle={onIdle}
            >
            </IdleTimer>
        </>
    )
}

export default IdleTimerContainer;