import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    FormGroup,
    Label,
    Input,
    Badge,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { SrxApi } from '../api/SrxApi';
import { useParams } from 'react-router-dom';

const AlertContactEmail = (props) => {
    const {facility, alertType, contact} = props;
    const {handleInputChange, handleDeleteEmail} = props;

    const [emailError, setEmailError] = useState("");
    const [emailFieldHasChanged, setEmailFieldHasChanged] = useState(false);
    const [emailFieldIsBeingEdited, setEmailFieldIsBeingEdited] = useState(false);
    const [emailFieldValue, setEmailFieldValue] = useState(undefined);

    useEffect(() => {
        if (contact) {
            setEmailFieldValue(contact);
        }
    }, [])

    function validateEmail(contact) {
        if (alreadyExists(contact)) {
            setEmailError('Email already exists');
            return false;
        }

        if (!isEmailValid(contact)) {
            setEmailError('Please enter a valid email address.');
            return false;
        }

        setEmailError(null);

        return true;
    }

    function isEmailValid(newEmail) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(newEmail?.contactInfo);
    }

    function alreadyExists(value) {
        if (facility.facilityContacts?.filter(w => w.widget === value?.widget)
            .some(c => c.contactInfo === value?.contactInfo && c.facilityContactInfoID !== value?.facilityContactInfoID)
        ) {
            return true;
        }
    }

    const handleAddNewEmail = (value, widget, btnState, inputID) => {
        if (false === validateEmail(value)) {
            return;
        }

        handleInputChange(emailFieldValue, widget, 'Email', btnState, inputID);
    }

    const handleSaveEditedEmail = (value, widget, btnState, inputID, editState) => {
        if (false === validateEmail(value)) {
            return;
        }

        let editPayload = {
            ContactId: value.facilityContactInfoID,
            ContactInfo: value.contactInfo,
            contactMethod: 'Email',
        };

        SrxApi.post('/editfacisetting', editPayload)
            .then((res) => {
                btnState(false);
                editState(false);
                toast.success('Facility settings for ' + `${widget}` + `Email` + ' successfully saved');
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
            });
    };

    return (
        <React.Fragment>
            <div className="d-flex my-1">
                {(emailFieldValue?.contactInfo?.length && !emailFieldHasChanged) ? (
                    <>
                        {emailFieldIsBeingEdited ? (
                            <div style={{ position: 'relative', width: '100%' }}>
                                <Input
                                    type="email"
                                    value={emailFieldValue?.contactInfo}
                                    onChange={(e) => setEmailFieldValue({ ...emailFieldValue, contactInfo: e.target.value })}
                                />
                                <i
                                    role="button"
                                    style={{ color: '#42d29d', position: 'absolute', right: '72px', top: '50%', transform: 'translateY(-50%)' }}
                                    className="mdi mdi-check-circle-outline"
                                    onClick={(e) => handleSaveEditedEmail(
                                        emailFieldValue, 'HighCost', setEmailFieldHasChanged, setEmailFieldValue, setEmailFieldIsBeingEdited
                                    )}
                                ></i>
                                <i
                                    role="button"
                                    style={{ color: '#fa6767', position: 'absolute', right: '48px', top: '50%', transform: 'translateY(-50%)' }}
                                    className="uil-trash-alt"
                                    onClick={(e) => {
                                        handleDeleteEmail(emailFieldValue, 'HighCost', 'Email', setEmailFieldHasChanged, setEmailFieldValue, setEmailFieldIsBeingEdited);
                                        setEmailFieldIsBeingEdited(false)
                                        setEmailFieldHasChanged(false)
                                    }}
                                ></i>
                                <i
                                    role="button"
                                    style={{ color: 'secondary', position: 'absolute', right: '24px', top: '50%', transform: 'translateY(-50%)' }}
                                    className="mdi mdi-cancel"
                                    onClick={(e) => {
                                        setEmailFieldIsBeingEdited(false);
                                        setEmailError("")
                                    }}
                                ></i>
                            </div>

                        ) : (
                                <div onClick={(e) => setEmailFieldIsBeingEdited(true)} style={{ cursor: 'pointer' }} className='bg-light px-1'>
                                    {emailFieldValue?.contactInfo}
                                </div>
                        )}
                    </>

                ) : (
                    <Input
                        type="email"
                        name={`highcost-new-email-address`}
                        id={`highcost-new-email-address`}
                        placeholder={`Enter new email`}
                        value={emailFieldValue?.contactInfo}
                        onChange={(e) => {
                            setEmailFieldValue({ ...emailFieldValue, contactInfo: e.target.value, widget: alertType.widget });
                            setEmailFieldHasChanged(true);
                        }}
                        />

                )}
                {emailFieldHasChanged && (
                    <Button
                        disabled={!emailFieldValue?.contactInfo && !facility?.facilityContacts?.filter(contact => contact.widget === 'HighCost' && contact.contactMethod === 'Email').find((contact) => contact.contactInfo === emailFieldValue?.contactInfo)}
                        color="primary"
                        onClick={() => handleAddNewEmail(emailFieldValue, alertType.widget, setEmailFieldHasChanged, setEmailFieldValue)}
                        className="mx-2"
                    >
                        Save
                    </Button>
                )}
            </div>
            {emailError && <div className="text-danger">{emailError}</div>}
        </React.Fragment>
    );
}

const AlertSetting = (props) => {
    const { facility, alertType } = props;

    const [emailCount, setEmailCount] = useState(undefined);
    const [emailInterval, setEmailInterval] = useState(60);
    const [addingNewContactEmail, setAddingNewContactEmail] = useState(false);
    const [noResponseAction, setNoResponseAction] = useState("");
    const [limit, setLimit] = useState(1);

    const [emailCountFieldHasChanged, setEmailCountFieldHasChanged] = useState(false);
    const [emailIntervalFieldHasChanged, setEmailIntervalFieldHasChanged] = useState(false);
    const [limitFieldHasChanged, setLimitFieldHasChanged] = useState(false);

    const [contactsForWidget, setContactsForWidget] = useState([]);

    useEffect(() => {
        setEmailCount(facility.facilitySettings?.find(s => s.widgetName === alertType.widget && s.settingName == 'EmailNotificationMaxCount')?.settingValue ?? 0);
        setEmailInterval(facility.facilitySettings?.find(s => s.widgetName === alertType.widget && s.settingName == 'EmailNotificationIntervalMinutes')?.settingValue ?? 60);
        setNoResponseAction(facility.facilitySettings?.find(s => s.widgetName === alertType.widget && s.settingName == 'AutoRespondAction')?.settingValue ?? "");
        setLimit(facility?.facilitySettings?.find((s) => s.widgetName === alertType.widget && s.settingName === 'AutoRespondAction' && s.settingValue === 'SubmitLimit')?.settingNote ?? undefined);

        setContactsForWidget(facility.facilityContacts.filter(c => c.contactMethod === 'Email' && c.widget === alertType.widget));

        console.log('Setting up initial state...', facility, alertType);
    }, []);

    const handleInputChange = (value, widget, settingName, btnState, inputID) => {
        let payload = {
            settingValues: {},

            FaciId: facility.facID,
            WidgetName: widget,
            ContactInfo: {},
        };

        if (settingName === 'EmailNotificationMaxCount') {
            payload.settingValues.EmailNotificationMaxCount = value.toString();
        }

        if (settingName === 'EmailNotificationIntervalMinutes') {
            payload.settingValues.EmailNotificationIntervalMinutes = value.toString();
        }

        if (settingName === 'AutoRespondAction') {
            payload.settingValues.AutoRespondAction = value;
        }

        if (settingName === 'SubmitLimit') {
            payload.settingValues.AutoRespondAction = 'SubmitLimit';
            payload.SettingNote = value.toString();
        }

        if (settingName === 'Email') {
            payload.ContactInfo.Email = value.contactInfo.toString();
        }

        SrxApi.post('/facilitysetting', payload)
            .then((res) => {
                if (res.data.faciContact.id) {
                    const faciContact = res.data.faciContact;
                    const newContact = {
                        contactInfo: faciContact.contact,
                        contactMethod: faciContact.method,
                        facilityContactInfoID: faciContact.id,
                        widget: faciContact.widget
                    };
                    inputID(newContact);
                    setContactsForWidget(current => [...current, newContact]);
                }

                if (btnState) {
                    btnState(false);
                }

                setAddingNewContactEmail(false);

                toast.success(' Facility settings for ' + `${widget}` /*+ `${settingName}`*/ + ' successfully saved');
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
            });
    };

    const handleDeleteEmail = (value, widget, settingName, btnState, inputID, editState) => {
        let index = contactsForWidget.findIndex((c => c.facilityContactInfoID === value.facilityContactInfoID));

        let payload = {
            ContactId: value?.facilityContactInfoID
        };

        SrxApi.post('/deletefacicontact', payload)
            .then((res) => {
                const updatedContacts = contactsForWidget.slice(0, index).concat(contactsForWidget.slice(index + 1));

                setContactsForWidget([]);
                setContactsForWidget(contactsForWidget => updatedContacts)

                toast.success(' Facility settings for ' + `${widget}` + `${settingName}` + ' successfully saved');
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
            });
        return;
    };

    return (
        <React.Fragment>
            <Col lg={6}>
                <Card>
                    <CardHeader className="bg-light">
                        <b>{alertType.title}</b>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6}>

                                <div className='mt-2 d-flex align-items-center'>
                                    <FormGroup>
                                        <Label className="mr-2" for={`highcost-submit-limit`}>Number of Email Reminders</Label>
                                        <div className='d-flex'>
                                            <Input
                                                type="number"
                                                value={emailCount}
                                                onChange={(e) => {{
                                                    setEmailCount(e.target.value);
                                                    setEmailCountFieldHasChanged(true);
                                                }}}
                                                style={{ width: '65px' }}
                                                className="custom-input"
                                            />
                                            {emailCountFieldHasChanged && <Button className="mx-2" color="primary" onClick={(e) => { handleInputChange(emailCount, alertType.widget, 'EmailNotificationMaxCount', setEmailCountFieldHasChanged); }}>Save</Button>}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className='mt-2 d-flex align-items-center'>
                                    <FormGroup>
                                        <Label className="mr-2" for={`highcost-submit-limit`}>Interval between Reminder Emails (in minutes)</Label>
                                        <div className='d-flex'>
                                            <Input
                                                type="number"
                                                value={emailInterval}
                                                onChange={(e) => {{
                                                    setEmailInterval(e.target.value);
                                                    setEmailIntervalFieldHasChanged(true);
                                                }}}
                                                style={{ width: '65px' }}
                                                className="custom-input"
                                            />
                                            {emailIntervalFieldHasChanged && <Button className="mx-2" color="primary" onClick={(e) => { handleInputChange(emailInterval, alertType.widget, 'EmailNotificationIntervalMinutes', setEmailIntervalFieldHasChanged); }}>Save</Button>}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className='mt-2'>
                                    <FormGroup>
                                        <Label className="mr-2" for={`highCost-saved-emails`}>Recipient Email Addresses</Label>
                                        {facility && contactsForWidget.map((contact, index) => (
                                            <AlertContactEmail key={index} facility={facility} alertType={alertType} contact={contact} 
                                                handleInputChange={handleInputChange} 
                                                handleDeleteEmail={handleDeleteEmail}
                                            />
                                        ))}
                                        {facility && contactsForWidget.length === 0 && (
                                            <AlertContactEmail key="newEmailInstance" facility={facility} alertType={alertType} contact={null} 
                                                handleInputChange={handleInputChange} 
                                            />
                                        )}
                                        {facility && addingNewContactEmail && (
                                            <AlertContactEmail key="newAdditionalEmailInstance" facility={facility} alertType={alertType} contact={null} handleInputChange={handleInputChange} />
                                        )}

                                        {!addingNewContactEmail && contactsForWidget.length > 0 && (
                                            <a className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() =>{setAddingNewContactEmail(true)}}>Add additional email address</a>
                                        )}
                                    </FormGroup>
                                </div>

                                <div>
                                    <FormGroup>
                                        <Label for={`high-cost-alerts-auto-respond-action`}>Auto Response Action</Label>

                                        <Input
                                            type="select"
                                            name={`high-cost-alerts-auto-respond-action`}
                                            id={`high-cost-alerts-auto-respond-action`}
                                            value={noResponseAction}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setNoResponseAction(selectedValue);
                                                if (selectedValue === 'SubmitLimit') {
                                                    setLimit(1);
                                                    setLimitFieldHasChanged(true);
                                                } else {
                                                    handleInputChange(selectedValue, alertType.widget, 'AutoRespondAction');
                                                }
                                            }}
                                        >
                                            <option value="">No Auto Response Action</option>
                                            {alertType.autoResponseActions.map((action, index) => (
                                                <option key={index} value={action.value}>{action.label}</option>
                                            ))}
                                        </Input>

                                        {noResponseAction === 'SubmitLimit' && (
                                            <div className='mt-4 d-flex align-items-center'>
                                                <Label className="mr-2" for={`high-cost-alerts-submit-limit`}>Limit Count</Label>
                                                <div className='d-flex'>
                                                    <Input
                                                        type="number"
                                                        min={1}
                                                        max={9}
                                                        value={limit}
                                                        onChange={(e) => {{ 
                                                            setLimit(e.target.value);
                                                            setLimitFieldHasChanged(true);
                                                        }}}
                                                        style={{ width: '65px' }}
                                                        className="custom-input"
                                                    />
                                                    {limitFieldHasChanged && <Button className="mx-2" color="primary" onClick={(e) => { 
                                                        handleInputChange(limit, alertType.widget, 'SubmitLimit', setLimitFieldHasChanged); 
                                                    }}>Save</Button>}
                                                </div>
                                            </div>
                                        )}
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

const FacilitySettings = () => {
    const alertTypes = [
        {
            widget: 'HighCost',
            title: 'Authorization',
            autoResponseActions: [
                {value: 'Approve', label: 'Approve'},
                {value: 'Deny', label: 'Deny'},
                {value: 'ApproveOnce', label: 'Approve Once'},
                {value: 'SubmitLimit', label: 'Approve Limit'},
            ]
        },
        {
            widget: 'HighCostAlerts',
            title: 'High Cost Alerts',
            autoResponseActions: [
                {value: 'Approve', label: 'Approve'},
                {value: 'Deny', label: 'Deny'},
                {value: 'SubmitLimit', label: 'Approve Limit'},
            ]
        },
    ];

    const [loading, setLoading] = useState(false);
    const { facID } = useParams();
    const [facility, setFacility] = useState(null);

    const getFacility = () => {
        setLoading(true);
        SrxApi.get('/facility/' + facID)
            .then((res) => {
                setFacility(res.data.facility);
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getFacility();
        setLoading(false);
    }, []);
    
    return (
        <React.Fragment>
            {loading ?
                <div className="page-loading-loader-div w-100 d-flex justify-content-center align-items-center">
                    <h4>Loading Settings...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                :
                <Container fluid>
                    <h3 className="text-center">
                        Facility Settings for{' '}
                        <Badge style={{ fontSize: '15px' }} className="badge badge-secondary py-1">
                            {facility?.facName}
                        </Badge>
                    </h3>

                    {/* loop alertTypes and insert AlertSetting for each */}
                    {facility && alertTypes.map((alertType, index) => (
                        <Row key={index}>
                            <AlertSetting facility={facility} alertType={alertType} />
                        </Row>
                    ))}
                    
                </Container>
            }
        </React.Fragment>

    );

};
export default FacilitySettings;
