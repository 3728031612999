import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import classNames from 'classnames';
import versionNotes from '../release-notes/versions.json';
import DOMPurify from 'dompurify';

const Versions = props => {
    return(
        <>
        <Row>
            <Col>
                <h2>Specialty365 Versions</h2>
            </Col>
        </Row>
        <Row className='justify-content-center mt-3'>
            <Col md={10} lg={8} xl={6}>
        {/* <Card>
        <CardHeader 
            className='d-flex align-items-center justify-content-center bg-primary text-white position-relative gradient-modal' 
            style={{ borderTopLeftRadius: '11px', borderTopRightRadius: '11px',
            background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)"
            }}
            >  
            <h4>
            Versions
            </h4>
        </CardHeader>
            <CardBody> */}
            {versionNotes.sort((a, b) => parseFloat(a.version) > parseFloat(b.version) ? -1 : 1).map(note => (
                <>
                {/* <Row  className='mb-3'>
                    <Col className='d-flex align-items-center'>
                        <div className='mr-2' style={{ height: '1px', width: '100px', borderTop: '2px dashed' }}>

                        </div>
                        <div>
                            <b>{note.date}</b>
                        </div>
                    </Col>
                </Row> */}
                <Card className="ribbon-box">
                    <CardBody>
                        <div className='float-left'>
                        <div className={classNames('ribbon-two', 'ribbon-two-' + 'primary')}>
                            <span>{note.version}</span>
                        </div>
                        </div>
                        <h5 className={classNames('text-' + 'dark', 'mt-0', 'float-right', 'ml-4')}>Version: {note.version} - {note.title}</h5>
                        <div className="ribbon-content">
                            <p className="mb-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.description)}}>
                            </p>
                        </div>
                        <hr />
                        <div className="ribbon-content">
                            <p className="mb-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize('<b>Admin Update: </b><br>' + note.pharmUserDescription)}}>
                            </p>
                        </div>
                        <hr />
                        <div className='text-muted'>
                            <b>{note.date}</b> - <b>By: {note.author}</b>
                        </div>
                    </CardBody>
                </Card>
                </>
            ))}
        {/* <Card className="ribbon-box">
            <CardBody>
                <div className={classNames('ribbon-two', 'ribbon-two-' + 'primary')}>
                    <span>Ribbon 1</span>
                </div>
                <p className="mb-0">
                    Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In
                    egestas mattis dui. Aliquam mattis dictum aliquet. Nulla sapien mauris, eleifend et sem ac, commodo
                    dapibus odio.
                </p>
            </CardBody>
        </Card> */}
        {/* </CardBody>
        </Card> */}
        </Col>
        </Row>
        </>
    )
}

export default Versions;