import axios from 'axios';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';

export const CurrentTimeContext = createContext();

export const CurrentTimeProvider = (props) => {

    const [currentTime, setCurrentTime] = useState(Date.now())

    useEffect(() => {
        let interval = setInterval(() => setCurrentTime(Date.now), 1 * 1000)
        return () => clearInterval(interval)
    }, [])
    
    return (
        <CurrentTimeContext.Provider value={{ currentTime }}>
            {props.children}
        </CurrentTimeContext.Provider>
    );
}