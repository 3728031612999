import React, { useContext, useEffect, useState } from 'react';
import useMediprocityScript from '../custom-hooks/useMediprocityScript';
import { Collapse, Button, ButtonGroup } from 'reactstrap';
import { Icon } from '@iconify/react';
import arrowCollapseDown from '@iconify/icons-mdi/arrow-collapse-down';
import arrowExpandUp from '@iconify/icons-mdi/arrow-expand-up';
import formatHorizontalAlignLeft from '@iconify/icons-mdi/format-horizontal-align-left';
import formatHorizontalAlignRight from '@iconify/icons-mdi/format-horizontal-align-right';
import arrowExpandHorizontal from '@iconify/icons-mdi/arrow-expand-horizontal';
import { UserContext } from '../contexts/UserContext';

const MediprocityPortal = ({ isCondensed }) => {

    const { user } = useContext(UserContext);

    const [isOpen, setIsOpen] = useState(true)

    const [horizontalPosition, setHorizontalPosition] = useState('fullWidth')

    const toggleHorizontalPosition = (position) => {
        if (horizontalPosition !== position)
            setHorizontalPosition(position)
    }

    useMediprocityScript()

    return (
        // <div style={{ position: 'fixed' }} className={"parent-div-mediprocity " + horizontalPosition}>
        // <div className={`w-100 bg-secondary d-flex ${!isCondensed ? 'justify-content-between' : 'justify-content-end'}`} style={{ height: '40px' }}>
        //     {!isCondensed && <ButtonGroup className='ml-1 my-1' >
        //         <Button color="light" className='d-flex justify-content-center align-items-center' 
        //         onClick={() => toggleHorizontalPosition('left')}
        //         active={horizontalPosition === 'left'}>
        //             <Icon icon={formatHorizontalAlignLeft} height="27" width="27"/></Button>
        //         <Button color="light" className='d-flex justify-content-center align-items-center mx-1'
        //         onClick={() => toggleHorizontalPosition('fullWidth')} 
        //         active={horizontalPosition === 'fullWidth'}><Icon icon={arrowExpandHorizontal} height="27" width="27"/></Button>
        //         <Button color="light" className='d-flex justify-content-center align-items-center' 
        //         onClick={() => toggleHorizontalPosition('right')}
        //         active={horizontalPosition === 'right'}><Icon icon={formatHorizontalAlignRight} height="27" width="27"/></Button>
        //     </ButtonGroup>}
        //     <Button color="light" className='d-flex justify-content-center align-items-center mr-1 my-1' onClick={() => setIsOpen(!isOpen)}>
        //     {isOpen ? <Icon icon={arrowCollapseDown} height="20" width="20" /> : <Icon icon={arrowExpandUp} height="20" width="20" />}
        //     </Button>
        // </div>
        <div isOpen={isOpen} className={`med-portal ${isOpen ? 'open' : 'closed'}`}>
            <div id='chat-panel' className={`chatStyle ${isOpen ? 'h-100 w-100' : ''}`}>
                {!user.mediprocityUser && <iframe className="h-100 w-100" src="https://secure.specialtyrxnow.com/"></iframe>}
            </div>
        </div>
        // </div>
    )
}

export default MediprocityPortal;