import axios from 'axios';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';

export const ActiveAlertContext = createContext();

export const ActiveAlertProvider = (props) => {

    const [activeAlert, setActiveAlert] = useState(null);
    
    return (
        <ActiveAlertContext.Provider value={{ activeAlert, setActiveAlert }}>
            {props.children}
        </ActiveAlertContext.Provider>
    );
}