import React, { Suspense, useContext } from 'react'
import MediprocityPortal from '../utility-components/MediprocityPortal';
import useMediprocityScript from '../custom-hooks/useMediprocityScript';
import { UserContext } from '../contexts/UserContext';


function SpecialtyRxNow() {
    const { user } = useContext(UserContext);
    const emptyLoading = () => <div></div>;

    // const login = () => {
    //     var iframe = document.getElementById('mediprocity-frame');
    //     console.log(iframe);
    //     console.log('jjkdhdhdjk');
    //     // console.log(iframe.contentWindow);
    //     // useMediprocityScript()
    //     let options = {}
    //     options.email = user.mediprocityInfo.user;
    //     options.password = user.mediprocityInfo.password;
    //     options.encryption_key = user.mediprocityInfo.encryptionKey;
    //     options.url = 'https://secure.specialtyrxnow.com/';

    //     iframe.contentWindow.postMessage(options, options.url)
    //     console.log(iframe.contentWindow);
    //     //  let iframe?.postMessage(options, options.url)
    // }


    // 

    return (<>
        {!!user && <Suspense fallback={emptyLoading()}>
            <MediprocityPortal
                isCondensed={false}
            />
        </Suspense>}
    </>

    )
}

export default SpecialtyRxNow
