import React, { useContext, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';

const SortCarets = ({ sortDirection }) => (
    <>
        {sortDirection === 'asc' ? 
        <Icon icon={arrowUp} color="black"/>
        :
        <Icon icon={arrowDown} color="black"/>
        }
    </>
)

export default SortCarets;