import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown,
    Badge,
    InputGroup, InputGroupAddon
} from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { UserContext } from '../contexts/UserContext';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import { Icon } from '@iconify/react';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import roundDeleteForever from '@iconify/icons-ic/round-delete-forever';
import plusIcon from '@iconify/icons-mdi/plus';
import accountEye from '@iconify/icons-mdi/account-eye';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import accountIcon from '@iconify/icons-mdi/account';
import Select from 'react-select';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import mdiAccountCogOutline from '@iconify/icons-mdi/account-cog-outline';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { SrxApi } from '../api/SrxApi';
import accountDetails from '@iconify/icons-mdi/account-details';
import officeBuildingOutline from '@iconify/icons-mdi/office-building-outline';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import cloneDeep from 'lodash.clonedeep';
import { confirm } from '../utility-components/ConfirmModal';
import deleteIcon from '@iconify/icons-mdi/delete';
import lockReset from '@iconify/icons-mdi/lock-reset';
import accountEditOutline from '@iconify/icons-mdi/account-edit-outline';
import { UserRolesContext } from '../contexts/UserRolesContext';
import MaskedInput from 'react-text-mask';
// import Settings from '../UserSettings'
import Settings from '../custom-components/UserSettings'

const Users = () => {

    const { faciToken, setFaciToken, user } = useContext(UserContext);

    const [users, setUsers] = useState(null);
    const [deletedUser, setDeletedUser] = useState(null);

    const [entityId, setEntityId] = useState('');
    const [UserId, setUserId] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const [userFaciModal, setUserFaciModal] = useState({
        isOpen: false,
        user: null
    })

    const { facilities } = useContext(SelectedFacilitiesContext);
    const [numberFacilities, setNumberFacilities] = useState(user.numberFacilities);

    const [resetPasswordModal, setResetPasswordModal] = useState({
        isOpen: false,
        data: ''
    });

    function closePasswordModal() {
        setResetPasswordModal({
            isOpen: false,
            data: ''
        })
    }

    function closeUserFaciModal() {
        setUserFaciModal({
            isOpen: false,
            user: null
        })
    }

    const [editUserSettingsModal, setEditUserSettingsModal] = useState({
        isOpen: false,
        data: {
            user: null
        }
    });

    function closeEditUserSettingsModal() {
        setEditUserSettingsModal({
            isOpen: false,
            data: {
                user: null
            }
        });
    }

    
    const [deleteUserModal, setDeleteUserModal] = useState({
        isOpen: false,
        data: ''
    });
    
    
    function closeDeleteUserModal() {
        setDeleteUserModal({
            isOpen: false,
            data: ''
        });
    }

    // const undoDelete = () => {
    //     console.log(deletedUser)
    //     if (deletedUser) {
    //         // const payload = {
    //         //     ...deletedUser,
    //         //     faciID: deletedUser.entityTypeAndRole[0].facid,
    //         //     email: deletedUser.email,
    //         //     entities: JSON.stringify(deletedUser.entityTypeAndRole[0].facid),
    //         //     userRole: deletedUser.entityTypeAndRole[0].userRole,
    //         //     UserRole: deletedUser.entityTypeAndRole[0].userRole,
    //         //     UserType: 'FacilityUser',
    //         //     title: deletedUser.title,
    //         //     UserId: deletedUser.id,
    //         //     model: deletedUser.id,
    //         //     password: deletedUser.mediprocityInfo.password,

    //         // };

    //         // SrxApi.post('/createuser', payload)
    //         //     .then((res) => {
    //         //         console.log(res.data);
    //         //         toast.success(`${deletedUser.displayName || deletedUser.email} added successfully`);
    //         //     })
    //         //     .catch((err) => {
    //         //         toast.error('Error adding user: ' + err.message);
    //         //     });
    //         //update users list
    //         const updatedUsers = [...users, deletedUser];
    //         console.log(users)
    //         console.log(updatedUsers)
    //         setUsers(updatedUsers);
    //         setDeletedUser(null); 
    //     }
    // }

    const [editProfileModal, setEditProfileModal] = useState({
        isOpen: false,
        data: {
            user: null,
            // numberPharmacies: 0
        }
    });

    function closeEditProfileModal() {
        setEditProfileModal({
            isOpen: false,
            data: {
                user: null,
            }
        });
    }

    // useEffect(() => {
    //     if (user?.userType !== 'FacilityUser') {
    //         history.replace('/dashboard')
    //     }
    // }, [user])

    const [createFacilityUserModalIsOpen, setCreateFacilityUserModalIsOpen] = useState(false);

    const [createFacilityUserModalKey, setCreateFacilityUserModalKey] = useState(false);

    // this function only closes the modal and does not open it
    function closeCreateFacilityUserModal() {
        setCreateFacilityUserModalIsOpen(false)
        // reset the key of the modal when closing it in order to reset the data. The timeout is added for ux purposes to allow the modal to close before resetting it
        setTimeout(() => setCreateFacilityUserModalKey(Date.now()), 500)
    }

    function getFacilityUsers() {
        // set users to false in order to display a loading state
        setUsers(null)
        SrxApi.get('/getusers')
            .then(res => {
                setUsers(res.data.users)
            })
            .catch(err => {
                setUsers([])
                toast.error('Error retrieving facility users')
                if (err.response?.status === 401) {
                    localStorage.removeItem('FACI_TOKEN');
                    setFaciToken(null);
                    history.replace('/account/login')
                }
            })
    }
    let history = useHistory();

    useEffect(() => {
        SrxApi.get('/getusers')
            .then(res => {
                setUsers(res.data.users)
            })
            .catch(err => {
                if (err.response?.status === 401) {
                    localStorage.removeItem('FACI_TOKEN');
                    setFaciToken(null);
                    history.replace('/account/login')
                }
            })
    }, [])

    function searchUsers(array) {
        if (searchTerm) {
            let results = array.filter(user => (user.displayName && user.displayName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) || user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }

    const [sortBy, setSortBy] = useState({
        sortField: 'displayName',
        sortDirection: 'asc'
    })

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'email':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.email < b.email ? -1 : 1)
                else
                    return array.sort((a, b) => a.email > b.email ? -1 : 1)
            case 'displayName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.displayName < b.displayName ? -1 : 1)
                else
                    return array.sort((a, b) => a.displayName > b.displayName ? -1 : 1)
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    const canChangeUserSettings = (user_) => {
        let thePermission = 'allowToSetSettingsForUsers'

        let selectedUserRoles = user_?.entityTypeAndRole || []; //returns entityTypeAndRole array/s of selected user
        let signedInUserFacID = facilities?.map((f) => f.facID); // returns array of facID of signed in user
        // convert signedInUserFacID into an array of objects
        signedInUserFacID = signedInUserFacID.map((facid) => {
            return {
                facid
            }
        })
        function findMatchingFacid(signedInUserFacID, selectedUserRoles) {
            let matchingFacid = [];
            // Loop through the facID array of the signed in user
            for (const role1 of signedInUserFacID) {
                const facid1 = role1.facid;

                for (const role2 of selectedUserRoles) {
                    const facid2 = role2.facid;

                    if (facid1 === facid2) {
                        matchingFacid.push(facid1);
                    }
                }
            }

            return matchingFacid;

        }
        let hasMatchingFacid = findMatchingFacid(signedInUserFacID, selectedUserRoles) // returns common facID of both users
        if (hasMatchingFacid.length === 0) {
            console.log("No matching facid found.");
            return false;
        }

        let matchingFacility = facilities?.filter((facility) => hasMatchingFacid.includes(facility.facID)); //returns common facilties!
        return (matchingFacility[0]?.permissions[thePermission])
    }

    const canResetPassword = (user_) => {
   
        let selectedUserRoles = user_?.entityTypeAndRole || []; //returns entityTypeAndRole array/s of selected user
        let signedInUserFacID = facilities?.map((f) => f.facID); // returns array of facID of signed in user
        // convert signedInUserFacID into an array of objects
        signedInUserFacID = signedInUserFacID.map((facid) => {
            return {
                facid
            }
        })
        function findMatchingFacid(signedInUserFacID, selectedUserRoles) {
            let matchingFacid = [];
            // Loop through the facID array of the signed in user
            for (const role1 of signedInUserFacID) {
                const facid1 = role1.facid;

                for (const role2 of selectedUserRoles) {
                    const facid2 = role2.facid;

                    if (facid1 === facid2) {
                        matchingFacid.push(facid1);
                    }
                }
            }

            return matchingFacid;

        }
        let hasMatchingFacid = findMatchingFacid(signedInUserFacID, selectedUserRoles) // returns common facID of both users
        if (hasMatchingFacid.length === 0) {
            console.log("No matching facid found.");
            return false;
        }

        let matchingFacility = facilities?.filter((facility) => hasMatchingFacid.includes(facility.facID)); //returns common facilties!
        let selectedUserRole = [...new Set(user_?.entityTypeAndRole.map(r => r.userRole))]; //returns selected user role/s without duplicates
        let permissionRole = selectedUserRole.map(role => `user_Edit${role}`); // returns user_EditCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingFacility.map((facility) => facility.permissions); //returns all user permissions
        for (let key of permissionRole) {
            let hasFacilityPermissions = allUserPermissions.some((permissions) => permissions && permissions.hasOwnProperty(key));
            let hasAnyPermission = false;
            if (matchingFacility && hasFacilityPermissions) {
                console.log(`User has permission for ${permissionRole}: ${hasFacilityPermissions}`);
                hasAnyPermission = true;
                return true;
            } else {
                console.log("No matching facilities found.");
                return false;
            }
        }
        
    }

    const canDelete = (user_) => {
        let selectedUserRoles = user_?.entityTypeAndRole || []; //returns entityTypeAndRole array/s of selected user
        let signedInUserFacID = facilities?.map((f) => f.facID); // returns array of facID of signed in user
        // convert signedInUserFacID into an array of objects
        signedInUserFacID = signedInUserFacID.map((facid) => {
            return {
                facid
            }
        })
        function findMatchingFacid(signedInUserFacID, selectedUserRoles) {
            let matchingFacid = [];
            // Loop through the facID array of the signed in user
            for (const role1 of signedInUserFacID) {
                const facid1 = role1.facid;
                
                for (const role2 of selectedUserRoles) {
                    const facid2 = role2.facid;

                    if (facid1 === facid2) {
                        matchingFacid.push(facid1);
                    }
                }
            }

            return matchingFacid;

        }
        let hasMatchingFacid = findMatchingFacid(signedInUserFacID, selectedUserRoles) // returns common facID of both users
        if (hasMatchingFacid.length === 0) {
            console.log("No matching facid found.");
            return false;
        }

        let matchingFacility = facilities?.filter((facility) => hasMatchingFacid.includes(facility.facID)); //returns common facilties!
        let selectedUserRole = [...new Set(user_?.entityTypeAndRole.map(r => r.userRole))]; //returns selected user role/s without duplicates
        let permissionRole = selectedUserRole.map(role => `user_Delete${role}`); // returns user_EditCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingFacility.map((facility) => facility.permissions); //returns all user permissions
        console.log(allUserPermissions)
        for (let key of permissionRole) {
            let hasFacilityPermissions = allUserPermissions.some((permissions) => permissions && permissions.hasOwnProperty(key));
            let hasAnyPermission = false;
            if (matchingFacility && hasFacilityPermissions) {
                console.log(`User has permission for ${permissionRole}: ${hasFacilityPermissions}`);
                hasAnyPermission = true;
                return true;
            } else {
                console.log("No matching facilities found.");
                return false;
            }
        }
        
    }

    if (users)
        return (
            <>
                <div className='mt-3 mx-2'>
                    <h2>Facility Users</h2>
                    <Card>
                        <CardBody>
                            {/* <h4 className="header-title">Product List</h4>
                <p className="text-muted font-14 mb-4">A list of all existing Greenbowl Products</p> */}
                            <Row className="mb-2 d-flex justify-content-between">
                                <Col xs={'auto'}>
                                    <Button color='primary'
                                        onClick={() => {
                                            setCreateFacilityUserModalIsOpen(true)
                                        }}
                                        >
                                        <i className="mdi mdi-plus-circle mr-2"></i>Add Facility User
                                    </Button>
                                </Col>
                                <Col className="text-right" md={5}>
                                    <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <Label className='mr-2 mb-0'><b>Search:</b></Label>
                                        <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table className="mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th onClick={() => onClickSort('displayName')} className="th-hover">Name {sortBy.sortField === 'displayName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                        <th onClick={() => onClickSort('email')} className="th-hover">Email {sortBy.sortField === 'email' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                        <th>Phone</th>
                                        <th>Facilities</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 ?
                                        sortByField(searchUsers(users))
                                            .map((user_, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row" style={{ verticalAlign: 'middle' }}>{user_.displayName}{(user_.email === user.email) && <Badge color="dark" className='ml-1' style={{ fontSize: '12px' }}>Self</Badge>}</th>
                                                        <td style={{ verticalAlign: 'middle' }}>{user_.email}</td>
                                                        <td style={{ verticalAlign: 'middle' }}>{formatPhoneNumber(user_.phone)}</td>
                                                        <td style={{ verticalAlign: 'middle' }} className="position-relative">
                                                            <b>{user_.numberFacilities}</b>
                                                            <UncontrolledButtonDropdown className="position-absolute" style={{ right: '20px', top: '5px' }}>
                                                                <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                                                    <Icon icon={dotsHorizontal} width="23" height="23" />
                                                                </DropdownToggle>
                                                                <DropdownMenu right style={{ minWidth: 'max-content' }}>
                                                                    {/* <DropdownItem className='px-3 py-1'
                                            ><Icon icon={accountDetails} width="20" height="20" className="mr-1"/>Edit Details</DropdownItem> */}
                                                                    <DropdownItem className='px-3 py-1'
                                                                        onClick={() => setUserFaciModal({
                                                                            isOpen: true,
                                                                            user: user_
                                                                        })}
                                                                    ><Icon icon={officeBuildingOutline} width="20" height="20" className="mr-1" />View / Edit Facilities</DropdownItem>
                                                                    {canResetPassword(user_) &&
                                                                        <DropdownItem className='px-3 py-1'
                                                                        disabled={!user_.requestorIsAdmin}
                                                                            onClick={() => setResetPasswordModal({
                                                                                isOpen: true,
                                                                                data: user_
                                                                            })}
                                                                        ><Icon icon={lockReset} width="20" height="20" className="mr-1" />Reset Password</DropdownItem>
                                                                    }
                                                                    {canResetPassword(user_) &&
                                                                        <DropdownItem className='px-3 py-1'
                                                                            onClick={() => setEditProfileModal({
                                                                                isOpen: true,
                                                                                data: user_

                                                                            })}
                                                                        ><Icon icon={accountEditOutline} width="20" height="20" className="mr-1" />Edit Name and Phone</DropdownItem>

                                                                    }
                                                                    {canChangeUserSettings(user_) &&
                                                                        <DropdownItem className='px-3 py-1'
                                                                            onClick={() => setEditUserSettingsModal({
                                                                                isOpen: true,
                                                                                data: user_

                                                                            })}
                                                                        ><Icon icon={mdiAccountCogOutline} width="20" height="20" className="mr-1" />Edit User Settings</DropdownItem>

                                                                }
                                                            {canDelete(user_) && 
                                                                        <DropdownItem className='px-3 py-1'
                                                                            onClick={() => setDeleteUserModal({
                                                                                isOpen: true,
                                                                                data: user_
                                                                            })}
                                                                        ><Icon icon={roundDeleteForever} width="20" height="20" className="mr-1" />Delete User</DropdownItem>
                                                                    }
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        :
                                        <tr>
                                            <td colSpan={4} className='text-center'><b>No Users</b></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    {/* {deletedUser && (
                    <div className="mt-2">
                        <p>{deletedUser.displayName || deletedUser.email} has been deleted. <b>Undo the deletion?</b></p>
                        <Button color="secondary" onClick={undoDelete}>Undo</Button>
                    </div>
                    )} */}
                    <CreateFacilityUserModal isOpen={createFacilityUserModalIsOpen} toggle={closeCreateFacilityUserModal} key={createFacilityUserModalKey}
                        getFacilityUsers={getFacilityUsers}
                    />
                    <ResetPasswordModal isOpen={resetPasswordModal.isOpen} toggle={closePasswordModal} user={resetPasswordModal.data} entityId={entityId} />
                    <EditProfileModal isOpen={editProfileModal.isOpen} toggle={closeEditProfileModal} user={editProfileModal.data} entityId={entityId} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} numberFacilities={editProfileModal.data.numberFacilities} setNumberFacilities={setNumberFacilities} />
                    <EditFacilitiesModal isOpen={userFaciModal.isOpen} user={userFaciModal.user} toggle={closeUserFaciModal} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} />
                    <EditUserSettingsModal isOpen={editUserSettingsModal.isOpen} toggle={closeEditUserSettingsModal} user={editUserSettingsModal.data} entityId={entityId} setFaciToken={setFaciToken} users={users} setUsers={setUsers} contextUser={user} />
                    <DeleteUserModal isOpen={deleteUserModal.isOpen} toggle={closeDeleteUserModal} user={deleteUserModal.data} entityId={entityId} users={users} setUsers={setUsers} contextUser={user} UserId={UserId} deleteUserModal={deleteUserModal} setDeleteUserModal={setDeleteUserModal} closeDeleteUserModal={closeDeleteUserModal} deletedUser={deletedUser} setDeletedUser={setDeletedUser}/>
                </div>
            </>
        );

    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
                <h4>Loading Facility Users...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

const DeleteUserModal = ({ isOpen, toggle, user, entityId, users, setUsers, contextUser, UserId, deletedUser, setDeletedUser, setDeleteUserModal}) => {
            const deleteUser = (deletedUser) => {
                const payload = {
                  email: deletedUser.email,
                  entityId: deletedUser.entityTypeAndRole[0].facid,
                  UserId: deletedUser.id,
                };
              
                SrxApi.post('/deleteuser', payload)
                  .then((res) => {
                    const updatedUsers = users.filter((user) => user.email !== deletedUser.email);
                    setUsers(updatedUsers);
                    setDeletedUser(deletedUser); // Store the deleted user for potential undo
                    SrxApi.get('/getusers')
                      .then((res) => {
                        setUsers(res.data.users);
                      })
                      .catch((err) => {
                        toast.error('Error retrieving user list');
                      });
                    setDeleteUserModal({ isOpen: false, data: '' });
                    // setDeletedUser(null);
                    toggle();
                    console.log(deletedUser)
                    toast.success(`${deletedUser.displayName || deletedUser.email} deleted successfully`);
                  })
                  .catch((err) => {
                    toast.error('Error deleting user: ' + err.message);
                  });
              };

              const [userFacilities, setUserFacilities] = useState(null);
                useEffect(() => {
                    if (isOpen) {
                        setUserFacilities([]);
                        SrxApi.get('/userfacilities', {
                            params: {
                                email: user.email
                            }
                        })
                            .then(({ data }) => setUserFacilities(data.facilities))
                            .catch(err => {
                                toast.error('Error: ' + err.message)
                            })
                    }
                }, [isOpen, user.email])
              
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
              <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Delete User
              </ModalHeader>
              <ModalBody>
                <h5>Are you sure you want to delete <b>{user.displayName}</b>?</h5>
                {userFacilities && (
                <table>
                    <thead>
                    <tr>
                        {userFacilities.length > 0 && <th>Facilities</th>}
                        {/* <th >Facility ID</th>
                        <th>Facility Name</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {userFacilities
                        .sort((a, b) => (a.facName > b.facName ? 1 : -1))
                        .map((facility, index) => (
                        <tr key={index}>
                            <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>{facility.facID}</td>
                            <td style={{ verticalAlign: 'middle', fontWeight: 'normal' }}>{facility.facName}</td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={() => deleteUser(user)}>Delete</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
              </ModalFooter>
            </Modal>
        );
};




const EditProfileModal = ({ isOpen, toggle, user, users, setUsers, contextUser, setFaciToken, numberFacilities, setNumberFacilities }) => {
    const [displayName, setDisplayName] = useState(user.displayName || '');
    const [phone, setPhone] = useState(formatPhoneNumber(user.phone || ''));
    const [phoneInput, setPhoneInput] = useState(user.phone || '');
    const [phoneError, setPhoneError] = useState('');

    const [userFacilities, setUserFacilities] = useState(null);



    function formatPhoneNumber(phone) {
        if (!phone) return phone;
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    function handlePhoneChange(e) {
        const inputValue = e;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        if (numericValue.length === 10) {
            setPhoneError('');
        } else {
            setPhoneError('Phone number must have 10 digits');
        }
        setPhoneInput(numericValue);
        setPhone(formatPhoneNumber(numericValue));
    }
  
      function handleSave() {
          if (!displayName && !phone) {
            toast.error('Display Name and Phone are required fields');
            return;
        }

        if (phoneInput && phoneInput.length !== 10) {
            setPhoneError('Phone number must have 10 digits');
            return;
        }

        let profileUpdatePayload = {
            displayName,
            phone: phoneInput.toString(),
            UserRole: user.entityTypeAndRole[0].userRole,
            email: user.email,
            entityId: user.entityTypeAndRole[0].facid,
        };


        SrxApi.post('/edituserinfo', profileUpdatePayload)
            .then(async res => {

                const updatedUserIndex = users.findIndex((u) => u.email === user.email);
                if (updatedUserIndex !== -1) {
                    let updatedUsers = [
                        ...users
                    ];
                    updatedUsers[updatedUserIndex] = res.data.userInfo
                    setUsers(updatedUsers);
                    let copyOfUserFacilities = [
                        ...userFacilities
                    ]
                    setUserFacilities(copyOfUserFacilities);
                    let newNumberOfFacilities = userFacilities.length;
                    let newUserFacilities = await SrxApi.get('/userfacilities', {
                        params: {
                            email: user.email
                        }
                    })
                    //   add new number of facilities to list user
                    updatedUsers.find(({ email }) => email === user.email).numberFacilities = newNumberOfFacilities;
                    SrxApi.get('/getusers')
                        .then(res => {
                            setUsers(res.data.users)
                            console.log("Updated users state:", res.data.users);
                        })
                    //   setUsers(updatedUsers)
                    setUserFacilities(newUserFacilities.data.facilities)
                    setNumberFacilities(user.numberFacilities);
                    setPhoneError('')
                    toast.success('Profile updated successfully');
                }
              toggle(); 
          })
          .catch((err) => {
              toast.error(err.message);
          })
      };
      
    useEffect(() => {
        if (isOpen) {
            setUserFacilities([]);
            SrxApi.get('/userfacilities', {
                params: {
                    email: user.email
                }
            })
                .then(({ data }) => setUserFacilities(data.facilities))
                .catch(err => {
                    toast.error('Error: ' + err.message)
                })
        }
        if (!isOpen) {
            setDisplayName(user.displayName || '');
            setPhoneInput(user.phone || '');
            setPhone(formatPhoneNumber(user.phone || ''));
            setNumberFacilities(user.numberFacilities);

        }
    }, [isOpen, user]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">Edit Profile for <i>"{user.displayName}"</i></ModalHeader>
            <ModalBody>
                <AvForm >
                    <AvGroup className="form-group">
                        <Label for="confirm-name">New Name</Label>
                        <AvField
                            type="text"
                            placeholder={user.displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            value={displayName}
                            name="newName"
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Name must include a minimum of 4 characters' }
                            }}
                        />
                    </AvGroup>
                    <AvGroup className="form-group">
                        <Label for="confirm-phone">New Phone</Label>
                        <AvField
                            type="text"
                            placeholder="Enter new Phone"
                            onChange={(e) => handlePhoneChange(e.target.value)}
                            value={phone}
                            name="newPhone"
                            required
                        />
                        {phoneError && <div className="text-danger">{phoneError}</div>}
                    </AvGroup>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const EditUserSettingsModal = ({ isOpen, toggle, user, users, setUsers, contextUser, }) => {

    const handleSave = () => {
        console.log('saving...');
    }
    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ minWidth: '800px' }}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">Edit settings for <i>"{user.displayName}"</i></ModalHeader>
            <ModalBody>

                <Settings userData={user} toggle={toggle} users={users} setUsers={setUsers} source='facility' />
            </ModalBody>
            {/* <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter> */}
        </Modal>
    );
};

const EditFacilitiesModal = ({ toggle, user, isOpen, setFaciToken, users, setUsers, contextUser }) => {

    const { facilities } = useContext(SelectedFacilitiesContext);

    const { facilityUserRoles } = useContext(UserRolesContext);

    const [userFacilities, setUserFacilities] = useState(null);

    const [pendingUserFacilities, setPendingUserFacilities] = useState([]);

    const [userRoleLoading, setUserRoleLoading] = useState(false);

    const [selectedFacility, setSelectedFacility] = useState([])

    let history = useHistory();

    const canAddFacility = (user) => {
        let selectedUserRoles = user?.entityTypeAndRole || []; //returns entityTypeAndRole array/s of selected user
        let signedInUserFacID = facilities?.map((f) => f.facID); // returns array of facID of signed in user
        // convert signedInUserFacID into an array of objects
        signedInUserFacID = signedInUserFacID.map((facid) => {
            return {
                facid
            }
        })
        function findMatchingFacid(signedInUserFacID, selectedUserRoles) {
            let matchingFacid = [];
            // Loop through the facID array of the signed in user
            for (const role1 of signedInUserFacID) {
                const facid1 = role1.facid;

                for (const role2 of selectedUserRoles) {
                    const facid2 = role2.facid;

                    if (facid1 === facid2) {
                        matchingFacid.push(facid1);
                    }
                }
            }
            return matchingFacid;


        }
        let hasMatchingFacid = findMatchingFacid(signedInUserFacID, selectedUserRoles) // returns common facID of both users
        if (hasMatchingFacid.length === 0) {
            console.log("No matching facid found.");
            return false;
        }

        let matchingFacility = facilities?.filter((facility) => hasMatchingFacid.includes(facility.facID)); //returns common facilties!
        let selectedUserRole = [...new Set(user?.entityTypeAndRole.map(r => r.userRole))]; //returns selected user role/s without duplicates
        let userPermission = 'user_AddFacilityToUser'; // returns user_EditCustomerService, Manager, Pharmacist
        let allUserPermissions = matchingFacility.map((facility) => facility.permissions); //returns all user permissions
        let hasPermission = allUserPermissions.some((permissions) => permissions.hasOwnProperty(userPermission));
        if (matchingFacility && hasPermission) {
            console.log(`User has permission for ${userPermission}: ${hasPermission}`);
            return true;
        } else {
            console.log("No matching facilities found.");
            return false;
        }
    }

    useEffect(() => {

        if (isOpen) {
            setUserFacilities([]);
            SrxApi.get('/userfacilities', {
                params: {
                    email: user.email
                }
            })
                .then(({ data }) => setUserFacilities(data.facilities))
                .catch(err => {
                    if (err.response?.status === 401) {
                        localStorage.removeItem('FACI_TOKEN');
                        setFaciToken(null);
                        history.replace('/account/login')
                    }
                })
        }


    }, [isOpen])

    function onFaciRoleChange(role, user, facility) {

        let payload = {
            entityID: facility.facID,
            email: user.email,
            userRole: role
        }

        SrxApi.post('/edituser', payload)
            .then(res => {
                let copyOfUserFacilities = [
                    ...userFacilities
                ]

                copyOfUserFacilities.find(userFaci => userFaci.facID === facility.facID).userRole = role;

                setUserFacilities(copyOfUserFacilities);

                toast.success('User role successfully changed')

            })
            .catch(err => {
                toast.error('Error: ' + err.message)
            })
    }

    function onPendingFaciRoleChange(role, facility) {

        let copyOfPendingUserFacilities = [
            ...pendingUserFacilities
        ]

        copyOfPendingUserFacilities.find(userFaci => userFaci.facID === facility.facID).userRole = role;

        setPendingUserFacilities(copyOfPendingUserFacilities);
    }

    function addToPendingFacilities() {
        // if(userFacilities.findIndex(faci => faci.facID === selectedFacility[0].facID) !== -1){
        //     setSelectedFacility([])
        //     return toast.error('Facility has already been added to user')
        // }

        // set default role to user
        let tempSelected = cloneDeep(selectedFacility[0]);

        tempSelected.userRole = null;

        setPendingUserFacilities([
            ...pendingUserFacilities,
            tempSelected
        ])
        setSelectedFacility([])
    }

    function deletePendingFacility(id) {
        setPendingUserFacilities(pendingUserFacilities.filter(({ facID }) => facID !== id))
    }

    function savePendingFacilities() {
        if (pendingUserFacilities.findIndex(faci => !faci.userRole) >= 0)
            return toast.error('A role must be assigned to all users')

        let payload = {
            email: user.email,
            entities: pendingUserFacilities.map(({ facID, userRole }) => {
                return {
                    entityID: facID,
                    userRole
                }
            })
        }

        SrxApi.post('/adduserfacilities', payload)
            .then(res => {

                let newNumberOfFacilities = userFacilities.length + pendingUserFacilities.length;

                setUserFacilities([
                    ...userFacilities,
                    ...pendingUserFacilities.map(faci => ({
                        ...faci,
                        userType: user.userType
                    }))
                ])
                setPendingUserFacilities([])
                // add new number of facilities to list user
                let tempUsers = [
                    ...users
                ]

                tempUsers.find(({ email }) => email === user.email).numberFacilities = newNumberOfFacilities;

                setUsers(tempUsers)

                toast.success('User added to facilities')
            })
            .catch(err => toast.error('Error: ' + err.message))
    }

    async function removeUserFromSingleFacility(id, name) {

        if (!await confirm(`Remove <i>${user.displayName || user.email}</i> from <i>${name}</i>?`, `Removing user from facility`, "Confirm", "Cancel", "primary", "primary"))
            return

        let payload = {
            email: user.email,
            facID: id
        }
        SrxApi.post('/removefacility', payload)
            .then(res => {

                let newNumberOfFacilities = userFacilities.filter(({ facID }) => facID !== id).length

                setUserFacilities(userFacilities.filter(({ facID }) => facID !== id))

                // add new number of facilities to list user
                let tempUsers = [
                    ...users
                ]

                tempUsers.find(({ email }) => email === user.email).numberFacilities = newNumberOfFacilities;

                setUsers(tempUsers)

                toast.success((user.displayName || user.email) + " removed from " + name)
            })
            .catch(err => toast.error('Error: ' + err.message))
    }

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: "flex" }}>
            <div style={{
                color: disabled ? '#C0C4C8' : 'inherit'
            }}>{label}</div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
          </div> */}
        </div>
    );

    async function preToggle() {
        if (pendingUserFacilities.length && await confirm("There are unsaved pending facilities. Leave page without saving?", "Unsaved Changes!", "Leave", "Return to Page", "danger", "danger") === false)
            return
        setSelectedFacility([]);
        setPendingUserFacilities([]);
        toggle();
    }

    const checkIfUserIsAdmin = (facility) => {
        if (facility.userType?.toLowerCase() === 'pharmacyuser')
            return true
        else if (facility.userType?.toLowerCase() === 'superadmin')
            return true
        else if (facility.userType?.toLowerCase() === 'facilityuser') {
            if (facility.userRole?.toLowerCase() === 'facilityadmin')
                return true
            else
                return false
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={preToggle} size="lg" style={{ minWidth: '600px', maxWidth: 'max-content', width: '100%', minHeight: '500px' }} className="modal-dialog-scrollable">
            <ModalHeader toggle={preToggle} className="modal-colored-header bg-primary">
                View / Edit Facilities for <i>"{user?.displayName || user?.email}"</i>
                {(user?.email === contextUser.email) && <Badge color="dark" className='ml-1' style={{ fontSize: '14px' }}>Self</Badge>}
                {/* {isOpen && 
                    {user.displayName || user.email}
                } */}
            </ModalHeader>
            <ModalBody>
                {user?.userType?.toLowerCase() === 'facilityuser'
                    && canAddFacility(user) && <FormGroup>
                        <Label for="itemType">Select Facility</Label>
                        <InputGroup>
                            <Typeahead
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="facName"
                                multiple={false}
                                options={facilities
                                    .filter(faci => checkIfUserIsAdmin(faci))
                                    .filter(faci => (userFacilities?.findIndex(userFaci => userFaci.facID === faci.facID) === -1) && (pendingUserFacilities.findIndex(userFaci => userFaci.facID === faci.facID) === -1))
                                    .map(faci => faci)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                selected={selectedFacility}
                                onChange={(selected) => setSelectedFacility(selected)}
                                placeholder="Select facility..."
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="primary"
                                    onClick={addToPendingFacilities}
                                    disabled={!selectedFacility.length}
                                >Add</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>}
                {(!!pendingUserFacilities.length) && <>
                    <Card>
                        <CardBody className='p-2 m-2'
                            style={{
                                border: '2px dashed #BDC6CA',
                                borderRadius: '3px'
                            }}
                        >
                            <h4 className='ml-1'>Pending Facilities</h4>
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Facility ID</th>
                                        <th>Facility Name</th>
                                        <th>Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                    {pendingUserFacilities
                                        .sort((a, b) => a.facName > b.facName ? 1 : -1)
                                        .map((facility, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }}>{facility.facID}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{facility.facName}</td>
                                                    {/* <td>{user.isAdmin ?
                                    <><Icon icon={shieldAccount} width="24" height="24"/> Admin</> : 
                                    <><Icon icon={accountIcon} width="24" height="24"/> User</>}</td> */}
                                                    <td>

                                                        {user?.userType === 'FacilityUser'
                                                            ?
                                                            <div style={{ width: '140px' }}>
                                                                <Select
                                                                    className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    isSearchable={false}
                                                                    onChange={(e) => onPendingFaciRoleChange(e.value, facility)}
                                                                    hideSelectedOptions
                                                                    value={
                                                                        facilityUserRoles?.find(role => role.value === facility.userRole) || null
                                                                    }
                                                                    formatOptionLabel={formatOptionLabel}
                                                                    isOptionDisabled={(option) => option.disabled}
                                                                    options={
                                                                        facilityUserRoles
                                                                    }>
                                                                </Select>
                                                            </div>
                                                            :
                                                            <b><i>{user.userRole}</i></b>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <Button className="card-drop p-0 text-white"
                                                            onClick={() => deletePendingFacility(facility.facID)}
                                                        >
                                                            <Icon icon={deleteIcon} height="24" width="24" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <hr />
                            <div className='d-flex justify-content-end'>
                                <Button color="secondary" onClick={preToggle} className="mr-2">
                                    Cancel
                                </Button>
                                <Button color="primary"
                                    disabled={pendingUserFacilities.length < 1}
                                    onClick={savePendingFacilities}
                                >
                                    Save Pending Facilities
                                </Button>{' '}
                            </div>
                        </CardBody>
                    </Card>
                    <hr />
                </>}
                <><h4 className='ml-1'>Facilities</h4>
                    {userFacilities
                        ?
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>Facility ID</th>
                                    <th>Facility Name</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                {userFacilities.length > 0
                                    ?
                                    // .filter(faci => facilities.find(facility => facility.facID === faci.facID).isAdmin)
                                    userFacilities.sort((a, b) => a.facName > b.facName ? 1 : -1)
                                        .map((facility, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }}>{facility.facID}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{facility.facName}</td>
                                                    {/* <td>{user.isAdmin ?
                                    <><Icon icon={shieldAccount} width="24" height="24"/> Admin</> : 
                                    <><Icon icon={accountIcon} width="24" height="24"/> User</>}</td> */}
                                                    <td>
                                                        {facility.userType === 'FacilityUser'
                                                            ?
                                                            <div style={{ width: '140px' }}>
                                                                <Select
                                                                    className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    hideSelectedOptions
                                                                    isSearchable={false}
                                                                    isDisabled={userRoleLoading || !checkIfUserIsAdmin(facilities.find(faci => faci.facID === facility.facID)) || (user?.email === contextUser.email)}
                                                                    onChange={(e) => onFaciRoleChange(e.value, user, facility)}
                                                                    value={facility.userType === 'FacilityUser'
                                                                        ?
                                                                        facilityUserRoles?.find(role => {
                                                                            return role.value === facility.userRole
                                                                        })
                                                                        :
                                                                        facilityUserRoles?.find(role => {
                                                                            return role.value === facility.userRole
                                                                        })
                                                                    }
                                                                    formatOptionLabel={formatOptionLabel}
                                                                    isOptionDisabled={(option) => option.disabled}
                                                                    options={
                                                                        facility.userType === 'FacilityUser'
                                                                            ?
                                                                            facilityUserRoles
                                                                            :
                                                                            facilityUserRoles
                                                                    }>

                                                                </Select>
                                                            </div>
                                                            :
                                                            <b><i>{facility.userRole}</i></b>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <Button className="card-drop p-0"
                                                            disabled={!checkIfUserIsAdmin(facilities.find(faci => faci.facID === facility.facID)) || (user?.email === contextUser.email)}
                                                            onClick={() => removeUserFromSingleFacility(facility.facID, facility.facName)}
                                                        >
                                                            <Icon icon={deleteIcon} height="24" width="24" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    :
                                    <tr>
                                        <td colSpan={4} className='text-center'><b>No User Facilities</b></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        :
                        <div className='w-100 d-flex justify-content-center'>
                            <div>
                                <h4>Loading User Facilities...</h4>
                                <div className="bouncing-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    }</>
            </ModalBody>
            {/* <ModalFooter>
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
                <Button color="primary"
                disabled={pendingUserFacilities.length < 1}
                onClick={savePendingFacilities}
                >
                    Save Pending Facilities
                </Button>{' '}
            </ModalFooter> */}
        </Modal>
    )
}

const ResetPasswordModal = ({ isOpen, toggle, user }) => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function handleValidSubmit() {
        if (password !== confirmPassword)
            return toast.error('Passwords do not match')

        let resetPasswordPayload = {
            password,
            confirmPassword,
            email: user.email,
            entityId: user.entityTypeAndRole[0].facid
        }

        SrxApi.post('/adminpwdreset', resetPasswordPayload)
            .then(res => {
                toast.success(res.data.message + ' for ' + '"' + res.data.email + '"')
                toggle()
            })
            .catch(err => toast.error(err.message))
    }

    useEffect(() => {
        if (!isOpen) {
            setPassword('');
            setConfirmPassword('');
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Reset Password for <i>"{user.displayName}"</i>
            </ModalHeader>
            <ModalBody>
                <AvForm onValidSubmit={() => handleValidSubmit()}>

                    <AvGroup>
                        <Label for="password">New Password</Label>
                        <AvField
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter new password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Password must include a minimum of 4 characters' }
                            }}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="confirm-password">Confirm New Password</Label>
                        <AvField
                            type="password"
                            name="confirm-password"
                            id="confirm-password"
                            placeholder="Confirm new password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                            validate={{
                                minLength: { value: 4, errorMessage: 'Password must include a minimum of 4 characters' }
                            }}
                        />
                        {/* <AvFeedback>This field is invalid</AvFeedback> */}
                    </AvGroup>

                    <FormGroup>
                        <Button color="secondary">Reset Password</Button>
                    </FormGroup>
                </AvForm>
            </ModalBody>
        </Modal>
    )
}



const CreateFacilityUserModal = ({ isOpen = false, toggle = () => { }, getFacilityUsers, facInfo }) => {

    const { facilities } = useContext(SelectedFacilitiesContext);
    const [validState, setValidState] = useState({
        facilityRole: true,
        facilities: true,
        displayName: true,
        email: true,
        phone: true,
        confirmPasswordRequired: true,
        confirmPasswordMatches: true,
        // Title: true
    })

    const { facilityUserRoles } = useContext(UserRolesContext)

    const [facilityUser, setFacilityUser] = useState({
        facilityRole: null,
        facilities: [],
        displayName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        Title: '',
    })

    const customValidateForm = () => {

        let newValidState = {
            ...validState
        }

        if (facilityUser.facilities.length < 1)
            newValidState.facilities = false

        if (!facilityUser.facilityRole)
            newValidState.facilityRole = false

        if (!facilityUser.confirmPassword)
            newValidState.confirmPasswordRequired = false

        if ((facilityUser.password && facilityUser.confirmPassword) && (facilityUser.password !== facilityUser.confirmPassword))
            newValidState.confirmPasswordMatches = false

        if (facilityUser.phone.replace(/\D/g, '').length > 0 && facilityUser.phone.replace(/\D/g, '').length < 10)
            newValidState.phone = false;

        let valid = true;

        for (let state in newValidState) {
            if (newValidState[state] === false)
                valid = false
        }

        setValidState(newValidState)

        return valid
    }

    const handleValidSubmit = () => {
        if (!customValidateForm()) return
        let payload = {
            ...facilityUser,
            faciID: facilityUser.facilities[0],
            UserRole: facilityUser.facilityRole,
            phone: facilityUser.phone.replace(/\D/g, ''),
            UserType: 'FacilityUser',
            Title: facilityUser.Title
        }

        delete payload.confirmPassword

        delete payload.facilities

        delete payload.FacilityRole
        

        let addingFacilityUserToast = toast.loading('Adding folder...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/createuser', payload)
            .then(res => {

                toast.update(addingFacilityUserToast,
                    {
                        render: 'Facility user successfully created',
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

                getFacilityUsers()
                toggle()
            })
            .catch(err => {
                toast.update(addingFacilityUserToast,
                    {
                        render: err.response.data.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

            })
    }

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: "flex" }}>
            <div style={{
                color: disabled ? '#C0C4C8' : 'inherit'
            }}>{label}</div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {customAbbreviation}
      </div> */}
        </div>
    );


    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader
                className="modal-colored-header bg-primary"
                toggle={toggle}
            >
                Create Facility User
            </ModalHeader>
            <AvForm
                beforeSubmitValidation={customValidateForm}
                onValidSubmit={handleValidSubmit}>
                <ModalBody>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label style={{ color: `${!validState.facilities ? '#fa5c7c' : ''}` }}>Facilities *</Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    className={`${!validState.facilities ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    // name="facilities"
                                    loadingMessage={() => 'Loading Facilities'}
                                    isLoading={!facilities}
                                    placeholder="Select Facilities..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            facilities: true
                                        })
                                        setFacilityUser({
                                            ...facilityUser,
                                            facilities: [selection.value]
                                        })
                                    }
                                    }
                                    // value={facilities?.find(f => f.facID === facilityUser.facilities[0])}
                                    isSearchable={true}
                                    options={facilities.map(faci => ({ label: faci.facName, value: faci.facID }))}
                                >
                                </Select>
                                {/* </div> */}
                                {(!validState.facilities) && <div className="invalid-text">Please select a facility</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="facility-role" style={{ color: `${!validState.facilityRole ? '#fa5c7c' : ''}` }}>Facility Role *</Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    className={`${!validState.facilityRole ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    name="facility-role"
                                    isLoading={!facilityUserRoles}
                                    placeholder="Select Role..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            facilityRole: true
                                        })
                                        setFacilityUser({
                                            ...facilityUser,
                                            facilityRole: selection.value
                                        })
                                    }}
                                    formatOptionLabel={formatOptionLabel}
                                    isOptionDisabled={(option) => option.disabled}
                                    value={facilityUserRoles?.find(role => role.value === facilityUser.facilityRole) || ''}
                                    isSearchable={false}
                                    options={facilityUserRoles}
                                >
                                </Select>
                                {/* </div> */}
                                {(!validState.facilityRole) && <div className="invalid-text">Please select a facility role</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={6}>
                            <AvField
                                name="displayName"
                                label="Display Name *"
                                placeholder="Display name..."
                                value={facilityUser.displayName}
                                onChange={(e) => setFacilityUser({
                                    ...facilityUser,
                                    displayName: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter a display name' }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <AvField
                                type="email"
                                name="email"
                                label="Email *"
                                placeholder="Email..."
                                value={facilityUser.email}
                                onChange={(e) => setFacilityUser({
                                    ...facilityUser,
                                    email: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter an email address' },
                                    email: { value: true, errorMessage: 'Invalid email' }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="phone" style={{ color: `${!validState.phone ? '#fa5c7c' : ''}` }}>Phone</Label>
                                {/* <div style={{ width: '200px' }}> */}
                                <MaskedInput
                                    name="phone"
                                    value={facilityUser.phone}
                                    onChange={(e) => {
                                        if (e.target.value.replace(/\D/g, '').length === 10) {
                                            setValidState({
                                                ...validState,
                                                phone: true
                                            })
                                        }

                                        setFacilityUser({
                                            ...facilityUser,
                                            phone: e.target.value
                                        })
                                    }}
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    placeholder="(xxx) xxxx-xxxx"
                                    className={`form-control ${!validState.phone ? 'invalid' : ''}`}
                                />
                                {/* </div> */}
                                {!validState.phone ? <div className="invalid-text">Invalid phone</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={6}>
                            <AvField
                                type="password"
                                label="Password *"
                                name="password"
                                placeholder="Password..."
                                value={facilityUser.password}
                                onChange={(e) => setFacilityUser({
                                    ...facilityUser,
                                    password: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter a password' }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="confirm-password" style={{ color: `${!validState.confirmPasswordRequired || !validState.confirmPasswordMatches ? '#fa5c7c' : ''}` }}>Confirm Password *</Label>
                                <Input type="password" name="confirm-password" id="confirm-password"
                                    className={`${!validState.confirmPasswordRequired || !validState.confirmPasswordMatches ? 'invalid' : ''}`}
                                    autoComplete='off'
                                    placeholder='Re-enter Password...'
                                    value={facilityUser.confirmPassword}
                                    onChange={(e) => {
                                        let target = e.target;
                                        if (target.value === facilityUser.password) {
                                            setValidState(prev => ({
                                                ...prev,
                                                confirmPasswordMatches: true
                                            }))
                                        }

                                        setValidState(prev => ({
                                            ...prev,
                                            confirmPasswordRequired: target.value ? true : false
                                        }))

                                        setFacilityUser({
                                            ...facilityUser,
                                            confirmPassword: target.value
                                        })
                                    }}
                                />
                                {(!validState.confirmPasswordRequired) ? <div className="invalid-text">Please re-enter password</div> : ''}
                                {(validState.confirmPasswordRequired && !validState.confirmPasswordMatches) ? <div className="invalid-text">Passwords do not match</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <AvField
                                name="Title"
                                label="Title *"
                                placeholder="Title..."
                                value={facilityUser.Title}
                                onChange={(e) => setFacilityUser({
                                    ...facilityUser,
                                    Title: e.target.value
                                })}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter a title' }
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button color="primary">
                        Save
                    </Button>{' '}
                </ModalFooter>
//         </AvForm>
//     </Modal>
    )
}

export default Users;