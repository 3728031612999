import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAtom, atom } from 'jotai';
import {
    highCostAtom,
    highCostAtomShownAlerts,
    highCostAlertsAtom,
    highCostAlertsAtomShownAlerts,
    clarificationsAtom,
    clarificationsAtomShownAlerts,
    priorAuthAtom,
    priorAuthAtomShownAlerts,
    nonCoveredAtom,
    nonCoveredAtomShownAlerts,
    therapeuticIntAtom,
    therapeuticIntAtomShownAlerts,
    admissionMedsAtom,
    admissionMedsAtomShownAlerts,
    patMissingAtom,
    patMissingAtomShownAlerts,
} from '../jotai-atoms/AlertAtoms';
import {
    archivedHighCostAtom,
    archivedHighCostAlertsAtom,
    archivedClarificationsAtom,
    archivedPriorAuthAtom,
    archivedNonCoveredAtom,
    archivedTherapeuticIntAtom,
    archivedAdmissionMedsAtom,
    archivedPatMissingAtom,
} from '../jotai-atoms/ArchivedAlertAtoms';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify';
import { ActiveAlertContext } from '../contexts/ActiveAlertContext';

const useUpdateAlert = () => {
    const [highCostShownAlerts, setHighCostShownAlerts] = useAtom(highCostAtomShownAlerts);
    const [highCostAlertsShownAlerts, setHighCostAlertsShownAlerts] = useAtom(highCostAlertsAtomShownAlerts);
    const [clarificationsShownAlerts, setClarificationsShownAlerts] = useAtom(clarificationsAtomShownAlerts);
    const [priorAuthShownAlerts, setPriorAuthShownAlerts] = useAtom(priorAuthAtomShownAlerts);
    const [nonCoveredShownAlerts, setNonCoveredShownAlerts] = useAtom(nonCoveredAtomShownAlerts);
    const [therapeuticIntShownAlerts, setTherapeuticIntShownAlerts] = useAtom(therapeuticIntAtomShownAlerts);
    const [admissionMedsShownAlerts, setAdmissionMedsShownAlerts] = useAtom(admissionMedsAtomShownAlerts);
    const [patMissingShownAlerts, setPatMissingShownAlerts] = useAtom(patMissingAtomShownAlerts);

    const [archivedHighCostAlerts, setArchivedHighCostAlerts] = useAtom(archivedHighCostAtom);
    const [archivedHighCostAlertsAlerts, setArchivedHighCostAlertsAlerts] = useAtom(archivedHighCostAlertsAtom);
    const [archivedNonCoveredAlerts, setArchivedNonCoveredAlerts] = useAtom(archivedNonCoveredAtom);
    const [archivedClarificationsAlerts, setArchivedClarificationsAlerts] = useAtom(archivedClarificationsAtom);
    const [archivedPriorAuthAlerts, setArchivedPriorAuthAlerts] = useAtom(archivedPriorAuthAtom);
    const [archivedTherapeuticIntAlerts, setArchivedTherapeuticIntAlerts] = useAtom(archivedTherapeuticIntAtom);
    const [archivedAdmissionMedsAlerts, setArchivedAdmissionMedsAlerts] = useAtom(archivedAdmissionMedsAtom);
    const [archivedPatMissingAlerts, setArchivedPatMissingAlerts] = useAtom(archivedPatMissingAtom);

    const { faciToken, setFaciToken } = useContext(UserContext);

    const { activeAlert, setActiveAlert } = useContext(ActiveAlertContext);

    function updateReadStatus(readStatus, alert, category) {
        console.log({ readStatus, alert, category });

        if (category === 'highCost') {
            setHighCostShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);

                return tempAlerts;
            });
        } else if (category === 'highcostalerts') {
            setHighCostAlertsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter(
                    (exisitingAlert) => exisitingAlert._internalID !== alert._internalID);


                alert.readByFaci = readStatus;

                tempAlerts.push(alert);

                return tempAlerts;
            });
        } else if (category === 'clarifications') {
            setClarificationsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        } else if (category === 'priorAuth') {
            setPriorAuthShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        } else if (category === 'nonCovered') {
            setNonCoveredShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        } else if (category === 'therapeuticInt') {
            setTherapeuticIntShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        } else if (category === 'admissionMeds') {
            setAdmissionMedsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        } else if (category === 'patMissing') {
            setPatMissingShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return tempAlerts;
            });
        }
    };

    function updateArchivedReadStatus(readStatus, alert, category) {
        console.log({ readStatus, alert, category });

        if (category === 'highCost') {
            setArchivedHighCostAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);

                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'highcostalerts') {
            setArchivedHighCostAlertsAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter(
                    (exisitingAlert) => exisitingAlert.highCostAlertsArchiveId !== alert.highCostAlertsArchiveId
                );

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);

                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'clarifications') {
            setArchivedClarificationsAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'priorAuth') {
            setArchivedPriorAuthAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'nonCovered') {
            setArchivedNonCoveredAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'therapeuticInt') {
            setArchivedTherapeuticIntAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'admissionMeds') {
            setArchivedAdmissionMedsAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        } else if (category === 'patMissing') {
            setArchivedPatMissingAlerts((prevState) => {
                let tempAlerts = [...prevState.alertData];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.readByFaci = readStatus;

                tempAlerts.push(alert);
                return {
                    ...prevState,
                    alertData: tempAlerts,
                };
            });
        }
    }

    function updateMessageReadStatus(readStatus, action, alert, category) {
        console.log({ readStatus, action, alert, category });

        if (category === 'highCost') {
            setHighCostShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'highcostalerts') {
            setHighCostAlertsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'admissionMeds') {
            setAdmissionMedsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'clarifications') {
            setClarificationsShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'priorAuth') {
            setPriorAuthShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'nonCovered') {
            setNonCoveredShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'therapeuticInt') {
            setTherapeuticIntShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        } else if (category === 'patMissing') {
            setPatMissingShownAlerts((prevState) => {
                let tempAlerts = [...prevState];

                tempAlerts = tempAlerts.filter((exisitingAlert) => exisitingAlert._internalID !== alert._internalID);

                alert.requestedActions.find((x) => x.requestActionID === action.requestActionID).readByFaci =
                    readStatus;

                tempAlerts.push(alert);
                console.log('updated ' + action.requestActionID);
                return tempAlerts;
            });
        }
    }
    return {
        updateReadStatus,
        updateArchivedReadStatus,
        updateMessageReadStatus,
    };
};

export default useUpdateAlert;
