import React from 'react';

const Documents = () => {
    return(
        <>
        <iframe src='https://srxdocs.evelthost.com/files/' className="w-100 docIframe shadow-lg">

        </iframe>
        </>
    )
}

export default Documents;