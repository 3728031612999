import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown,
    Badge,
} from 'reactstrap';
import { UserContext } from '../contexts/UserContext';
import { Icon } from '@iconify/react';
import bellCogOutline from '@iconify/icons-mdi/bell-cog-outline';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import plusIcon from '@iconify/icons-mdi/plus';
import accountEye from '@iconify/icons-mdi/account-eye';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import accountIcon from '@iconify/icons-mdi/account';
import Select from 'react-select';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { SrxApi } from '../api/SrxApi';
import deleteIcon from '@iconify/icons-mdi/delete';
import { UserRolesContext } from '../contexts/UserRolesContext';
import MaskedInput from 'react-text-mask';
import { confirm } from '../utility-components/ConfirmModal';
import { Link } from 'react-router-dom';
import FacilitySettings from '../custom-components/FacilitySettings';

const Facilities = () => {
    const { faciToken, setFaciToken } = useContext(UserContext);

    const [userFacilities, setUserFacilities] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const { pharmacyUserRoles, facilityUserRoles } = useContext(UserRolesContext);

    let history = useHistory();
    useEffect(() => {
        SrxApi.get('/facilities')
            .then((res) => {
                setUserFacilities(res.data.facilities);
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    localStorage.removeItem('FACI_TOKEN');
                    setFaciToken(null);
                    history.replace('/account/login');
                }
            });
    }, []);

    function searchFacilities(array) {
        array.sort((a, b) => (a.name < b.name ? -1 : 1));
        if (searchTerm) {
            let results = array.filter((faci) =>
                faci.facName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            );
            return results;
        } else {
            return array;
        }
    }

    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

    const [isViewUsersModalOpen, setIsViewUsersModalOpen] = useState(false);

    const [isFacilitySettingsPageOpen, setIsFacilitySettingsPageOpen] = useState(false);

    const [userModalFacInfo, setUserModalFacInfo] = useState('');

    function toggleAddUserModal(info) {
        if (!isAddUserModalOpen) setUserModalFacInfo(info);
        else setUserModalFacInfo('');
        setIsAddUserModalOpen(!isAddUserModalOpen);
    }

    function toggleViewUsersModal(info) {
        if (!isViewUsersModalOpen) setUserModalFacInfo(info);
        else setUserModalFacInfo('');
        setIsViewUsersModalOpen(!isViewUsersModalOpen);
    }

    function toggleViewFacilitySettingsPage(facility) {
        console.log(facility);
        history.push('/facility/facilitySettings/' + facility.facID);

        setIsFacilitySettingsPageOpen(!isFacilitySettingsPageOpen);
    }

    // const [facilitySettingsPage, setFacilitySettingsPage] = useState({
    //     isOpen: false,
    //     data: {
    //         user: null
    //     }
    // });

    // function closeFacilitySettingsPage() {
    //     setFacilitySettingsPage({
    //         isOpen: false,
    //         data: {
    //             user: null
    //         }
    //     });
    // }

    const [sortBy, setSortBy] = useState({
        sortField: 'facName',
        sortDirection: 'asc',
    });

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'dispenseDt':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => (new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1));
                else return array.sort((a, b) => (new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1));
            case 'patName':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.patName < b.patName ? -1 : 1));
                else return array.sort((a, b) => (a.patName > b.patName ? -1 : 1));
            case 'facName':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facName < b.facName ? -1 : 1));
                else return array.sort((a, b) => (a.facName > b.facName ? -1 : 1));
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.ttlPrice < b.ttlPrice ? -1 : 1));
                else return array.sort((a, b) => (a.ttlPrice > b.ttlPrice ? -1 : 1));
            // case 'facName':
            //     if(sortBy.sortDirection === 'asc')
            //         return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            //     else
            //         return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
            case 'facID':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facID < b.facID ? -1 : 1));
                else return array.sort((a, b) => (a.facID > b.facID ? -1 : 1));
            case 'pharmID':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.pharmID < b.pharmID ? -1 : 1));
                else return array.sort((a, b) => (a.pharmID > b.pharmID ? -1 : 1));
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc',
            });
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? (
                <Icon icon={arrowUp} color="black" />
            ) : (
                <Icon icon={arrowDown} color="black" />
            )}
        </>
    );

    const disableAddUserButton = (facility) => {
        if (facility.userType.toLowerCase() === 'pharmacyuser') return false;
        else if (facility.userType.toLowerCase() === 'superadmin') return false;
        else if (facility.userType.toLowerCase() === 'facilityuser') {
            if (facility.userRole.toLowerCase() === 'facilityadmin') return false;
            else return true;
        }
    };

    const canSetSettings = (facility) => {
        const foundFacility = userFacilities.find((f) => f.facID === facility.facID);
        if (foundFacility.permissions.allowToSetSettingsForFacility) {
            return true;
        } else {
            return false;
        }
    };

    if (userFacilities.length)
        return (
            <>
                <div className="mt-3 mx-2">
                    <h2>Facilities</h2>
                    <Card>
                        <CardBody>
                            {/* <h4 className="header-title">Product List</h4>
                <p className="text-muted font-14 mb-4">A list of all existing Greenbowl Products</p> */}
                            <Row className="mb-2 d-flex justify-content-end">
                                <Col className="text-right" md={5}>
                                    <FormGroup
                                        className="d-flex mb-0 align-items-center"
                                        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <Label className="mr-2 mb-0">
                                            <b>Search by facility name:</b>
                                        </Label>
                                        <Input
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table className="mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th onClick={() => onClickSort('facID')} className="th-hover">
                                            ID{' '}
                                            {sortBy.sortField === 'facID' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th onClick={() => onClickSort('facName')} className="th-hover">
                                            Name{' '}
                                            {sortBy.sortField === 'facName' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th onClick={() => onClickSort('pharmID')} className="th-hover">
                                            Pharmacy ID{' '}
                                            {sortBy.sortField === 'pharmID' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortByField(searchFacilities(userFacilities)).map((facility, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="row" style={{ verticalAlign: 'middle' }}>
                                                    {facility.facID}
                                                </th>
                                                <td style={{ verticalAlign: 'middle' }}>{facility.facName}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{facility.pharmID}</td>
                                                <td style={{ verticalAlign: 'middle' }} className="position-relative">
                                                    {facility.userRole}
                                                    <UncontrolledButtonDropdown
                                                        className="position-absolute"
                                                        style={{ right: '20px', top: '5px' }}>
                                                        <DropdownToggle
                                                            tag="button"
                                                            className="btn btn-lg btn-link no-arrow card-drop p-0">
                                                            <Icon icon={dotsHorizontal} width="23" height="23" />
                                                        </DropdownToggle>
                                                        <DropdownMenu right style={{ minWidth: 'max-content' }}>
                                                            <DropdownItem
                                                                className="px-3 py-1"
                                                                disabled={disableAddUserButton(facility)}
                                                                onClick={() => toggleAddUserModal(facility)}>
                                                                <Icon
                                                                    icon={plusIcon}
                                                                    width="20"
                                                                    height="20"
                                                                    className="mr-1"
                                                                />{' '}
                                                                Add User
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                className="px-3 py-1"
                                                                onClick={() => toggleViewUsersModal(facility)}>
                                                                <Icon
                                                                    icon={accountEye}
                                                                    width="20"
                                                                    height="20"
                                                                    className="mr-1"
                                                                />{' '}
                                                                View Users
                                                            </DropdownItem>
                                                            {canSetSettings(facility) && (
                                                                <DropdownItem
                                                                    className="px-3 py-1"
                                                                    onClick={() =>
                                                                        toggleViewFacilitySettingsPage(facility)
                                                                    }>
                                                                    <Icon
                                                                        icon={bellCogOutline}
                                                                        width="20"
                                                                        height="20"
                                                                        className="mr-1"
                                                                    />{' '}
                                                                    Facility Settings
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <AddUserModal
                                toggle={toggleAddUserModal}
                                isOpen={isAddUserModalOpen}
                                facInfo={userModalFacInfo}
                            />
                            <ViewUsersModal
                                toggle={toggleViewUsersModal}
                                isOpen={isViewUsersModalOpen}
                                facInfo={userModalFacInfo}
                            />
                        </CardBody>
                    </Card>
                </div>
            </>
        );

    return (
        <div className="page-loading-loader-div w-100 d-flex justify-content-center align-items-center">
            <div>
                <h4>Loading Facilities...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

const AddUserModal = ({ toggle, isOpen, facInfo }) => {
    const { faciToken, setFaciToken } = useContext(UserContext);

    const [confirmPassword, setConfirmPassword] = useState('');

    const { facilityUserRoles } = useContext(UserRolesContext);

    const [newUser, setNewUser] = useState({
        email: '',
        password: '',
        displayName: '',
        phone: '',
        userRole: null,
    });

    const [invalidState, setInvalidState] = useState({
        email: false,
        password: false,
        confirmPassword: false,
        displayName: false,
        userRole: false,
        phone: false,
    });

    function preToggle() {
        setNewUser({
            email: '',
            password: '',
            displayName: '',
            phone: '',
            userRole: null,
        });
        setInvalidState({
            email: false,
            password: false,
            confirmPassword: false,
            displayName: false,
            phone: false,
        });
        setConfirmPassword('');
        toggle();
    }

    console.log({ newUser });

    function saveUser() {
        let isPhoneInvalid =
            newUser.phone.replace(/\D/g, '').length > 0 && newUser.phone.replace(/\D/g, '').length < 10;

        if (
            !newUser.email ||
            !newUser.password ||
            !newUser.displayName ||
            !confirmPassword ||
            !newUser.userRole ||
            isPhoneInvalid
        ) {
            return setInvalidState({
                email: !newUser.email,
                password: !newUser.password,
                displayName: !newUser.displayName,
                confirmPassword: !confirmPassword,
                userRole: !newUser.userRole,
                phone: isPhoneInvalid,
            });
        }

        if (newUser.password.length < 4) return;

        if (confirmPassword !== newUser.password) return;

        let addingUserToast = toast.loading('Adding user to facility...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/createuser', {
            ...newUser,
            phone: newUser.phone.replace(/\D/g, ''),
            faciID: facInfo.facID,
            userType: 'FacilityUser',
        })
            .then((res) => {
                toast.update(addingUserToast, {
                    render: `${newUser.userRole} added to ${facInfo.facName}`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
                preToggle();
            })
            .catch((err) => {
                console.log(err.response);
                toast.update(addingUserToast, {
                    render: err.response.data.message + ': ' + err.response.statusText,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            });
    }

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    color: disabled ? '#C0C4C8' : 'inherit',
                }}>
                {label}
            </div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
        </div> */}
        </div>
    );

    return (
        <Modal isOpen={isOpen} toggle={preToggle}>
            <ModalHeader toggle={preToggle} className="modal-colored-header bg-primary">
                Add Facility User to{' '}
                <Badge style={{ fontSize: '13px' }} className="badge badge-secondary py-1">
                    {facInfo.facName}
                </Badge>
            </ModalHeader>
            <ModalBody>
                <form autoComplete="new-password">
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label style={{ color: `${invalidState.userRole ? '#fa5c7c' : ''}` }}>
                                    Facility User Role *
                                </Label>
                                <Select
                                    className={`${invalidState.userRole ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    onChange={(e) => {
                                        setInvalidState({
                                            ...invalidState,
                                            userRole: false,
                                        });

                                        setNewUser({
                                            ...newUser,
                                            userRole: e.value,
                                        });
                                    }}
                                    value={facilityUserRoles?.find((role) => role.value === newUser.userRole) || ''}
                                    loading={!facilityUserRoles}
                                    options={facilityUserRoles}
                                    isOptionDisabled={(option) => option.disabled}
                                    formatOptionLabel={formatOptionLabel}></Select>
                                {invalidState.userRole && (
                                    <div className="invalid-text">Please select a facility user role</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="email">
                                    Email <small>*required</small>
                                </Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className={`${invalidState.email ? 'invalid' : ''}`}
                                    autoComplete="none"
                                    placeholder="Email"
                                    value={newUser.email}
                                    onChange={(e) => {
                                        if (e.target.value)
                                            setInvalidState({
                                                ...invalidState,
                                                email: false,
                                            });

                                        setNewUser({
                                            ...newUser,
                                            email: e.target.value,
                                        });
                                    }}
                                />
                                {invalidState.email ? <div className="invalid-text">Email is required</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="password">
                                    Password <small>*required</small>
                                </Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className={`${(newUser.password && newUser.password.length < 4) || invalidState.password
                                        ? 'invalid'
                                        : ''
                                        }`}
                                    autoComplete="off"
                                    placeholder="Password"
                                    value={newUser.password}
                                    onChange={(e) => {
                                        if (e.target.value)
                                            setInvalidState({
                                                ...invalidState,
                                                password: false,
                                            });
                                        setNewUser({
                                            ...newUser,
                                            password: e.target.value,
                                        });
                                    }}
                                />
                                {invalidState.password ? <div className="invalid-text">Password is required</div> : ''}
                                {newUser.password && newUser.password.length < 4 ? (
                                    <div className="invalid-text">Password must be at least 4 characters long</div>
                                ) : (
                                    ''
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="confirm-password">
                                    Confirm password <small>*required</small>
                                </Label>
                                <Input
                                    type="password"
                                    name="confirm-password"
                                    id="confirm-password"
                                    className={`${(newUser.password && confirmPassword && newUser.password !== confirmPassword) ||
                                        invalidState.confirmPassword
                                        ? 'invalid'
                                        : ''
                                        }`}
                                    autoComplete="off"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        if (e.target.value)
                                            setInvalidState({
                                                ...invalidState,
                                                confirmPassword: false,
                                            });
                                        setConfirmPassword(e.target.value);
                                    }}
                                />
                                {invalidState.confirmPassword ? (
                                    <div className="invalid-text">Confirm password is required</div>
                                ) : (
                                    ''
                                )}
                                {newUser.password && confirmPassword && newUser.password !== confirmPassword ? (
                                    <div className="invalid-text">Confirm password does not match password</div>
                                ) : (
                                    ''
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">
                                    Display name <small>*required</small>
                                </Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={`${invalidState.displayName ? 'invalid' : ''}`}
                                    placeholder="Display name"
                                    autoComplete="off"
                                    value={newUser.displayName}
                                    onChange={(e) => {
                                        if (e.target.value)
                                            setInvalidState({
                                                ...invalidState,
                                                displayName: false,
                                            });

                                        setNewUser({
                                            ...newUser,
                                            displayName: e.target.value,
                                        });
                                    }}
                                />
                                {invalidState.displayName ? (
                                    <div className="invalid-text">Display name is required</div>
                                ) : (
                                    ''
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="phone" style={{ color: `${invalidState.phone ? '#fa5c7c' : ''}` }}>
                                    Phone
                                </Label>
                                {/* <div style={{ width: '200px' }}> */}
                                <MaskedInput
                                    name="phone"
                                    value={newUser.phone}
                                    onChange={(e) => {
                                        setInvalidState({
                                            ...invalidState,
                                            phone: false,
                                        });

                                        setNewUser({
                                            ...newUser,
                                            phone: e.target.value,
                                        });
                                    }}
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    placeholder="(xxx) xxxx-xxxx"
                                    className={`form-control ${invalidState.phone ? 'invalid' : ''}`}
                                />
                                {/* </div> */}
                                {invalidState.phone ? <div className="invalid-text">Invalid phone</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={saveUser}>
                    Add User
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

const ViewUsersModal = ({ toggle, isOpen, facInfo }) => {
    const { faciToken, user: contextUser } = useContext(UserContext);

    const [facilityUsers, setFacilityUsers] = useState([]);

    const [userRoleLoading, setUserRoleLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const { facilityUserRoles, pharmacyUserRoles } = useContext(UserRolesContext);

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            SrxApi.get('/facilityusers', {
                params: {
                    faciId: facInfo.facID,
                },
            })
                .then((res) => {
                    setFacilityUsers(res.data.userInfos);
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    }, [isOpen]);

    const formatOptionLabel = ({ value, label, disabled }) => (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    color: disabled ? '#C0C4C8' : 'inherit',
                }}>
                {label}
            </div>
            {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
          </div> */}
        </div>
    );

    function onFaciRoleChange(role, user) {
        let payload = {
            entityID: facInfo.facID,
            email: user.email,
            userRole: role,
        };

        SrxApi.post('/edituser', payload)
            .then((res) => {
                let copyOfFaciUsers = [...facilityUsers];

                copyOfFaciUsers.find((faciUser) => faciUser.email === user.email).userRole = role;

                setFacilityUsers(copyOfFaciUsers);

                toast.success('User role successfully changed');
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
            });
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    async function removeUserFromSingleFacility(userInfo) {
        if (
            !(await confirm(
                `Remove <i>${userInfo.displayName}</i> from <i>${facInfo.facName}</i>?`,
                `Removing user from facility`,
                'Confirm',
                'Cancel',
                'primary',
                'primary'
            ))
        )
            return;

        let payload = {
            email: userInfo.email,
            facID: facInfo.facID,
        };

        SrxApi.post('/removefacility', payload)
            .then((res) => {
                setFacilityUsers(facilityUsers.filter(({ email }) => email !== userInfo.email));

                toast.success((userInfo.displayName || userInfo.email) + ' removed from ' + facInfo.facName);
            })
            .catch((err) => toast.error('Error: ' + err.message));
    }

    const checkIfUserIsAdmin = (facility) => {
        if (!isOpen) return true;
        if (facility.userType.toLowerCase() === 'pharmacyuser') return false;
        else if (facility.userType.toLowerCase() === 'superadmin') return false;
        else if (facility.userType.toLowerCase() === 'facilityuser') {
            if (facility.userRole.toLowerCase() === 'facilityadmin') return false;
            else return true;
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                User list for{' '}
                <Badge style={{ fontSize: '13px' }} className="badge badge-secondary py-1">
                    {facInfo.facName}
                </Badge>
            </ModalHeader>
            {isLoading ? (
                <ModalBody className="d-flex flex-column justify-content-center align-items-center">
                    <h5>Loading facility users...</h5>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </ModalBody>
            ) : (
                <ModalBody>
                    {facilityUsers.length ? (
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                {facilityUsers
                                    .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                                    .map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {user.displayName}{' '}
                                                    {user.email === contextUser.email && (
                                                        <Badge
                                                            color="dark"
                                                            className="ml-1"
                                                            style={{ fontSize: '12px' }}>
                                                            Self
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td>{user.email}</td>
                                                <td>{formatPhoneNumber(user.phone)}</td>
                                                {/* <td>{user.isAdmin ?
                                    <><Icon icon={shieldAccount} width="24" height="24"/> Admin</> : 
                                <><Icon icon={accountIcon} width="24" height="24"/> User</>}</td> */}
                                                <td>
                                                    {user.userType.toLowerCase() === 'facilityuser' ? (
                                                        <div style={{ width: '140px' }}>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable={false}
                                                                isDisabled={
                                                                    userRoleLoading ||
                                                                    checkIfUserIsAdmin(facInfo) ||
                                                                    user.email === contextUser.email
                                                                }
                                                                hideSelectedOptions
                                                                onChange={(e) => onFaciRoleChange(e.value, user)}
                                                                value={
                                                                    user.userType === 'FacilityUser'
                                                                        ? facilityUserRoles?.find(
                                                                            (role) => role.value === user.userRole
                                                                        )
                                                                        : pharmacyUserRoles?.find(
                                                                            (role) => role.value === user.userRole
                                                                        )
                                                                }
                                                                formatOptionLabel={formatOptionLabel}
                                                                options={
                                                                    user.userType === 'FacilityUser'
                                                                        ? facilityUserRoles
                                                                        : pharmacyUserRoles
                                                                }
                                                                isOptionDisabled={(option) => option.disabled}></Select>
                                                        </div>
                                                    ) : (
                                                        <b>
                                                            <i>{user.userRole}</i>
                                                        </b>
                                                    )}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <Button
                                                        className="card-drop p-0"
                                                        disabled={
                                                            checkIfUserIsAdmin(facInfo) ||
                                                            user.email === contextUser.email
                                                        }
                                                        onClick={() => removeUserFromSingleFacility(user)}>
                                                        <Icon icon={deleteIcon} height="24" width="24" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    ) : (
                        <div
                            style={{ width: '100%', height: '60px', border: '1px dashed #6c757d', borderRadius: '5px' }}
                            className="d-flex justify-content-center align-items-center">
                            <strong>No Users</strong>
                        </div>
                    )}
                </ModalBody>
            )}
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Facilities;
