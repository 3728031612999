import axios from 'axios';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';

export const ShowResolvedAlertsContext = createContext();

export const ShowResolvedAlertsProvider = (props) => {

    const [showResolved, setShowResolved] = useState(false);
    
    return (
        <ShowResolvedAlertsContext.Provider value={{ showResolved, setShowResolved }}>
            {props.children}
        </ShowResolvedAlertsContext.Provider>
    );
}