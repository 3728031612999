import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledPopover,
    PopoverLegacy,
    PopoverHeader,
    PopoverBody,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown,
    Badge,
    CardHeader,
    CardFooter
} from 'reactstrap';
import { UserContext } from '../contexts/UserContext';
import { Icon } from '@iconify/react';
import outlineBadge from '@iconify/icons-ic/outline-badge';
import buildingIcon from '@iconify/icons-uil/building';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import plusIcon from '@iconify/icons-mdi/plus';
import accountEye from '@iconify/icons-mdi/account-eye';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import accountIcon from '@iconify/icons-mdi/account';
import Select from 'react-select';
import accountMultiple from '@iconify/icons-mdi/account-multiple';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { SrxApi } from '../api/SrxApi';
import deleteIcon from '@iconify/icons-mdi/delete';
import SortCarets from '../utility-components/SortCarets';
import informationIcon from '@iconify/icons-mdi/information';
import fileDocument from '@iconify/icons-mdi/file-document';
import { SecureForms } from '../data/MediprocitySecureForms';
import fileDocumentEditOutline from '@iconify/icons-mdi/file-document-edit-outline';
import 'bootstrap/dist/css/bootstrap.min.css';
const Pharmacies = (props) => {
    const [userPharmacies, setUserPharmacies] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalPharmacy, setModalPharmacy] = useState(null)
    const [activePharm, setActivePharm] = useState(null);
    const [activeFaci, setActiveFaci] = useState(null)
    const [activePharmUsers, setActivePharmUsers] = useState(null)
    let history = useHistory();
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let pharmID = query.get('pharmID');
        let faciID = query.get('faciID');
        let getPharmacyUsers = query.get('users');
        if (pharmID && !getPharmacyUsers) {
            setActivePharm(pharmID)
        } else {
            setActivePharm(null)
        }
        if (pharmID && getPharmacyUsers) {
            setActivePharmUsers(pharmID)
        } else {
            setActivePharmUsers(null)
        }
        if (faciID) {
            setActiveFaci(faciID)
        } else {
            setActiveFaci(null)
        }
    }, [props.location.search])
    useEffect(() => {
        SrxApi.get('/pharmacies')
            .then(res => setUserPharmacies(res.data.pharmacies))
    }, [])
    function searchPharmacies(array) {
        array.sort((a, b) => a.pharmID < b.pharmID ? -1 : 1)
        if (searchTerm) {
            let results = array.filter(pharm => pharm.pharmID.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }
    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'dispenseDt':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1)
            case 'patName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.patName < b.patName ? -1 : 1)
                else
                    return array.sort((a, b) => a.patName > b.patName ? -1 : 1)
            case 'facName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
                else
                    return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.ttlPrice < b.ttlPrice ? -1 : 1)
                else
                    return array.sort((a, b) => a.ttlPrice > b.ttlPrice ? -1 : 1)
            // case 'facName': 
            //     if(sortBy.sortDirection === 'asc')
            //         return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            //     else
            //         return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
            case 'facID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facID < b.facID ? -1 : 1)
                else
                    return array.sort((a, b) => a.facID > b.facID ? -1 : 1)
            case 'pharmID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.pharmID < b.pharmID ? -1 : 1)
                else
                    return array.sort((a, b) => a.pharmID > b.pharmID ? -1 : 1)
        }
    }
    const [sortBy, setSortBy] = useState({
        sortField: 'pharmID',
        sortDirection: 'asc'
    })
    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }
    if (!userPharmacies)
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading Pharmacies...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
    return (
        <>
            <div className='mt-3 mx-2'>
                <h2>Pharmacies</h2>
                <Card>
                    <CardBody>
                        {/* <h4 className="header-title">Product List</h4>
                <p className="text-muted font-14 mb-4">A list of all existing Greenbowl Products</p> */}
                        <Row className="mb-2 d-flex justify-content-end">
                            <Col className="text-right" md={5}>
                                <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    <Label className='mr-2 mb-0'><b>Search by pharmacy:</b></Label>
                                    <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Table className="mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th onClick={() => onClickSort('pharmID')} className="th-hover">Pharmacy ID {sortBy.sortField === 'pharmID' ? <SortCarets sortDirection={sortBy.sortDirection} /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                    <th>Num of Facilities</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortByField(searchPharmacies(userPharmacies))
                                    .map((pharm, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="row" style={{ verticalAlign: 'middle' }}>{pharm.pharmID}</th>
                                                <td style={{ verticalAlign: 'middle' }}>{pharm.facilities.length}</td>
                                                <td style={{ verticalAlign: 'middle' }} className="d-flex justify-content-end align-items-center">
                                                    {/* <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '26px' }}
                                    ><Icon icon={fileDocument} /></Button> */}
                                                    <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '26px' }}
                                                        onClick={() => {
                                                            const location = {
                                                                pathname: `/pharmacies`,
                                                                search: 'pharmID=' + pharm.pharmID
                                                            }
                                                            history.push(location)
                                                        }}
                                                    ><Icon icon={buildingIcon} /></Button>
                                                    <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '26px' }}
                                                        onClick={() => {
                                                            const location = {
                                                                pathname: `/pharmacies`,
                                                                search: `pharmID=${pharm.pharmID}&users=true`
                                                            }
                                                            history.push(location)
                                                        }}
                                                    ><Icon icon={accountMultiple} /> </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <PharmacyDetailModal
                    pharmacy={activePharm ? userPharmacies.find(pharm => pharm.pharmID.toLocaleLowerCase() === activePharm.toLocaleLowerCase()) : null}
                    toggle={() => history.push('/pharmacies')}
                />
                <PharmacyUsersModal
                    pharmacy={activePharmUsers}
                    toggle={() => history.push('/pharmacies')}
                />
                <FormDetailModal
                    pharmacy={activePharm ? userPharmacies.find(pharm => pharm.pharmID.toLocaleLowerCase() === activePharm.toLocaleLowerCase()) : null}
                    facility={activeFaci ? userPharmacies.find(pharm => pharm.pharmID.toLocaleLowerCase() === activePharm.toLocaleLowerCase())?.facilities
                        .find(faci => faci.facID.toLocaleLowerCase() === activeFaci.toLocaleLowerCase()) : null}
                    toggle={() => {
                        const query = new URLSearchParams(props.location.search);
                        let pharmID = query.get('pharmID')
                        const newLocation = {
                            pathname: props.location.pathname,
                            search: `pharmID=${pharmID}`
                        }
                        history.push(newLocation)
                    }}
                />
            </div>
        </>
    )
}
const PharmacyDetailModal = ({ pharmacy, toggle }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState({
        sortField: 'facName',
        sortDirection: 'asc'
    })
    const [activeFacility, setActiveFacility] = useState(null)
    let history = useHistory();
    let location = useLocation();
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let faciID = query.get('faciID');
        if (faciID) {
            setActiveFacility(faciID)
        } else {
            setActiveFacility(null)
        }
    }, [location.search])
    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'dispenseDt':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1)
            case 'patName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.patName < b.patName ? -1 : 1)
                else
                    return array.sort((a, b) => a.patName > b.patName ? -1 : 1)
            case 'facName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
                else
                    return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.ttlPrice < b.ttlPrice ? -1 : 1)
                else
                    return array.sort((a, b) => a.ttlPrice > b.ttlPrice ? -1 : 1)
            // case 'facName': 
            //     if(sortBy.sortDirection === 'asc')
            //         return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            //     else
            //         return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
            case 'facID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facID < b.facID ? -1 : 1)
                else
                    return array.sort((a, b) => a.facID > b.facID ? -1 : 1)
            case 'pharmID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.pharmID < b.pharmID ? -1 : 1)
                else
                    return array.sort((a, b) => a.pharmID > b.pharmID ? -1 : 1)
        }
    }
    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }
    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )
    function searchFacilities(array) {
        array.sort((a, b) => a.name < b.name ? -1 : 1)
        if (searchTerm) {
            let results = array.filter(faci => faci.facID.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || faci.facName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }
    return (
        <Modal
            size='lg'
            isOpen={pharmacy}
            toggle={toggle}
        >
            <ModalHeader
                className="gradient-modal"
                toggle={toggle}
                style={{
                    background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)",
                    color: 'white'
                }}
            >
                {pharmacy?.pharmID}
            </ModalHeader>
            <ModalBody>
                <>
                    {pharmacy ?
                        <>
                            <Row>
                                <Col>
                                    <p>Pharmacy ID: <b>{pharmacy?.pharmID}</b></p>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-2 d-flex justify-content-end">
                                <Col className="text-right" md={7}>
                                    <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <Label className='mr-2 mb-0'><b>Search facilities:</b></Label>
                                        <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table className="mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th onClick={() => onClickSort('facID')} className="th-hover">ID {sortBy.sortField === 'facID' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                        <th onClick={() => onClickSort('facName')} className="th-hover">Name {sortBy.sortField === 'facName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                        <th>Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortByField(searchFacilities(pharmacy.facilities))
                                        .map((facility, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row" style={{ verticalAlign: 'middle' }}>{facility.facID}</th>
                                                    <td style={{ verticalAlign: 'middle' }}>{facility.facName}</td>
                                                    <td style={{ verticalAlign: 'middle' }}><b><i>{facility.userRole}</i></b>
                                                    </td>
                                                    <td>
                                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '26px' }}
                                                            onClick={() => {
                                                                const query = new URLSearchParams(location.search);
                                                                let pharmID = query.get('pharmID')
                                                                const newLocation = {
                                                                    pathname: location.pathname,
                                                                    search: `pharmID=${pharmID}&faciID=${facility.facID}`
                                                                }
                                                                history.push(newLocation)
                                                            }}
                                                        ><Icon icon={fileDocument} /></Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </>
                        :
                        ''
                    }
                </>
            </ModalBody>
        </Modal>
    )
}
const PharmacyUsersModal = ({ pharmacy, toggle }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState(null);
    const [sortBy, setSortBy] = useState({
        sortField: 'facName',
        sortDirection: 'asc'
    })
    function getPharmacyUsers() {
        // set users to false in order to display a loading state
        setUsers(null)
        SrxApi.get('/getpharmacyusers')
            .then(res => {
                setUsers(res.data.users)
            })
            .catch(err => {
                setUsers([])
                toast.error('Error retrieving pharmacy users')
            })
    }
    useEffect(() => {
        getPharmacyUsers()
    }, [])
    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'dispenseDt':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1)
            case 'patName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.patName < b.patName ? -1 : 1)
                else
                    return array.sort((a, b) => a.patName > b.patName ? -1 : 1)
            case 'facName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
                else
                    return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.ttlPrice < b.ttlPrice ? -1 : 1)
                else
                    return array.sort((a, b) => a.ttlPrice > b.ttlPrice ? -1 : 1)
            // case 'facName': 
            //     if(sortBy.sortDirection === 'asc')
            //         return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            //     else
            //         return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
            case 'facID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facID < b.facID ? -1 : 1)
                else
                    return array.sort((a, b) => a.facID > b.facID ? -1 : 1)
            case 'pharmID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.pharmID < b.pharmID ? -1 : 1)
                else
                    return array.sort((a, b) => a.pharmID > b.pharmID ? -1 : 1)
        }
    }
    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }
    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )
    function searchFacilities(array) {
        array.sort((a, b) => a.name < b.name ? -1 : 1)
        if (searchTerm) {
            let results = array.filter(faci => faci.facID.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || faci.facName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }
    function currentPharmRole(user) {
        let currentPharm = user.pharmRole.find((i) => i.pharid === pharmacy)
        if (currentPharm) {
            return currentPharm
        }
    }


//     function roleColumn(i, index) {
//         return (
//             <div id={`comment-popover-${index}`} className="d-flex justify-content-center " style={{ width: 'max-content' }}>
//             <Icon icon={outlineBadge} height="25" width="25" className='comment-icon' />
//             <UncontrolledPopover placement="top" target={`comment-popover-${index}`} trigger="legacy" className='me-4 bg-white' style={{ maxHeight: '200px', maxWidth: 'max-content', overflow: 'auto',whiteSpace: 'nowrap' }} >

//                     <>
//                         <PopoverBody >{i.pharmRole.map((r,i) => {
//                            return <div key={i} style={{ maxWidth: 'max-content', whiteSpace: 'nowrap' }} className='w-100 d-flex justify-content-center'>
//                                 ID: <b className='px-2'>{r.pharid}</b>
//                                 Role: <b className='px-2'>{r.role}</b>
//                             </div>
//                             })}</PopoverBody>
//                             </>
    
//                     </UncontrolledPopover>
//                 </div>
//                   )
//                 }    
            
                return (
                    <>
                        <Modal
                            size='lg'
                            isOpen={pharmacy}
                            toggle={toggle}
                        >
                            <ModalHeader
                                className="gradient-modal"
                                toggle={toggle}
                                style={{
                                    background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)",
                                    color: 'white'
                                }}
                            >
                                {users?.pharmID}
                            </ModalHeader>
                            <ModalBody>
                                <>
                                    {users ?
                                        <>
                                            <Row>
                                                <Col>
                                                    <p>Pharmacy ID: <b>{pharmacy}</b></p>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-2 d-flex justify-content-end">
                                                {/* <Col className="text-right" md={7}>
                                                <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                    <Label className='mr-2 mb-0'><b>Search users:</b></Label>
                                                    <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                                                    </Input>
                                                </FormGroup>
                                            </Col> */}
                                            </Row>
                                            <Table className="mb-0" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th onClick={() => onClickSort('facID')} className="th-hover">Name {sortBy.sortField === 'facID' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                        <th onClick={() => onClickSort('facName')} className="th-hover">Email {sortBy.sortField === 'facName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                        <th>Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortByField(searchFacilities(users))
                                                        .filter((user) => user.pharmRole.find((r) => r.pharid === pharmacy))
                                                        .map((user, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td scope="row" style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        <b>{user.displayName}</b>
                                                                    </td>
                                                                    <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.email}</td>
                                                                    <td style={{ verticalAlign: 'middle', whiteSpace: 'normal', wordBreak: 'break-word' }}>{currentPharmRole(user).role}</td>
                                                                    {/* <td style={{ verticalAlign: 'middle' }}>{roleColumn(user, index)}</td> */}
                                                                 </tr>
                                                            );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </>
                            :
                            ''
                        }
                    </>
                </ModalBody>
            </Modal >
        </>
    )
}
const FormDetailModal = ({ pharmacy, facility, toggle }) => {
    const { user } = useContext(UserContext);
    let mediprocityFormsBaseUrl = 'https://secure-forms.mediprocity.com/forms/'
    function returnFormState(pharmID) {
        if (pharmID.toLowerCase() === 'fll')
            return 'FLL'
        if (pharmID.toLowerCase() === 'flw')
            return 'FLW'
        if (pharmID.toLowerCase() === 'ohio')
            return 'OH'
        if (pharmID.toLowerCase() === 'parx')
            return 'NY'
        if (pharmID.toLowerCase() === 'sprx')
            return 'NJ'
        if (pharmID.toLowerCase() === 'upny')
            return 'NY'
        if (pharmID.toLowerCase() === 'alrx')
            return 'AL'
        return 'NY'
    }
    const formLink = (form) => {
        if (form.label === 'out on pass medication') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility=${encodeURIComponent(facility.facName)}&nurse_name=${encodeURIComponent(user.displayName)}&today_date=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}&type_sign_1=${encodeURIComponent(user.displayName)}&date_1=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}`
        } else if (form.label === 'facility stock vaccine request') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility_name=${encodeURIComponent(facility.facName)}&date=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}&individual_ordering=${encodeURIComponent(user.displayName)}&date_1=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}`
        } else if (form.label === 'refill/reorder medication') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility=${encodeURIComponent(facility.facName)}&facility_rep=${encodeURIComponent(user.displayName)}&date=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}`
        } else if ((form.label === 'covid vaccine independent contractor')) {
            return (`${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&independent_contractor_agreement_date=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}&contractor=${encodeURIComponent(facility.facName)}` +
                `&state_name_2_checkbox=${encodeURIComponent(facility.facState.toLowerCase() === 'nj' ? 'Yes' : 'No')}` +
                `&state_name_2=${encodeURIComponent(facility.facState.toLowerCase() === 'nj' ? '' : facility.facState)}` +
                `&state_name_3_checkbox=${encodeURIComponent(facility.facState.toLowerCase() === 'nj' ? 'Yes' : 'No')}` +
                `&state_name_3=${encodeURIComponent(facility.facState.toLowerCase() === 'nj' ? '' : facility.facState)}` +
                `&facility_name=${encodeURIComponent(facility.facName)}` +
                `&type_sign_1=${encodeURIComponent(user.displayName)}` +
                `&name=${encodeURIComponent(user.displayName)}` +
                `&title=${encodeURIComponent(user.hasOwnProperty('title') ? user.title : '')}`
            )
        }
    }
    return (
        <Modal
            centered
            isOpen={facility}
            toggle={toggle}
        >
            {facility ?
                <>
                    <ModalHeader
                        className="gradient-modal"
                        toggle={toggle}
                        style={{
                            background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)",
                            color: 'white'
                        }}
                    >
                        {facility.facName}
                    </ModalHeader>
                    <ModalBody>
                        <>
                            <Row>
                                <Col>
                                    <p>Pharmacy ID: <b>{pharmacy.pharmID}</b></p>
                                    <p>Facility ID: <b>{facility.facID}</b></p>
                                    <p>Facility Name: <b>{facility.facName}</b></p>
                                </Col>
                            </Row>
                            <hr className='my-0' />
                            <Row>
                                <Col>
                                    <h3>Forms: </h3>
                                </Col>
                            </Row>
                            <Row>
                                {SecureForms[returnFormState(pharmacy.pharmID)]
                                    .map(form => (
                                        <Col md={6} style={{ minWidth: '200px' }}>
                                            <a href={formLink(form)} target="_blank" rel='noopener noreferrer'>
                                                <Card>
                                                    <CardBody className='d-flex justify-content-center align-items-center'>
                                                        <Icon icon={fileDocumentEditOutline} color="#D6E0EC" width="120" height="120" />
                                                    </CardBody>
                                                    <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                                                        <h4 className='text-center' style={{ textTransform: 'capitalize' }}>{form.label}</h4>
                                                    </CardFooter>
                                                </Card>
                                            </a>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </>
                    </ModalBody>
                </>
                :
                ''
            }
        </Modal>
    )
}
export default Pharmacies;
