import React, { useState, useRef, useEffect } from 'react';
import { Badge, Button } from 'reactstrap';
import useRefreshAlerts from '../custom-hooks/useRefreshAlerts';

function displayDifferenceInMinutes(now, rfrshTime){
    let differenceInMinutes = Math.floor((new Date(now).getTime() - new Date(rfrshTime).getTime()) / 60000);

    if(differenceInMinutes < 1)
        return {
            minutes: differenceInMinutes,
            view: <small>Less than <span style={{ fontSize: '16px' }} ><b>1</b></span> minute ago</small>}
    else if(differenceInMinutes === 1)
        return {minutes: differenceInMinutes,
            view: <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minute ago</small>}
    else if(differenceInMinutes >= 2 && differenceInMinutes <= 60)
        return {
            minutes: differenceInMinutes,
            view: <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minutes ago</small>}
    else
        return {
            minutes: differenceInMinutes,
            view: <small>More than <span style={{ fontSize: '16px' }} ><b>1</b></span> hour ago</small>}
} 

const LastUpdated = ({lastRefreshed, widgetPath}) => {

    const [currentTime, setCurrentTime] = useState(Date.now());

    const currentTimeTimeoutRef = useRef(null);

    useEffect(() => {
        currentTimeTimeoutRef.current =  setTimeout(() => {
            setCurrentTime(Date.now());
        }, 1000)

        return () => clearTimeout(currentTimeTimeoutRef.current)
    }, [currentTime])

    useEffect(() => {
        setTimeout(() => setCurrentTime(Date.now()), 1000)
    }, [currentTime])

    const { refreshWidget } = useRefreshAlerts();
    
    return(
        <>
        {lastRefreshed ?
        <div><b><span className='elementToFadeInAndOut'><small><b>Updated</b></small></span>: {displayDifferenceInMinutes(currentTime, lastRefreshed).view}</b>
        {displayDifferenceInMinutes.minutes >= 12 && 
            <Button color='warning'
                onClick={() => {
                    refreshWidget(widgetPath)
                }}
                className='py-0 px-1 ml-1 refresh-button'
            ><small><b>Refresh</b></small></Button>}
        </div>
        :
        <></>
        }
        </>
    )
}

export default LastUpdated;