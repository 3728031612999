import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { SrxApi } from '../api/SrxApi';
import { useAtom } from 'jotai';
import { SrxFsApi } from '../api/SrxFsApi';
import {
    highCostAtom, highCostAtomFilteredCount, highCostAtomFiltered, highCostAtomShownAlerts,
    highCostAlertsAtom, highCostAlertsAtomFilteredCount, highCostAlertsAtomFiltered, highCostAlertsAtomShownAlerts,
    clarificationsAtom, clarificationsAtomFilteredCount, clarificationsAtomFiltered, clarificationsAtomShownAlerts,
    priorAuthAtom, priorAuthAtomFilteredCount, priorAuthAtomFiltered, priorAuthAtomShownAlerts,
    nonCoveredAtom, nonCoveredAtomFilteredCount, nonCoveredAtomFiltered, nonCoveredAtomShownAlerts,
    therapeuticIntAtom, therapeuticIntAtomFilteredCount, therapeuticIntAtomFiltered, therapeuticIntAtomShownAlerts,
    admissionMedsAtom, admissionMedsAtomFilteredCount, admissionMedsAtomFiltered, admissionMedsAtomShownAlerts,
    patMissingAtom, patMissingAtomFilteredCount, patMissingAtomFiltered, patMissingAtomShownAlerts,
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
    patMissingAtomUserSettings
} from '../jotai-atoms/AlertAtoms';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export const UserContext = createContext();

// export default function useUser(){
//     return useContext(UserContext)
// }

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);
    const [faciToken, setFaciToken] = useState(null);

    // const [userUpdated, setUserUpdated] = useState(false);

    const [userLoading, setUserLoading] = useState(true);

    let history = useHistory();
    let location = useLocation();

    // useMemo(() => {
    //     let userToken = localStorage.getItem('FACI_TOKEN');
    // }, [])

    useEffect(() => {

        let localStorageFaciToken = localStorage.getItem('FACI_TOKEN');

        if (localStorageFaciToken !== undefined && localStorageFaciToken !== null) {
            axios.get(process.env.REACT_APP_API_BASE_URL + '/tokeninfo', {
                headers: {
                    'Authorization': 'bearer ' + localStorageFaciToken
                }
            })
                .then(res => {
                    SrxApi.defaults.headers.common['Authorization'] = 'bearer ' + localStorageFaciToken;
                    SrxFsApi.defaults.headers.common['Authorization'] = 'bearer ' + localStorageFaciToken;
                    setFaciToken(localStorageFaciToken);
                    setUser(res.data.userInfo)
                })
                .catch(err => {
                    SrxApi.defaults.headers.common['Authorization'] = undefined;
                    SrxFsApi.defaults.headers.common['Authorization'] = undefined;
                    setFaciToken(null);
                    localStorage.removeItem('FACI_TOKEN');
                    // history.push('/account/login');
                })
                .finally(() => setUserLoading(false))
        } else {
            setUserLoading(false)
            setFaciToken(null);
        }

    }, [])

    // THIS IS ADDED IN ORDER TO RESET ALL JOTAI ATOMS TO THEIR DEFAULT STATE ON LOGOUT AND NOT TO PRESERVE THE ALERTS

    const resetHighCostAtom = useResetAtom(highCostAtom);
    const resetHighCostAlertsAtom = useResetAtom(highCostAlertsAtom);
    const resetClarificationsAtom = useResetAtom(clarificationsAtom);
    const resetPriorAuthAtom = useResetAtom(priorAuthAtom);
    const resetNonCoveredAtom = useResetAtom(nonCoveredAtom);
    const resetTherapeuticIntAtom = useResetAtom(therapeuticIntAtom);
    const resetAdmissionMedsAtom = useResetAtom(admissionMedsAtom);
    const resetPatMissingAtom = useResetAtom(patMissingAtom);

    const resetHighCostAtomFilteredCount = useResetAtom(highCostAtomFilteredCount);
    const resetHighCostAlertsAtomFilteredCount = useResetAtom(highCostAlertsAtomFilteredCount);
    const resetClarificationsAtomFilteredCount = useResetAtom(clarificationsAtomFilteredCount);
    const resetPriorAuthAtomFilteredCount = useResetAtom(priorAuthAtomFilteredCount);
    const resetNonCoveredAtomFilteredCount = useResetAtom(nonCoveredAtomFilteredCount);
    const resetTherapeuticIntAtomFilteredCount = useResetAtom(therapeuticIntAtomFilteredCount);
    const resetAdmissionMedsAtomFilteredCount = useResetAtom(admissionMedsAtomFilteredCount);
    const resetPatMissingAtomFilteredCount = useResetAtom(patMissingAtomFilteredCount);

    const resetHighCostAtomFiltered = useResetAtom(highCostAtomFiltered);
    const resetHighCostAlertsAtomFiltered = useResetAtom(highCostAlertsAtomFiltered);
    const resetClarificationsAtomFiltered = useResetAtom(clarificationsAtomFiltered);
    const resetPriorAuthAtomFiltered = useResetAtom(priorAuthAtomFiltered);
    const resetNonCoveredAtomFiltered = useResetAtom(nonCoveredAtomFiltered);
    const resetTherapeuticIntAtomFiltered = useResetAtom(therapeuticIntAtomFiltered);
    const resetAdmissionMedsAtomFiltered = useResetAtom(admissionMedsAtomFiltered);
    const resetPatMissingAtomFiltered = useResetAtom(patMissingAtomFiltered);

    const resetHighCostAtomShownAlerts = useResetAtom(highCostAtomShownAlerts);
    const resetHighCostAlertsAtomShownAlerts = useResetAtom(highCostAlertsAtomShownAlerts);
    const resetClarificationsAtomShownAlerts = useResetAtom(clarificationsAtomShownAlerts);
    const resetPriorAuthAtomShownAlerts = useResetAtom(priorAuthAtomShownAlerts);
    const resetNonCoveredAtomShownAlerts = useResetAtom(nonCoveredAtomShownAlerts);
    const resetTherapeuticIntAtomShownAlerts = useResetAtom(therapeuticIntAtomShownAlerts);
    const resetAdmissionMedsAtomShownAlerts = useResetAtom(admissionMedsAtomShownAlerts);
    const resetPatMissingAtomShownAlerts = useResetAtom(patMissingAtomShownAlerts);

    // reset alert sort order
    const resetHighCostAtomUserSettings = useResetAtom(highCostAtomUserSettings);
    const resetHighCostAlertsAtomUserSettings = useResetAtom(highCostAlertsAtomUserSettings);
    const resetClarificationsAtomUserSettings = useResetAtom(clarificationsAtomUserSettings);
    const resetPriorAuthAtomUserSettings = useResetAtom(priorAuthAtomUserSettings);
    const resetNonCoveredAtomUserSettings = useResetAtom(nonCoveredAtomUserSettings);
    const resetTherapeuticIntAtomUserSettings = useResetAtom(therapeuticIntAtomUserSettings);
    const resetAdmissionMedsAtomUserSettings = useResetAtom(admissionMedsAtomUserSettings);
    const resetPatMissingAtomUserSettings = useResetAtom(patMissingAtomUserSettings);


    const resetAlertStatesToDefault = () => {
        resetHighCostAtom()
        resetHighCostAlertsAtom()
        resetClarificationsAtom()
        resetPriorAuthAtom()
        resetNonCoveredAtom()
        resetTherapeuticIntAtom()
        resetAdmissionMedsAtom()
        resetPatMissingAtom()

        resetHighCostAtomFilteredCount()
        resetHighCostAlertsAtomFilteredCount()
        resetClarificationsAtomFilteredCount()
        resetPriorAuthAtomFilteredCount()
        resetNonCoveredAtomFilteredCount()
        resetTherapeuticIntAtomFilteredCount()
        resetAdmissionMedsAtomFilteredCount()
        resetPatMissingAtomFilteredCount()

        resetHighCostAtomFiltered()
        resetHighCostAlertsAtomFiltered()
        resetClarificationsAtomFiltered()
        resetPriorAuthAtomFiltered()
        resetNonCoveredAtomFiltered()
        resetTherapeuticIntAtomFiltered()
        resetAdmissionMedsAtomFiltered()
        resetPatMissingAtomFiltered()

        resetHighCostAtomShownAlerts()
        resetHighCostAlertsAtomShownAlerts()
        resetClarificationsAtomShownAlerts()
        resetPriorAuthAtomShownAlerts()
        resetNonCoveredAtomShownAlerts()
        resetTherapeuticIntAtomShownAlerts()
        resetAdmissionMedsAtomShownAlerts()
        resetPatMissingAtomShownAlerts()

        resetHighCostAtomUserSettings()
        resetHighCostAlertsAtomUserSettings()
        resetClarificationsAtomUserSettings()
        resetPriorAuthAtomUserSettings()
        resetNonCoveredAtomUserSettings()
        resetTherapeuticIntAtomUserSettings()
        resetAdmissionMedsAtomUserSettings()
        resetPatMissingAtomUserSettings()
    }

    const loginUser = async (loginInfo) => {
        setUserLoading(true);

        try {

            const tokenRequest = await axios.post(process.env.REACT_APP_API_BASE_URL + '/login', loginInfo)

            SrxApi.defaults.headers.common['Authorization'] = 'bearer ' + tokenRequest.data.token;
            SrxFsApi.defaults.headers.common['Authorization'] = 'bearer ' + tokenRequest.data.token;
            localStorage.setItem('FACI_TOKEN', tokenRequest.data.token)
            toast.success('User Logged In');
            setFaciToken(tokenRequest.data.token);
            setUser(tokenRequest.data.userInfo);

        } catch (err) {
            setFaciToken(null);
            SrxApi.defaults.headers.common['Authorization'] = undefined;
            SrxFsApi.defaults.headers.common['Authorization'] = undefined;
            toast.error('Error ' + err.response.status + ': ' + err.response.statusText);
        } finally {
            setUserLoading(false)
        }
    }

    const logoutUser = () => {
        resetAlertStatesToDefault()
        SrxApi.defaults.headers.common['Authorization'] = undefined;
        SrxFsApi.defaults.headers.common['Authorization'] = undefined;
        localStorage.removeItem('FACI_TOKEN');
        setFaciToken(null)
        toast.success('User Logged Out');
        history.push('/account/login');
    }

    // const logoutUser = () => {
    //     localStorage.removeItem('OMNI_TOKEN');
    //     OmniApi.defaults.headers.common['token'] = undefined;
    //     XOmniApi.defaults.headers.common['token'] = undefined;
    //     toast.success("User logged out", {
    //         theme: 'colored',
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    //     setUser('');
    //     history.push('/account/login');
    //     // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // } 



    return (
        <UserContext.Provider value={{ user, setUser, userLoading, loginUser, logoutUser, faciToken, setFaciToken }}>
            {props.children}
        </UserContext.Provider>
    );
}

