import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Card, CardBody, Breadcrumb, BreadcrumbItem, Button, UncontrolledCollapse, Row, Col,
    UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, Input, CardHeader, ModalHeader, Modal, ModalBody, ModalFooter, Label, FormGroup, Badge, Table, Spinner,
    InputGroup, InputGroupAddon
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import folderOutline from '@iconify/icons-mdi/folder-outline';
import folderOpenOutline from '@iconify/icons-mdi/folder-open-outline';
import folderIcon from '@iconify/icons-mdi/folder';
import axios from 'axios';
import filePdfBox from '@iconify/icons-mdi/file-pdf-box';
import fileJpgBox from '@iconify/icons-mdi/file-jpg-box';
import filePngBox from '@iconify/icons-mdi/file-png-box';
import folderZipOutline from '@iconify/icons-mdi/folder-zip-outline';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import deleteIcon from '@iconify/icons-mdi/delete';
import downloadIcon from '@iconify/icons-mdi/download';
import linkVariant from '@iconify/icons-mdi/link-variant';
import viewGridOutline from '@iconify/icons-mdi/view-grid-outline';
import formatListBulletedSquare from '@iconify/icons-mdi/format-list-bulleted-square';
import fileUploadOutline from '@iconify/icons-mdi/file-upload-outline';
import folderPlusOutline from '@iconify/icons-mdi/folder-plus-outline';
import textBox from '@iconify/icons-mdi/text-box';
import fileDocumentOutline from '@iconify/icons-mdi/file-document-outline';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Select from 'react-select';
import FileUploader from '../components/FileUploader';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import { SelectedPharmaciesContext } from '../contexts/SelectedPharmaciesContext';

import { UserContext } from '../contexts/UserContext';
import SimpleBar from 'simplebar-react';
import { SrxFsApi } from '../api/SrxFsApi';
import { toast } from 'react-toastify';
import homeOutline from '@iconify/icons-mdi/home-outline';
import folderHomeOutline from '@iconify/icons-mdi/folder-home-outline';
import fileIcon from '@iconify/icons-mdi/file';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import chevronRight from '@iconify/icons-mdi/chevron-right';
import plusThick from '@iconify/icons-mdi/plus-thick';
import minusThick from '@iconify/icons-mdi/minus-thick';
import { confirm } from '../utility-components/ConfirmModal';
import informationIcon from '@iconify/icons-mdi/information';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentSave from '@iconify/icons-mdi/content-save';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import restoreIcon from '@iconify/icons-mdi/restore';
import classNames from 'classnames';
import deleteForever from '@iconify/icons-mdi/delete-forever';
import { renderToString } from 'react-dom/server';
import folderPlus from '@iconify/icons-mdi/folder-plus';
import cancelIcon from '@iconify/icons-mdi/cancel';
import subdirectoryArrowRight from '@iconify/icons-mdi/subdirectory-arrow-right';

let minsToShowNewFileIndicator = 60 * 24 * 7;

function getFileIcon(fileType, fileOrFolder) {
    if (fileOrFolder === 'folder')
        return folderIcon
    if (fileType === 'pdf')
        return filePdfBox
    if (fileType === 'jpg')
        return fileJpgBox
    if (fileType === 'png')
        return filePngBox
    if (fileType === 'zip')
        return folderZipOutline
    return fileDocumentOutline
}

function showDifferenceInTime(date) {
    let difference = difference2Parts(date);
    if (difference.days)
        return difference.days > 1 ? difference.days + ' days ago' : difference.days + ' day ago'

    if (difference.hours)
        return difference.hours > 1 ? difference.hours + ' hours ago' : difference.hours + ' hour ago'

    if (difference.minutes)
        return difference.minutes > 1 ? difference.minutes + ' minutes ago' : difference.minutes + ' minute ago'

    return 'Added now'
}

function difference2Parts(date) {
    const secs = Math.floor(Math.abs(Date.now() - date) / 1000);
    const mins = Math.floor(secs / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    // const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;
    // const multiple = (term, n) => n !== 1 ? `${n} ${term}s` : `1 ${term}`;

    return {
        days: days,
        hours: hours % 24,
        hoursTotal: hours,
        minutesTotal: mins,
        minutes: mins % 60,
        seconds: secs % 60,
        secondsTotal: secs,
        //   milliSeconds: millisecs,
        //   get diffStr() {
        //     return `${multiple(`day`, this.days)}, ${
        //       multiple(`hour`, this.hours)}, ${
        //       multiple(`minute`, this.minutes)} and ${
        //       multiple(`second`, this.seconds)}`;
        //   },
        //   get diffStrMs() {
        //     return `${this.diffStr.replace(` and`, `, `)} and ${
        //       multiple(`millisecond`, this.milliSeconds)}`;
        //   },
    };
}

const FileManager = ({ location, history }) => {

    const { facilities } = useContext(SelectedFacilitiesContext);
    const { pharmacies } = useContext(SelectedPharmaciesContext);


    const { faciToken, logoutUser, user } = useContext(UserContext);

    const [dropdownOpen, setDropDownOpen] = useState({
        states: false,
        facilities: false
    })

    const [sidebarFolderClicked, setSidebarFolderClicked] = useState(false)

    const [searchTerm, setSearchTerm] = useState('');

    const [stateFolders, setStateFolders] = useState([]);

    const [facilityFolders, setFacilityFolders] = useState([]);

    const [shownChildren, setShownChildren] = useState([]);


    const [fileView, setFileView] = useState(localStorage.getItem('file-view') || 'grid');

    const [selectedParent, setSelectedParent] = useState(null);

    const [locationArray, setLocationArray] = useState([]);

    const [breadcrumbFolderInfo, setBreadcrumbFolderInfo] = useState([])

    const [topLevelFolders, setTopLevelFolders] = useState([]);

    const [mainViewLoading, setMainViewLoading] = useState(false)

    useEffect(() => {
        if (fileView)
            localStorage.setItem('file-view', fileView)
    }, [fileView])

    useEffect(() => {
        SrxFsApi.get('/topLevelFolders')
            .then(res => setTopLevelFolders(res.data))
            .catch(err => {
                if (err.response?.status === 401) {
                    logoutUser()
                }
            })
    }, [])

    useEffect(() => {
        setLocationArray(location.pathname.split('/').slice(2))
    }, [location.pathname])

    useEffect(() => {

        if (locationArray.length) {
            if (locationArray[0] === 'recycling-bin') {

                if (user?.userType !== 'PharmacyUser')
                    return history.replace('/files')

                setBreadcrumbFolderInfo([{ name: 'Recycling Bin' }])
                setSelectedParent(locationArray[0])
            } else {
                setSelectedParent(locationArray[locationArray.length - 1]);
                SrxFsApi.post('/files/foldernames', locationArray)
                    .then(res => setBreadcrumbFolderInfo(res.data))
                    .catch(err => {
                        if (err.response?.status === 401) {
                            logoutUser()
                        }
                    })
            }
        } else {
            if ((location.pathname.match(/\//g) || []).length === 1) {
                setMainViewLoading(true)
                SrxFsApi.get('/topLevelFolders')
                    .then(res => setShownChildren(res.data))
                    .finally(() => setMainViewLoading(false))
            }
            setSelectedParent(null);
            setBreadcrumbFolderInfo([])
        }
    }, [locationArray])
    console.log(pharmacies);
    let canCreateNewFile = () => {
        console.log(breadcrumbFolderInfo)
        if (breadcrumbFolderInfo?.length > 1) {
            let id = breadcrumbFolderInfo[1]?.facility;
            let fac = facilities.find((f) => f.facID === id);
            let state = facilities.find((s) => s.facState === breadcrumbFolderInfo[1]?.name);
            console.log(state)
            if (breadcrumbFolderInfo[0]?.name === "Facilities" && fac?.permissions?.files_FileSystem_faci_edit) {
                return true;
            }
            if (breadcrumbFolderInfo[0]?.name === "States" && state?.permissions?.files_FileSystem_state_edit) {
                return true;
            } else {
                return false;
            }
        }
    }

    const fileRequestAbortRef = useRef(null);

    useEffect(() => {
        if (selectedParent) {
            if (fileRequestAbortRef.current) fileRequestAbortRef.current.abort();
            const controller = new AbortController();
            fileRequestAbortRef.current = controller;
            setMainViewLoading(true)
            if (selectedParent === 'recycling-bin') {
                if (user?.userType !== 'PharmacyUser')
                    return history.replace('/files')
                SrxFsApi.get('/recycling-bin', { signal: controller.signal })
                    .then(res => setShownChildren(res.data))
                    .finally(() => setMainViewLoading(false))
            } else {
                SrxFsApi.get('/files/' + selectedParent, { signal: controller.signal })
                    .then(res => setShownChildren(res.data))
                    .finally(() => setMainViewLoading(false))

            }
        } else {
            setShownChildren([])
        }
    }, [selectedParent])

    function getBreadcrumbLink(index) {
        let link = '/files'

        for (let i = 0; i < index; i++) {
            link = `${link}/${locationArray[i]}`
        }

        return link
    }

    function getFileIcon(fileType, fileOrFolder) {
        if (fileOrFolder === 'folder')
            return folderOutline
        if (fileType === 'pdf')
            return filePdfBox
        if (fileType === 'jpg')
            return fileJpgBox
        if (fileType === 'png')
            return filePngBox
        if (fileType === 'zip')
            return folderZipOutline
        return fileDocumentOutline
    }

    const [sortBy, setSortBy] = useState({
        sortField: 'name',
        sortDirection: 'asc'
    })

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'date_added':
                if (sortBy.sortDirection === 'asc') {
                    return array.sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => new Date(a.date_added) > new Date(b.date_added) ? -1 : 1)
                }
                else {
                    return array.sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => new Date(a.date_added) < new Date(b.date_added) ? -1 : 1)
                }
            case 'deleted_at':
                if (sortBy.sortDirection === 'asc') {
                    return array.sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => new Date(a.deleted_at) > new Date(b.deleted_at) ? -1 : 1)
                }
                else {
                    return array.sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => new Date(a.deleted_at) < new Date(b.deleted_at) ? -1 : 1)
                }
            case 'name':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.name < b.name ? -1 : 1)
                else
                    return array.sort((a, b) => a.name > b.name ? -1 : 1)
            case 'size':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.size < b.size ? -1 : 1)
                else
                    return array.sort((a, b) => a.size > b.size ? -1 : 1)
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const sortedAndFilteredShownChildren =
        sortByField(shownChildren) // sortByField
            .filter(child => child.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) // filter results to match searchTerm
    // .sort((a, b) => a.file_or_folder === 'folder' ? -1 : 1) // sort folders before files


    const [fileModal, setFileModal] = useState({
        isOpen: false,
        file: null
    });

    const toggleFileModal = (file) => {
        if (fileModal.isOpen) {
            setFileModal({
                isOpen: false,
                file: null
            })
        } else {
            setFileModal({
                isOpen: true,
                file
            })
        }
    }

    const [createFolderModal, setCreateFolderModal] = useState(false);

    const toggleCreateFolderModal = () => {
        setCreateFolderModal(!createFolderModal)
    }

    const [createFileModal, setCreateFileModal] = useState(false);

    const toggleCreateFileModal = () => {
        setCreateFileModal(!createFileModal)
    }

    const downloadFile = (file) => {
        SrxFsApi.get('/downloadbase64/' + file.file_id)
            .then(res => {
                const link = document.createElement('a');
                link.href = `data:${file.file_or_folder === 'file' ? file.full_file_type : 'application/zip'};base64,${res.data}`;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                if (err.response?.status === 401) {
                    logoutUser()
                }
                toast.error('Error downloading file/folder')
            })
    }

    const editFileInfo = (file) => {
        SrxFsApi.put('/files/' + file.file_id, file)
            .then(res => {
                toast.success('File/Folder Info successfully updated');

                let tempShownChildren = [
                    ...shownChildren
                ]

                tempShownChildren.find(shownFile => shownFile.file_id === file.file_id).description = file.description;
                tempShownChildren.find(shownFile => shownFile.file_id === file.file_id).name = file.name;
                setShownChildren(tempShownChildren)
            })
            .catch(err => toast.success('File/Folder Info Update Error'))
    }

    const [restoreFileModal, setRestoreFileModal] = useState({
        state: false
    })

    function toggleRestoreFileModal() {
        setRestoreFileModal({
            state: false,
            file: null
        })
    }

    const restoreFile = async (file) => {
        SrxFsApi.get('/pre-restore/' + file.file_id)
            .then(async res => {
                if (res.data.deletedParentFolders) {
                    if (await confirm(`
                ${file.pathname_at_deletion ? `This file was deleted from: ${renderToString(folderStructure(file.pathname_at_deletion))}<br /><br />` : ''}
                <p>One of the folders containing this ${file.file_or_folder} has been deleted.</p> 
                <p>Would you like to restore this ${file.file_or_folder} to a new location?</p>
                `, `Restoring ${file.file_or_folder}`, "Restore to New Location", "Cancel", "primary", "primary") === false) return
                    setRestoreFileModal({
                        state: true,
                        file: file
                    })
                } else {
                    console.log({ structure: renderToString(folderStructure(res.data.currentPath)) })
                    if (await confirm(`"${file.name}" will be restored to ${renderToString(folderStructure(res.data.currentPath))}<br /><br />Please Confirm.`, `Restoring ${file.file_or_folder}`, "Restore", "Cancel", "primary", "primary") === false) return
                    SrxFsApi.post('/restore', {
                        fileId: file.file_id
                    })
                        .then(res => {
                            setShownChildren(shownChildren.filter(child => child.file_id !== file.file_id))
                            toast.success(file.name + ' successfully restored')
                        })
                        .catch(err => toast.error('Error: Error restoring file'))
                }
            })
    }

    const softDeleteFile = async (file) => {

        if (await confirm(`Are you sure you want to delete '${file.name}'?`, `Deleting ${file.file_or_folder}`, "Delete", "Cancel", "danger", "danger") === false) return

        let deletingFileToast = toast.loading('Deleting...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxFsApi.delete('/files/' + file.file_id)
            .then(res => {
                console.log(res)
                setShownChildren(shownChildren.filter(shownFile => shownFile.file_id !== file.file_id))
                toast.update(deletingFileToast,
                    {
                        render: '"' + file.name + '"' + ' has been deleted',
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
            .catch(err => {
                console.log(err.response)
                toast.update(deletingFileToast,
                    {
                        render: `${err.response.data.message}:${err.response.hasOwnProperty('data') ? err.response.data.message : 'Error'}`,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
    }

    async function hardDeleteFile(file) {
        if (await confirm(`Would you like to permanently delete '${file.name}'?<p>
            <p><i>Warning: Permanently deleted files/folders cannot be recovered</i></p>
        `, `Permanently deleting ${file.file_or_folder}`, "Permanently Delete", "Cancel", "danger", "danger") === false) return

        let deletingFileToast = toast.loading('Permanently Deleting...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxFsApi.delete('/delete/files/' + file.file_id)
            .then(res => {
                console.log(res)
                setShownChildren(shownChildren.filter(shownFile => shownFile.file_id !== file.file_id))
                toast.update(deletingFileToast,
                    {
                        render: '"' + file.name + '"' + ' has been permanently deleted',
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
            .catch(err => {
                console.log(err.response)
                toast.update(deletingFileToast,
                    {
                        render: `${err.response.data.message}:${err.response.hasOwnProperty('data') ? err.response.data.message : 'Error'}`,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
    }

    const FileOrFolderSquare = ({ file, pathname, downloadFile }) => {

        let history = useHistory();

        const { name, file_type: type, file_or_folder: fileOrFolder, file_id, editable } = file;

        const [isHovered, setIsHovered] = useState(false);

        function getNumberOfDays(start) {
            const date1 = new Date(start);
            const date2 = new Date();

            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;

            // Calculating the time difference between two dates
            const diffInTime = date2.getTime() - date1.getTime();

            // Calculating the no. of days between two dates
            const diffInDays = Math.floor(diffInTime / oneDay);

            return diffInDays;
        }

        return (
            <Col sm={6} md={4} lg={3} xl={2}>
                <Card style={{
                    height: '165px', backgroundColor: isHovered ? '#FBFBFB' : 'white',
                    overflow: 'hidden'
                }}
                    className="file-square"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {
                        if (selectedParent === 'recycling-bin') {
                            toggleFileModal(file)
                        }

                        else if (fileOrFolder === 'folder') {
                            history.push(pathname + '/' + file_id)
                        }

                        else if (fileOrFolder === 'file') {
                            toggleFileModal(file)
                        }
                    }}
                >
                    <CardBody className='d-flex flex-column align-items-center'>
                        <Badge color={'info'} className="position-absolute" style={{ left: '5px', top: '5px' }}>
                            {!file.deleted && (difference2Parts(new Date(file.date_added)).minutesTotal < minsToShowNewFileIndicator) && showDifferenceInTime(new Date(file.date_added))}
                        </Badge>
                        {!file.deleted && ((difference2Parts(new Date(file.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(file.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator)) && <FlashingIndicator style={{ top: '5px', right: '5px', position: 'absolute' }} />}
                        {(isHovered) && <UncontrolledButtonDropdown className="position-absolute" style={{ right: '5px', top: '5px' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                <Icon icon={dotsHorizontal} width="23" height="23" />
                            </DropdownToggle>
                            <DropdownMenu right style={{ minWidth: 'max-content' }}>
                                <DropdownItem className='py-1'
                                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        toggleFileModal(file)
                                    }}
                                // onClick={() => toggleViewUsersModal(facility)}
                                ><Icon icon={informationIcon} width="20" height="20" className="mr-1" /> View</DropdownItem>
                                {file.top_level_folder !== 'top_level' &&
                                    !file.deleted
                                    &&
                                    <DropdownItem className='py-1'
                                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                        onClick={() => downloadFile(file)}
                                    // href={'https://srx-fs.evelt.dev/' + file.full_path}
                                    // download
                                    // disabled={!facility.isAdmin}
                                    // onClick={() => toggleAddUserModal(facility)}
                                    ><Icon icon={downloadIcon} width="20" height="20" className="mr-1" /> Download</DropdownItem>}
                                {/* <DropdownItem className='py-1'
                                style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                // onClick={() => toggleViewUsersModal(facility)}
                                ><Icon icon={linkVariant} width="20" height="20" className="mr-1"/> Copy Link</DropdownItem> */}
                                {editable &&
                                    !file.deleted
                                    &&
                                    <DropdownItem className='py-1'
                                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                        onClick={() => softDeleteFile(file)}
                                    // onClick={() => toggleViewUsersModal(facility)}
                                    ><Icon icon={deleteIcon} width="20" height="20" className="mr-1" /> Delete</DropdownItem>}
                                {file.deleted
                                    &&
                                    <DropdownItem className='py-1'
                                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                        onClick={() => restoreFile(file)}
                                    // onClick={() => toggleViewUsersModal(facility)}
                                    ><Icon icon={restoreIcon} width="20" height="20" className="mr-1" /> Restore</DropdownItem>}
                                {editable &&
                                    file.deleted
                                    &&
                                    <DropdownItem className='py-1'
                                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                        onClick={() => hardDeleteFile(file)}
                                    // onClick={() => toggleViewUsersModal(facility)}
                                    ><Icon icon={deleteForever} width="20" height="20" className="mr-1" /> Permanently Delete</DropdownItem>}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>}
                        <div className='py-2 px-2'
                            style={{ background: '#eef2f7', borderRadius: '5px' }}>
                            <Icon icon={getFileIcon(type, fileOrFolder)} style={{ fontSize: '60px' }} />
                        </div>
                        <div className='py-1' style={{ maxWidth: '140px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'center' }} title={name}><b>{name}</b></div>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    return (
        <>
            <Card className='d-flex flex-row file-manager-height my-2' style={{ background: "#f6f7fa" }}>
                <CardBody style={{ minWidth: '380px', width: '380px', maxWidth: '380px', background: 'white', overflow: 'hidden', paddingTop: '0px' }} className="position-relative">
                    <div className='w-100 border-bottom d-flex justify-content-center align-items-center' style={{
                        height: '50px',
                        borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem',
                        fontSize: '21px'
                    }}><b>File Manager</b></div>
                    {/* <div className='files-navigate-scrolling-size' style={{ overflow: 'auto' }}> */}
                    <SimpleBar className="files-navigate-scrolling-size">
                        {topLevelFolders.map(_file =>
                            <LeftSideBarFolderDropDown file={_file} parentArray={[]} key={_file.file_id} history={history} pathname={location.pathname}
                                locationArray={locationArray} sidebarFolderClicked={sidebarFolderClicked} setSidebarFolderClicked={setSidebarFolderClicked}
                                selectedParent={selectedParent} mainViewLoading={mainViewLoading}
                            />)}
                    </SimpleBar>
                    {topLevelFolders.length > 0 &&
                        user?.userType === 'PharmacyUser' &&
                        <>
                            <hr className='my-1' />
                            <div
                                className={classNames('dropdown-item notify-item mt-2 settings-dropdown sidebar-folder-item position-relative', { selectedRecycling: selectedParent === 'recycling-bin' })}
                                style={{ paddingLeft: '10px' }}
                                onClick={() => history.push(`/files/recycling-bin`)}
                            >
                                <div className='d-flex align-items-center justify-space-between position-relative'>
                                    {(mainViewLoading && selectedParent === 'recyling-bin') ?
                                        <div style={{ minWidth: '25px', minHeight: '25px' }} className="d-flex justify-content-center align-items-center"><Spinner size={'sm'} color='dark' /></div> :
                                        <Icon icon={trashCanOutline} style={{ fontSize: '25px', minWidth: '25px' }}
                                        // onClick={(e) => {
                                        //     if(file.has_folders){
                                        //     e.stopPropagation()
                                        //     onDropdownSideButtonClick()}
                                        // }}
                                        />}
                                    <div className='ml-2' style={{ fontSize: '16px' }}>
                                        Recycling Bin
                                    </div>
                                    <div className='position-absolute d-flex align-items-center justify-content-center' style={{ right: '0px' }}>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </CardBody>
                <CardBody className='ml-2 position-relative' style={{ background: 'white', overflow: 'hidden', paddingTop: '0px' }}>
                    <Row>
                        <div className='w-100 border-bottom' style={{
                            top: '0px', left: '0px', height: '50px',
                            borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem'
                        }}>
                            <Breadcrumb className='ml-2'>
                                {locationArray.length ? <BreadcrumbItem><Link to="/files"><Icon icon={folderHomeOutline} style={{ fontSize: '25px', marginBottom: '3px' }} /></Link></BreadcrumbItem>
                                    :
                                    <BreadcrumbItem active className='d-flex align-items-center'><Icon icon={folderHomeOutline} style={{ fontSize: '25px', marginBottom: '3px' }} /></BreadcrumbItem>
                                }
                                {breadcrumbFolderInfo.map((_folder, i) => (
                                    i === locationArray.length - 1 ?
                                        <BreadcrumbItem active className='d-flex align-items-center'>{_folder.name}</BreadcrumbItem>
                                        :
                                        <BreadcrumbItem className='d-flex align-items-center'><Link to={getBreadcrumbLink(i + 1)}>{_folder.name}</Link></BreadcrumbItem>
                                ))}
                                {/* <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/dashboard">#2</Link></BreadcrumbItem>
                        <BreadcrumbItem active>#3</BreadcrumbItem> */}
                            </Breadcrumb>
                        </div>
                    </Row>
                    <Row className='mb-3 mt-2 d-flex flex-row-reverse justify-content-between'>
                        <Col lg={12} xl={'auto'} className="d-flex justify-content-center  mb-2">
                            <Button className="btn btn-icon" color={fileView === 'grid' ? 'secondary' : 'light'}
                                onClick={() => setFileView('grid')}
                            ><Icon icon={viewGridOutline} style={{ fontSize: '22px' }} /></Button>
                            <Button className="btn btn-icon ml-1" color={fileView === 'list' ? 'secondary' : 'light'}
                                onClick={() => setFileView('list')}
                            ><Icon icon={formatListBulletedSquare} style={{ fontSize: '22px' }} /></Button>
                            {(selectedParent !== 'recycling-bin' && !!locationArray.length && canCreateNewFile()) && <UncontrolledButtonDropdown className="ml-2">
                                <DropdownToggle color="primary" style={{ borderRadius: '0.15rem' }}>
                                    + Add New
                                </DropdownToggle>
                                <DropdownMenu>
                                    {/* <DropdownItem header>Header</DropdownItem> */}
                                    <DropdownItem
                                        onClick={toggleCreateFolderModal}
                                    ><Icon icon={folderPlusOutline} style={{ marginBottom: '3px', fontSize: '21px' }} /> Create Folder</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={toggleCreateFileModal}
                                    ><Icon icon={fileUploadOutline} style={{ marginBottom: '3px', fontSize: '21px' }} /> Upload File</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>}
                        </Col>
                        <Col lg={12} xl={'auto'} className="d-flex justify-content-center">
                            <Input placeholder="Search file name..." style={{ width: '300px' }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            ></Input>
                        </Col>
                    </Row>
                    <Row className={`d-flex justify-content-start files-display-scrolling-size ${(mainViewLoading || shownChildren.length < 1) && 'h-50'}`} style={{ overflow: 'auto' }}>
                        {mainViewLoading ?
                            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                <div>
                                    {/* <h5 className='mb-1'>Loading files/folders...</h5> */}
                                    <div className="bouncing-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            :
                            shownChildren.length > 0 ?
                                fileView === 'grid' ?
                                    sortedAndFilteredShownChildren
                                        .map(file => (
                                            <FileOrFolderSquare file={file} history={history} pathname={location.pathname} downloadFile={downloadFile} deleteFile={softDeleteFile}
                                                selectedParent={selectedParent}
                                                restoreFile={restoreFile}
                                            />
                                        ))
                                    :
                                    <FileList
                                        selectedParent={selectedParent}
                                        sortedAndFilteredShownChildren={sortedAndFilteredShownChildren}
                                        history={history} pathname={location.pathname}
                                        downloadFile={downloadFile}
                                        restoreFile={restoreFile}
                                        toggleFileModal={toggleFileModal}
                                        deleteFile={softDeleteFile}
                                        hardDeleteFile={hardDeleteFile}
                                        onClickSort={onClickSort}
                                        sortBy={sortBy}
                                    />
                                :
                                <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
                                    <h3 className='text-muted'>No Files/Folders</h3>
                                </div>
                        }
                    </Row>
                    <FileModal isOpen={fileModal.isOpen} toggle={toggleFileModal} file={fileModal.file} downloadFile={downloadFile} editFileInfo={editFileInfo} />
                    <CreateFolderModal isOpen={createFolderModal} toggle={toggleCreateFolderModal} selectedParent={selectedParent} setShownChildren={setShownChildren} shownChildren={shownChildren} faciToken={faciToken} />
                    <CreateFileModal isOpen={createFileModal} toggle={toggleCreateFileModal} selectedParent={selectedParent} setShownChildren={setShownChildren} shownChildren={shownChildren} faciToken={faciToken} />
                </CardBody>
                <RestoreFileModal isOpen={restoreFileModal.state} file={restoreFileModal.file} toggle={toggleRestoreFileModal}
                    topLevelFolders={topLevelFolders}
                    setShownChildren={setShownChildren}
                    shownChildren={shownChildren}
                />
            </Card>
        </>
    )
}

const LeftSideBarFolderDropDown = ({ file, parentArray, history, pathname, locationArray, sidebarFolderClicked, setSidebarFolderClicked, selectedParent, mainViewLoading }) => {

    const { logoutUser } = useContext(UserContext)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [children, setChildren] = useState([]);

    const [isHovered, setIsHovered] = useState(false)

    const [hasFolderChildren, setHasFolderChildren] = useState(true)

    // useEffect(() => {
    //     if(locationArray.length && !sidebarFolderClicked){
    //         if(locationArray.includes(file.file_id)){
    //             setLoading(true)
    //             SrxFsApi.get('/files/' + file.file_id)
    //             .then(res => {
    //                 let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
    //                 if(childrenFolders.length){
    //                     setIsDropdownOpen(prevState => (!prevState))
    //                     setChildren(childrenFolders)
    //                 } else {
    //                     console.log(parentArray)
    //                     // window.history.replaceState(null, "", '/files/' + parentArray.join('/') + '/' + file.file_id)
    //                     // history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
    //                 }
    //             })
    //             .finally(() => setLoading(false))
    //         }
    //     }
    // }, [locationArray])

    // useEffect(() => {
    //     if(isDropdownOpen){
    //         SrxFsApi.get('/files/' + file.file_id)
    //         .then(res => {
    //             let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
    //             if(childrenFolders.length){
    //                 setChildren(childrenFolders)
    //             } else {
    //                 console.log(parentArray)
    //                 history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
    //             }
    //         })
    //     }
    // }, [isDropdownOpen])

    const onDropdownSideButtonClick = () => {
        // setSidebarFolderClicked(true)
        if (!isDropdownOpen) {
            setLoading(true)
            SrxFsApi.get('/files/' + file.file_id)
                .then(res => {
                    let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
                    if (childrenFolders.length) {
                        setHasFolderChildren(true)
                        setIsDropdownOpen(prevState => (!prevState))
                        setChildren(childrenFolders)
                    } else {
                        setHasFolderChildren(false)
                        toast.warning('No additional folders')
                        // setSidebarFolderClicked(false)
                        // window.history.replaceState(null, "", '/files/' + parentArray.join('/') + '/' + file.file_id)
                        // history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
                    }
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        logoutUser()
                    }
                })
                .finally(() => setLoading(false))
        } else {
            setChildren([])
            setIsDropdownOpen(prevState => (!prevState))
        }
    }

    const onDropdownClick = () => {
        // setSidebarFolderClicked(true)
        // if(mainViewLoading) return 'loading'
        history.push(`/files/${parentArray.length ? parentArray.join('/') + '/' : ''}${file.file_id}`)

    }

    return (
        <>
            <div className={`dropdown-item notify-item mt-2 settings-dropdown sidebar-folder-item position-relative ${selectedParent === file.file_id && 'selected-leftside-dropdown'}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                title={file.name}
                style={{ paddingLeft: `${!isDropdownOpen ? (25 * parentArray.length) + 10 : 10}px`, paddingRight: '0px', maxWidth: '332px', overflow: 'hidden' }}
                onClick={onDropdownClick}
            >
                {selectedParent === file.file_id && <div style={{ height: '100%', width: '5px', top: '0px', left: '0px', background: '#6c757d', position: 'absolute' }}></div>}
                <div className='d-flex align-items-center justify-space-between position-relative'>
                    {isDropdownOpen && parentArray.map(_parent => <Icon icon={chevronRight} style={{ fontSize: '25px', opacity: '0.5', minWidth: '25px' }} />)}{(loading || (mainViewLoading && selectedParent === file.file_id)) ?
                        <div style={{ minWidth: '25px', minHeight: '25px' }} className="d-flex justify-content-center align-items-center"><Spinner size={'sm'} color='dark' /></div> :
                        <Icon icon={isDropdownOpen ? folderOpenOutline : folderOutline} style={{ fontSize: '25px', minWidth: '25px' }}
                            // onClick={(e) => {
                            //     if(file.has_folders){
                            //     e.stopPropagation()
                            //     onDropdownSideButtonClick()}
                            // }}
                            className={((difference2Parts(new Date(file.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(file.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator)) && 'flashingIndicatorforFileManager'}
                        />}
                    <div className='ml-2' style={{ fontSize: '16px' }}>{file.name.substring(0, (25 - (parentArray.length * 2.7))).trim()}{file.name.length > (18 + (parentArray.length * 2.7)) && <b>...</b>}
                        {/* {((difference2Parts(new Date(file.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(file.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator)) && <FlashingIndicator style={{ top: '-3px', right: '-13px', position: 'absolute' }} />} */}
                    </div>
                    <div className='position-absolute d-flex align-items-center justify-content-center' style={{ right: '0px' }}>
                        {file.has_folders &&
                            <Button color="link" className='link-dropdown-side-button' style={{ fontSize: '18px', color: '#343a40' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onDropdownSideButtonClick()
                                }}
                            >
                                <Icon icon={!isDropdownOpen ? plusThick : minusThick} />
                            </Button>}
                    </div>
                </div>
            </div>
            <div>
                {children.sort((a, b) => a.name < b.name ? -1 : 1).map(_file => (
                    // <Link
                    //     // disables={item.disabled}
                    //     to={'/files/' + folder.file_id}
                    //     className="dropdown-item notify-item"
                    //     // key={i + '-profile-menu'}
                    //     >
                    //     <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    //     <span style={{ fontSize: '14px' }} className="ml-1 position-relative">{folder.name}{((difference2Parts(new Date(folder.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(folder.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator)) && <FlashingIndicator style={{ top: '-5px', right: '-13px', position: 'absolute' }} />}</span>
                    // </Link>
                    <LeftSideBarFolderDropDown file={_file} parentArray={[...parentArray, file.file_id]}
                        key={_file.file_id} history={history} pathname={pathname} locationArray={locationArray}
                        sidebarFolderClicked={sidebarFolderClicked} setSidebarFolderClicked={setSidebarFolderClicked}
                        selectedParent={selectedParent} mainViewLoading={mainViewLoading}
                    />
                ))
                }
                {/* <Link
                    // disables={item.disabled}
                    to={'/facilities'}
                    className="dropdown-item notify-item"
                    // key={i + '-profile-menu'}
                    >
                    <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    <span style={{ fontSize: '14px' }} className="ml-1">NY</span>
                </Link>
                <Link
                    // disables={item.disabled}
                    to={'/files/facilities'}
                    className="dropdown-item notify-item"
                    // key={i + '-profile-menu'}
                    >
                    <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    <span style={{ fontSize: '14px' }} className="ml-1">NJ</span>
                </Link> */}
            </div>
        </>
    )
}

const RestoreModalFileDropdown = ({ file, parentArray, topLevelFolders, setSelectedFolder, selectedFolder, currentlyEditingId, setCurrentlyEditingId }) => {

    const { logoutUser } = useContext(UserContext)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [children, setChildren] = useState([]);

    const [isHovered, setIsHovered] = useState(false)

    const [hasFolderChildren, setHasFolderChildren] = useState(false);

    const elementRef = useRef(null)

    // useEffect(() => {
    //     if(locationArray.length && !sidebarFolderClicked){
    //         if(locationArray.includes(file.file_id)){
    //             setLoading(true)
    //             SrxFsApi.get('/files/' + file.file_id)
    //             .then(res => {
    //                 let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
    //                 if(childrenFolders.length){
    //                     setIsDropdownOpen(prevState => (!prevState))
    //                     setChildren(childrenFolders)
    //                 } else {
    //                     console.log(parentArray)
    //                     // window.history.replaceState(null, "", '/files/' + parentArray.join('/') + '/' + file.file_id)
    //                     // history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
    //                 }
    //             })
    //             .finally(() => setLoading(false))
    //         }
    //     }
    // }, [locationArray])

    // useEffect(() => {
    //     if(isDropdownOpen){
    //         SrxFsApi.get('/files/' + file.file_id)
    //         .then(res => {
    //             let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
    //             if(childrenFolders.length){
    //                 setChildren(childrenFolders)
    //             } else {
    //                 console.log(parentArray)
    //                 history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
    //             }
    //         })
    //     }
    // }, [isDropdownOpen])

    function openDropdown() {
        setLoading(true)
        SrxFsApi.get('/files/' + file.file_id)
            .then(res => {
                let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
                if (childrenFolders.length) {
                    setHasFolderChildren(true)
                    setIsDropdownOpen(true)
                    setChildren(childrenFolders)
                } else {
                    setHasFolderChildren(false)
                    toast.warning('No additional folders')
                    // setSidebarFolderClicked(false)
                    // window.history.replaceState(null, "", '/files/' + parentArray.join('/') + '/' + file.file_id)
                    // history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
                }
            })
            .catch(err => {
                if (err.response?.status === 401) {
                    logoutUser()
                }
            })
            .finally(() => setLoading(false))
    }

    const onDropdownSideButtonClick = () => {
        // setSidebarFolderClicked(true)
        if (!isDropdownOpen) {
            setLoading(true)
            SrxFsApi.get('/files/' + file.file_id)
                .then(res => {
                    let childrenFolders = res.data.filter(_file => _file.file_or_folder === 'folder');
                    if (childrenFolders.length) {
                        setHasFolderChildren(true)
                        setIsDropdownOpen(prevState => (!prevState))
                        setChildren(childrenFolders)
                    } else {
                        setHasFolderChildren(false)
                        toast.warning('No additional folders')
                        // setSidebarFolderClicked(false)
                        // window.history.replaceState(null, "", '/files/' + parentArray.join('/') + '/' + file.file_id)
                        // history.push('/files/' + parentArray.join('/') + '/' + file.file_id)
                    }
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        logoutUser()
                    }
                })
                .finally(() => setLoading(false))
        } else {
            setChildren([])
            setIsDropdownOpen(prevState => (!prevState))
        }
    }

    const onDropdownClick = () => {
        if (file.top_level_folder === 'top_level')
            return
        setSelectedFolder(file)
        // setSidebarFolderClicked(true)
        // if(mainViewLoading) return 'loading'
        // history.push(`/files/${parentArray.length ? parentArray.join('/') + '/' : ''}${file.file_id}`)

    }

    const [newFolder, setNewFolder] = useState({
        name: '',
        description: '',
        fileOrFolder: 'folder'
    })

    const [creatingFolderLoading, setCreatingFolderLoading] = useState(false)

    const createFolder = () => {

        setCreatingFolderLoading(true)

        let addingFolderToast = toast.loading('Adding folder...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxFsApi.post('/files/' + file.file_id, newFolder)
            .then(res => {
                setNewFolder({
                    name: '',
                    description: '',
                    fileOrFolder: 'folder'
                })

                let createdFolder = res.data;

                setHasFolderChildren(true)

                if (!isDropdownOpen) {
                    openDropdown()
                } else {
                    setTimeout(() => setChildren([
                        ...children,
                        createdFolder
                    ]), 200)
                }

                setSelectedFolder(createdFolder)

                toast.update(addingFolderToast,
                    {
                        render: `"${res.data.name}" folder added to "${file.name}"`,
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

                setCurrentlyEditingId(null)
            })
            .catch(err => {
                toast.update(addingFolderToast,
                    {
                        render: `Error #${err.response.status}: ${err.response.data.message}`,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
            .finally(() => setCreatingFolderLoading(false))
    }

    useEffect(() => {

        if (selectedFolder?.file_id === file?.file_id) {
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    }, [])

    return (
        <>
            <div className={`${file.top_level_folder === 'top_level' && 'no-click'} dropdown-item notify-item my-1 settings-dropdown sidebar-folder-item position-relative ${selectedFolder?.file_id === file?.file_id && 'selectedModalFolder'}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                title={file?.name}
                style={{ paddingLeft: `${!isDropdownOpen ? (25 * parentArray.length) + 10 : 10}px`, paddingRight: '0px', overflow: 'hidden' }}
                onClick={onDropdownClick}
                ref={elementRef}
            >
                <div className='d-flex align-items-center justify-space-between position-relative'>
                    {isDropdownOpen && parentArray.map(_parent => <Icon icon={chevronRight} style={{ fontSize: '25px', opacity: '0.5', minWidth: '25px' }} />)}{(loading) ?
                        <div style={{ minWidth: '25px', minHeight: '25px' }} className="d-flex justify-content-center align-items-center"><Spinner size={'sm'} color='dark' /></div> :
                        <Icon icon={isDropdownOpen ? folderOpenOutline : folderOutline} style={{ fontSize: '25px', minWidth: '25px' }}
                        // onClick={(e) => {
                        //     if(file?.has_folders){
                        //     e.stopPropagation()
                        //     onDropdownSideButtonClick()}
                        // }}
                        />}
                    <div className='ml-2' style={{ fontSize: '16px' }}>{file?.name.substring(0, (25 - (parentArray.length * 2.7))).trim()}{file?.name.length > (18 + (parentArray.length * 2.7)) && <b>...</b>}
                        {/* {((difference2Parts(new Date(file?.date_added)).minutesTotal < minsToShowNewFile?Indicator) || (difference2Parts(new Date(file?.file?_last_added_at)).minutesTotal < minsToShowNewFile?Indicator)) && <FlashingIndicator style={{ top: '-3px', right: '-13px', position: 'absolute' }} />} */}
                    </div>
                    <div className='position-absolute d-flex align-items-center justify-content-center' style={{ right: '0px' }}>
                        {(file.top_level_folder === 'top_level') ?
                            ''
                            :
                            isHovered
                                ?
                                <Button color="link" className={`px-1 link-dropdown-side-button ${file.top_level_folder === 'top_level' && 'enable-click'}`} style={{ fontSize: '18px', color: '#343a40' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        // if(isDropdownOpen){
                                        //     onDropdownSideButtonClick()
                                        // }
                                        if (currentlyEditingId) {
                                            if (currentlyEditingId === file?.file_id)
                                                setCurrentlyEditingId(null)
                                            else
                                                setCurrentlyEditingId(file.file_id)
                                            setNewFolder({
                                                name: '',
                                                description: '',
                                                fileOrFolder: 'folder'
                                            })
                                        } else {
                                            if (!isDropdownOpen) {
                                                if (file.has_folders || hasFolderChildren)
                                                    openDropdown()
                                            }
                                            setCurrentlyEditingId(file.file_id)
                                        }
                                    }}
                                >
                                    <Icon icon={currentlyEditingId === file?.file_id ? cancelIcon : folderPlus} />
                                </Button>
                                :
                                ''
                        }
                        {(file?.has_folders || hasFolderChildren || (file?.top_level_folder === 'top_level' && file?.name.toLowerCase() === 'states')) &&
                            <Button color="link" className={`px-1 link-dropdown-side-button ${file.top_level_folder === 'top_level' && 'enable-click'}`} style={{ fontSize: '18px', color: '#343a40' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onDropdownSideButtonClick()
                                }}
                            >
                                <Icon icon={!isDropdownOpen ? plusThick : minusThick} />
                            </Button>}
                    </div>
                </div>
            </div>
            <div>
                {currentlyEditingId === file?.file_id &&
                    <div
                        style={{ paddingLeft: `${(25 * (parentArray.length)) + 20}px`, paddingRight: '10px', overflow: 'hidden' }}
                        className="d-flex align-items-center"
                    >
                        <Icon icon={subdirectoryArrowRight} width="25" />
                        <InputGroup>
                            <Input
                                style={{ maxHeight: '30px' }}
                                className='py-0'
                                placeholder='Add Folder...'
                                value={newFolder.name}
                                onKeyPress={(e) => {
                                    if (!newFolder.name || creatingFolderLoading)
                                        return
                                    if (e.key === 'Enter')
                                        createFolder()
                                }}
                                onChange={(e) => {
                                    setNewFolder({
                                        ...newFolder,
                                        name: e.target.value
                                    })
                                }}
                            >
                            </Input>
                            <InputGroupAddon
                                addonType="append">
                                <Button
                                    disabled={!newFolder.name || creatingFolderLoading}
                                    onClick={createFolder}
                                    className="btn btn-secondary input-group-text py-0 px-1"
                                >
                                    {creatingFolderLoading ? <Spinner size={'sm'} /> : <Icon icon={contentSave} width="22" height="22" />}
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                }
                {children.sort((a, b) => a.name < b.name ? -1 : 1).map(_file => (
                    // <Link
                    //     // disables={item.disabled}
                    //     to={'/files/' + folder.file_id}
                    //     className="dropdown-item notify-item"
                    //     // key={i + '-profile-menu'}
                    //     >
                    //     <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    //     <span style={{ fontSize: '14px' }} className="ml-1 position-relative">{folder.name}{((difference2Parts(new Date(folder.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(folder.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator)) && <FlashingIndicator style={{ top: '-5px', right: '-13px', position: 'absolute' }} />}</span>
                    // </Link>
                    <RestoreModalFileDropdown file={_file} parentArray={[...parentArray, file.file_id]}
                        key={_file.file_id} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder}
                        currentlyEditingId={currentlyEditingId}
                        setCurrentlyEditingId={setCurrentlyEditingId}
                    />
                ))
                }
                {/* <Link
                    // disables={item.disabled}
                    to={'/facilities'}
                    className="dropdown-item notify-item"
                    // key={i + '-profile-menu'}
                    >
                    <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    <span style={{ fontSize: '14px' }} className="ml-1">NY</span>
                </Link>
                <Link
                    // disables={item.disabled}
                    to={'/files/facilities'}
                    className="dropdown-item notify-item"
                    // key={i + '-profile-menu'}
                    >
                    <Icon icon={folderIcon} style={{ fontSize: '20px', marginBottom: '3px' }}/>
                    <span style={{ fontSize: '14px' }} className="ml-1">NJ</span>
                </Link> */}
            </div>
        </>
    )
}

const FileModal = ({ isOpen, toggle, file, downloadFile, editFileInfo }) => {

    // const { name, file_type: type, file_or_folder: fileOrFolder, file_id } = file;

    const [editInfoState, setEditInfoState] = useState(false);

    const [modalFile, setModalFile] = useState('');

    useEffect(() => {
        if (file) setModalFile(file)
    }, [file])

    function getNumberOfDays(start) {
        const date1 = new Date(start);
        const date2 = new Date();

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;

        // Calculating the time difference between two dates
        const diffInTime = date2.getTime() - date1.getTime();

        // Calculating the no. of days between two dates
        const diffInDays = Math.floor(diffInTime / oneDay);

        return diffInDays;
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    if (!file)
        return <></>

    const preToggle = () => {
        setEditInfoState(false);
        toggle()
    }


    return (
        <Modal isOpen={isOpen} toggle={preToggle} size="sm">
            {/* <ModalHeader toggle={toggle}>
                {file.name}
            </ModalHeader> */}
            <Card className='mb-0 position-relative'>
                <CardHeader className={'bg-light d-flex justify-content-center'}>
                    <Icon icon={getFileIcon(file.file_type, file.file_or_folder)} style={{ fontSize: '120px' }} />
                </CardHeader>
                <CardBody className='pt-0'>
                    <Row className='d-flex justify-content-center' style={{ textAlign: 'center' }}>
                        <Col>
                            {!editInfoState ? <h4>{modalFile.name}</h4>
                                :
                                <InputGroup className='my-1'>
                                    <Input
                                        type="text"
                                        placeholder='Name'
                                        value={modalFile.file_or_folder === 'file' ? modalFile.name.slice(0, modalFile.name.lastIndexOf('.')) : modalFile.name}
                                        rows="4"
                                        onChange={(e) => {
                                            let baseName = e.target.value;
                                            let fileType = modalFile.file_or_folder === 'file' ? modalFile.name.slice(modalFile.name.lastIndexOf('.')) : '';
                                            setModalFile({
                                                ...modalFile,
                                                name: baseName + fileType
                                            })
                                        }}
                                    />
                                    {modalFile.file_or_folder === 'file' && <InputGroupAddon addonType="append">
                                        <span
                                            className="input-group-text py-0 px-1"
                                        >
                                            <b>{modalFile.name.slice(modalFile.name.lastIndexOf('.'))}</b>
                                        </span>
                                    </InputGroupAddon>}
                                </InputGroup>
                            }
                            {!editInfoState ?
                                <p style={{ minHeight: '38px', whiteSpace: 'normal' }} className="my-1 mx-1 d-flex align-items-center position-relative" align="left">
                                    <i>{modalFile.description}</i>
                                    {/* <div className='d-flex justify-content-center align-items-center bg-primary position-absolute edit-file-description' 
                            style={{ width: '35px', height: '35px', right: '-33px', top: '-5px', borderRadius: '20px' }}
                            >
                            <Icon icon={pencilIcon} width="17" height="17" color="white" onClick={() => setEditInfoState(true)} />
                            </div> */}
                                </p> :
                                <InputGroup className='my-1'>
                                    <Input
                                        type="textarea"
                                        placeholder='Add Description...'
                                        value={modalFile.description}
                                        rows="4"
                                        onChange={(e) => setModalFile({
                                            ...modalFile,
                                            description: e.target.value
                                        })}
                                    />
                                    {/* <InputGroupAddon addonType="append">
                                <span
                                    className="btn btn-secondary input-group-text py-0 px-1"
                                    onClick={() => {
                                        editFileInfo(modalFile)
                                        setEditInfoState(false)
                                    }}
                                >
                                    <Icon icon={contentSave}  width="25" height="25"/>
                                </span>
                            </InputGroupAddon> */}
                                </InputGroup>
                            }
                            {file.file_or_folder === 'file' && <p><b><i><span style={{ verticalAlign: "baseline" }}>{file.full_file_type}</span> &#124; <span style={{ verticalAlign: "middle" }}>{formatBytes(file.size)}</span></i></b></p>}
                            <div className='bg-light py-1'><b>{new Date(file.date_added).toLocaleString()}</b> <i> <br /> {showDifferenceInTime(new Date(file.date_added))}</i></div>
                        </Col>
                    </Row>
                </CardBody>
                {
                    file.top_level_folder !== 'top_level' &&
                    !file.deleted &&
                    file.editable &&
                    <Button className='position-absolute link-button p-0' color="link" style={{ color: '#6c757d', top: '0px', right: '40px', fontSize: '25px' }}
                        onClick={() => {
                            if (editInfoState) {
                                editFileInfo(modalFile)
                                setEditInfoState(false)
                            } else {
                                setEditInfoState(true)
                            }
                        }}
                    ><Icon icon={!editInfoState ? pencilIcon : contentSave} /></Button>}
                {file.top_level_folder !== 'top_level' &&
                    !file.deleted &&
                    <Button className='position-absolute link-button p-0' color="link" style={{ color: '#6c757d', top: '0px', right: '5px', fontSize: '25px' }}
                        onClick={() => downloadFile(file)}
                    ><Icon icon={downloadIcon} /></Button>}
            </Card>
            {/* <ModalBody>
                <Row className='d-flex justify-content-center'>
                    <Col sm={'auto'}>
                        <div>
                        <Icon icon={getFileIcon(file.file_type, file.file_or_folder)} style={{ fontSize: '100px' }} />
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row className='d-flex justify-content-center' style={{ textAlign: 'center' }}>
                    <Col>
                        <h4>{file.name}</h4>
                        <p><i>{file.description}</i></p>
                        <div className='bg-light py-1'><b>{new Date(file.date_added).toLocaleDateString()}</b> <i>~ {getNumberOfDays(file.date_added)} days ago</i></div>
                    </Col>
                </Row>
            </ModalBody> */}
            {/* <ModalFooter></ModalFooter> */}
        </Modal>
    )
}

const FileList = ({ sortedAndFilteredShownChildren, history, pathname, downloadFile, restoreFile, toggleFileModal, deleteFile, onClickSort, sortBy, selectedParent, hardDeleteFile }) => {

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    return (
        <Table className="mb-0 table-hover">
            <thead className="bg-light">
                <tr>
                    <th></th>
                    <th onClick={() => onClickSort('name')}>Name
                        {(sortBy.sortField === 'name') ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}
                    </th>
                    <th>Type</th>
                    <th onClick={() => onClickSort('size')}>Size
                        {(sortBy.sortField === 'size') ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}
                    </th>
                    {selectedParent === 'recycling-bin' ?
                        <th onClick={() => onClickSort('deleted_at')}>Date Deleted
                            {(sortBy.sortField === 'deleted_at') ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}
                        </th>
                        :
                        <th onClick={() => onClickSort('date_added')}>Date
                            {(sortBy.sortField === 'date_added') ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}
                        </th>}
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {sortedAndFilteredShownChildren
                    .map(file => (
                        <tr onClick={() => {
                            if (selectedParent === 'recycling-bin') {
                                toggleFileModal(file)
                            }

                            else if (file.file_or_folder === 'folder') {
                                history.push(pathname + '/' + file.file_id)
                            }

                            else if (file.file_or_folder === 'file') {
                                toggleFileModal(file)
                            }
                        }}>
                            <td style={{ verticalAlign: 'middle' }} className="pl-1 pr-0">
                                <Icon icon={file.file_or_folder === 'file' ? fileIcon : folderOutline} style={{ fontSize: '26px' }} />
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                                <span className='position-relative'>{file.name}{!file.deleted && ((difference2Parts(new Date(file.date_added)).minutesTotal < minsToShowNewFileIndicator) || (difference2Parts(new Date(file.file_last_added_at)).minutesTotal < minsToShowNewFileIndicator))
                                    &&
                                    <Badge className='position-absolute' style={{ top: '-5px', right: '-35px' }} color="info">New</Badge>}
                                    {file.deleted && file.file_or_folder === 'folder' &&
                                        <Badge className='position-absolute pt-1' style={{ top: '-5px', right: '-60px' }} color="danger"><span style={{ fontSize: '14px' }}><b>{file.deleted_file_count || 0}</b></span> <small>File/s</small></Badge>}
                                </span>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}><b><Badge className='pt-1 badge-outline-secondary' style={{ fontSize: '14px' }}>{file.file_type?.toUpperCase()}</Badge></b></td>
                            <td style={{ verticalAlign: 'middle' }}>{file.size && <Badge color="light" style={{ fontSize: '12px' }}><strong>{formatBytes(file.size)}</strong></Badge>}</td>
                            {selectedParent === 'recycling-bin' ?
                                <td style={{ verticalAlign: 'middle' }}>{new Date(file.deleted_at).toLocaleString()}</td>
                                :
                                <td style={{ verticalAlign: 'middle' }}>{new Date(file.date_added).toLocaleString()}</td>}
                            <td>
                                <div className='d-flex align-items-center'>
                                    <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                        title="Info"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleFileModal(file)
                                        }}
                                    ><Icon icon={informationIcon} /></Button>
                                    {file.deleted &&
                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                            title="Restore"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                restoreFile(file)
                                            }}
                                        ><Icon icon={restoreIcon} /></Button>}
                                    {file.deleted &&
                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                            title="Permanently Delete"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                hardDeleteFile(file)
                                            }}
                                        ><Icon icon={deleteForever} /></Button>}
                                    {!file.deleted && file.top_level_folder !== 'top_level' &&
                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                downloadFile(file)
                                            }}
                                        ><Icon icon={downloadIcon} /></Button>}
                                    {!file.deleted && file.editable &&
                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                deleteFile(file)
                                            }}
                                        ><Icon icon={deleteIcon} /></Button>}
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    )
}

const CreateFolderModal = ({ isOpen, toggle, selectedParent, setShownChildren, shownChildren, faciToken }) => {

    const [folderInfo, setFolderInfo] = useState({
        name: '',
        description: '',
        fileOrFolder: 'folder'
    })

    function handleValidSubmit() {

        let addingFolderToast = toast.loading('Adding folder...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxFsApi.post('/files/' + selectedParent, folderInfo)
            .then(res => {
                setFolderInfo({
                    name: '',
                    description: '',
                    fileOrFolder: 'folder'
                })

                setShownChildren([
                    ...shownChildren,
                    res.data
                ])

                toast.update(addingFolderToast,
                    {
                        render: 'Folder added',
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });

                toggle()
            })
            .catch(err => {
                toast.update(addingFolderToast,
                    {
                        render: `Error #${err.response.status}: ${err.response.data.message}`,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
    }

    function preToggle() {
        setFolderInfo({
            name: '',
            description: '',
            fileOrFolder: 'folder'
        })
        toggle()
    }

    return (
        <Modal isOpen={isOpen} toggle={preToggle} size="sm">
            <Card className='mb-0'>
                <CardHeader>
                    <b>Create Folder</b>
                </CardHeader>
                <CardBody>
                    <AvForm onValidSubmit={() => handleValidSubmit()}>
                        <AvGroup>
                            <Label for="name">Folder Name</Label>
                            <AvField
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Name..."
                                onChange={(e) => setFolderInfo({
                                    ...folderInfo,
                                    name: e.target.value
                                })}
                                value={folderInfo.name}
                                required
                            // validate={{
                            //     minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                            // }}
                            />
                        </AvGroup>
                        <AvGroup>
                            <Label for="description">Folder Description</Label>
                            <AvField
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Description..."
                                onChange={(e) => setFolderInfo({
                                    ...folderInfo,
                                    description: e.target.value
                                })}
                                value={folderInfo.description}
                            // required
                            // validate={{
                            //     minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                            // }}
                            />
                            {/* <AvFeedback>This field is invalid</AvFeedback> */}
                        </AvGroup>
                        <FormGroup className='d-flex justify-content-end'>
                            <Button color="success">Create Folder</Button>
                        </FormGroup>
                    </AvForm>
                </CardBody>
            </Card>
        </Modal>
    )
}

const CreateFileModal = ({ isOpen, toggle, selectedParent, setShownChildren, shownChildren, faciToken }) => {


    const [fileInfo, setFileInfo] = useState({
        name: '',
        description: '',
        fileOrFolder: 'file',
        fileType: 'pdf',
    })

    function preToggle() {
        setPendingFiles([])
        toggle()
    }

    const [pendingFiles, setPendingFiles] = useState([])

    async function handleValidSubmit() {

        if (!pendingFiles.length) return

        let uploadFilePromises = [];


        for (let pendingFile of pendingFiles) {
            let fileObj = new FormData();
            fileObj.append('file', pendingFile);

            let tempFileInfo;

            let uploadFilePromise = SrxFsApi.post('/upload', fileObj)

            uploadFilePromises.push(uploadFilePromise);

        }

        let uploadingFilesToast = toast.loading('Uploading files...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        try {

            let uploadedFiles = await Promise.all(uploadFilePromises)

            let addFilesToDBPromises = []

            uploadedFiles.forEach(({ data }) => {

                let tempFileInfo = {
                    name: data.originalname,
                    description: pendingFiles.find(file => file.name === data.originalname).description,
                    fileType: data.originalname.split('.').pop(),
                    fullFileType: data.mimetype,
                    filenameOnServer: data.filename,
                    fullPath: data.path,
                    size: data.size,
                    fileOrFolder: 'file'
                }

                let addToDBPromise = SrxFsApi.post('/files/' + selectedParent, tempFileInfo)

                addFilesToDBPromises.push(addToDBPromise)
            })

            let addedFilesToDB = await Promise.all(addFilesToDBPromises)

            addedFilesToDB.forEach(({ data }) => {
                setShownChildren(prevState => ([
                    ...prevState,
                    data
                ]))
            })

            toast.update(uploadingFilesToast,
                {
                    render: addedFilesToDB.length > 1 ? `${addedFilesToDB.length} files successfully uploaded` : '1 file successfully uploaded',
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });

            preToggle()

        } catch (err) {
            toast.update(uploadingFilesToast,
                {
                    render: `Error #${err.response.status}: ${err.response.data.message}`,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        }

        // pendingFiles.forEach(async file => {
        //     let fileObj = new FormData();
        //     fileObj.append('file', file);

        //     try{
        //         let uploadFile = await SrxFsApi.post('/upload', fileObj)

        //     let tempFileInfo = {
        //         ...fileInfo,
        //         name: uploadFile.data.originalname,
        //         fileType: uploadFile.data.originalname.split('.').pop(),
        //         fullFileType: uploadFile.data.mimetype,
        //         filenameOnServer: uploadFile.data.filename,
        //         fullPath: uploadFile.data.path,
        //         size: uploadFile.data.size
        //     }

        //     tempFileInfo.formData = fileObj;

        //     SrxFsApi.post('/files/' + selectedParent, tempFileInfo)
        //     .then(res => {
        //         setFileInfo({
        //             name: '',
        //             description: '',
        //             fileOrFolder: 'file',
        //             fileType: '',
        //         })

        //         setShownChildren(prevState => ([
        //             ...prevState,
        //             res.data
        //         ]))

        //     })

        //     } catch(err){
        //         console.log(err)
        //     }

        // })

        // preToggle()
    }

    const fileTypes = [
        { value: 'pdf', label: 'PDF' },
        { value: 'png', label: 'PNG' },
        { value: 'jpg', label: 'JPG' },
        { value: 'zip', label: 'ZIP' }
    ]

    // const uploadFile = (file) => {
    //     let fileObj = new FormData();
    //     fileObj.append('file', file);
    //     console.log(fileObj)
    //     axios.post('https://srx-fs.evelt.dev/upload', fileObj, {
    //         headers: {
    //             'token': process.env.REACT_APP_SRX_FS_SERVER_TOKEN
    //         }
    //     })
    //     .then(console.log)
    //     .catch(console.error)
    // }

    return (
        <Modal isOpen={isOpen} toggle={preToggle}>
            <Card className='mb-0'>
                <CardHeader>
                    <b>Upload Files</b>
                </CardHeader>
                <CardBody>
                    <AvForm onValidSubmit={() => handleValidSubmit()}>
                        {/* <AvGroup>
                        <Label for="name">File Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name..."
                            onChange={(e) => setFileInfo({
                                ...fileInfo,
                                name: e.target.value
                            })}
                            value={fileInfo.name}
                            required
                            // validate={{
                            //     minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                            // }}
                        />
                    </AvGroup>
                    {/* <FormGroup>
                        <Label for="fileType">File Type:</Label>
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        onChange={(e) => setFileInfo({
                            ...fileInfo,
                            fileType: e.value
                        })}
                        value={fileTypes.find(type => type.value == fileInfo.fileType)}
                        // value={newUser.isAdmin}
                        options={fileTypes}>
                        </Select>
                    </FormGroup> */}
                        {/* <AvGroup>
                        <Label for="description">File Description</Label>
                        <AvField
                            type="text"
                            name="description"
                            id="description"
                            placeholder="Description..."
                            onChange={(e) => setFileInfo({
                                ...fileInfo,
                                description: e.target.value
                            })}
                            value={fileInfo.description}
                        />
                        <AvFeedback>This field is invalid</AvFeedback>
                    </AvGroup> */}
                        <FormGroup>
                            <FileUploader
                                onFileUpload={files => {
                                    console.log(files);
                                    setPendingFiles(files)
                                }}
                                setPendingFiles={setPendingFiles}
                                pendingFiles={pendingFiles}
                            />
                        </FormGroup>
                        <FormGroup className='d-flex justify-content-end'>
                            <Button color="success"
                                disabled={!pendingFiles.length}
                            >Upload</Button>
                        </FormGroup>
                    </AvForm>
                </CardBody>
            </Card>
        </Modal>
    )
}

const FlashingIndicator = (props) => {
    return (
        <div style={props.style} className="flashingIndicatorToFadeInAndOut"></div>
    )
}

const RestoreFileModal = ({ isOpen, toggle, file, topLevelFolders, setShownChildren, shownChildren }) => {

    const [selectedFolder, setSelectedFolder] = useState(null)

    const [currentlyEditingId, setCurrentlyEditingId] = useState(null)

    function handleRestore() {
        console.log({ file, selectedFolder })
        SrxFsApi.post('/restore', {
            fileId: file.file_id,
            newParentId: selectedFolder.file_id
        })
            .then(res => {
                toast.success('File successfully restored')
                setShownChildren(shownChildren.filter(child => child.file_id !== file.file_id))
            })
            .finally(() => preToggle())
    }

    function preToggle() {
        setSelectedFolder(null)
        setCurrentlyEditingId(null)
        toggle()
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={preToggle}
        >
            <ModalHeader toggle={toggle}>
                Select New Location
            </ModalHeader>
            <ModalBody>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="ml-2">
                    Selected Folder: <b>{selectedFolder?.name}</b>
                </div>
                <hr />
                <Card className='shadow-none' style={{ backgroundColor: '#F7F8FA' }}>
                    <div className='mx-2 my-2 bg-transparent rounded' style={{ border: '2px solid #dee2e6' }}>
                        <SimpleBar style={{ maxHeight: '300px' }}>
                            {topLevelFolders ?
                                topLevelFolders.map(_file => (
                                    <RestoreModalFileDropdown
                                        file={_file} parentArray={[]} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} key={_file.file_id}
                                        currentlyEditingId={currentlyEditingId}
                                        setCurrentlyEditingId={setCurrentlyEditingId}
                                    />
                                ))
                                :
                                'loading'
                            }
                        </SimpleBar>
                    </div>
                </Card>
            </ModalBody>
            <ModalFooter >
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleRestore}
                    disabled={!selectedFolder}
                >
                    Restore
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

function folderStructure(folderString) {
    let space = '\u00A0 '
    return (
        <div style={{ padding: '10px 20px 10px 20px', background: '#eef2f7', marginTop: '20px', fontSize: '15px', fontWeight: '400', width: '80%' }}>
            {folderString.split('/')
                .filter(x => x)
                .map((folder, index) => (
                    <div style={{ margin: '5px 0 5px 0' }}>{index ? `${space.repeat(index * 2)} \u21B3 ${folder}` : <b>{folder}</b>}</div>
                ))}
        </div>
    )

}

export default FileManager;