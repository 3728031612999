import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Badge,
    Button,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import Barcode from 'react-barcode';
import { SrxApi } from '../api/SrxApi';


function OpenDepositSlip() {
    const [tote, setTote] = useState(null);
    let location = useLocation();
    const query = new URLSearchParams(location.search);
    let facID = query.get('facID');
    let toteID = query.get('toteID');
    let auth = query.get('auth');

    const getFormattedDate = (date) => {
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }

    const getToteDetails = () => {
        SrxApi.get(`/packingslipdetailsopen?facID=${facID}&toteID=${toteID}&auth=${auth}`)
            .then(res => {
                console.log(res);
                setTote(res);
            })
            .catch(err => {
                console.log(err);
                console.log('error');
            })
    }

    useEffect(() => {
        getToteDetails();
    }, [])

    return (
        <div style={{ height: '100vh' }} className='bg-white'>



            <div className='bg-white py-4 px-5'>
                {tote?.data?.map(t => {
                    return (
                        <div className='single-slip' style={{ borderBottom: '1px dashed #dee2e6', marginBottom: '0.75rem' }}>
                            <div>
                                <h4 className='mt-0'>
                                    <span className='mb-0 pl-1 pr-3'>NS: <b className='mr-3'>{t.nsName} ({t.nsID})</b>
                                        Date: <b className='mr-4'>{getFormattedDate(t.shipDate)}</b>
                                        Tote ID:  <b>{tote.data[0]?.toteID}</b>
                                        <b className='float-right bg-light px-1 rounded'>Packing Slip</b>
                                    </span>
                                </h4>
                            </div>
                            <div className='bg-secondary text-white pl-1'>Deliver To:</div>

                            <div className='border py-1 mb-1'>
                                <Row>
                                    <Col>
                                        <p className='mb-0 pl-1'><b>{t.facName} ({t.facID})</b></p>
                                        <p className='mb-0 pl-1'><b>{t.facStreet1} <br />{t.facCity} {t.facState} {t.facZip}</b></p>
                                        <p className='mb-0 pl-1'>Delivery Route: <b>{t.deliveryRoute ?? '-'}</b></p>
                                        <p className='mb-0 pl-1'>
                                            {/* <Barcode fontSize='15' width='1' height='50' value={`TOT${t?.toteID}`} /> */}
                                            <Barcode fontSize={'17'}
                                                width={'2'}
                                                height={'100'}
                                                value={`${t?.toteID}`} />
                                        </p>
                                    </Col>
                                    <Col className='border-left'>
                                        <p className='mb-0'><b>{t.pharmName} ({t.pharmID})</b></p>
                                        <p className='mb-0'><b>{t.pharmStreet1} <br />{t.pharmCity} {t.pharmState} {t.pharmZip}</b></p>
                                        <p className='mb-0'><b>{t.pharmPhone}</b></p>
                                        <p className='mb-0 pl-1 align-right'>
                                            {/* <Barcode fontSize='15' width='1' height='50' value={`PAK${t.psid}`} /> */}
                                            <Barcode fontSize={'17'}
                                                width={'2'}
                                                height={'100'}
                                                value={`PAK${t.psid}`} />
                                        </p>

                                    </Col>
                                </Row>
                            </div>
                            {t?.legalHeader && <div className='text-center'><h3>{t?.legalHeader}</h3></div>}
                            <div>
                                {t?.patientDetails?.map(slip => {
                                    return (<div className=''>
                                        <div className='pl-1 mt-0'>
                                            <b className='mr-2'>{slip.patName} </b><span className='mr-2'>({slip.patID})</span>
                                            Rm:<b className='mr-2'>{slip.room}</b>
                                            Bed:<b>{slip.bed}</b>
                                        </div>
                                        <div className='pl-1 pt-0 patient-drug-details mb-4'>

                                            {slip?.drugDetails?.map(d => {
                                                return (<>
                                                    <div className='single-drug'>
                                                        <Row className='drug-info-line'>
                                                            <Col xs={3} className='pl-2'>
                                                                <div className='pl-2'>Rx:<b className='mr-1'>{d.rxNo}</b> Ro:<b>{d.roNo}</b></div>
                                                            </Col>
                                                            {/* <Col xs={2}>
                                                        Qty: <b>{d.qty}</b>
                                                    </Col> */}
                                                            <Col xs={5}>
                                                                <b>
                                                                    {d.deaClass?.trim().length ?
                                                                        <Badge title='dea class' className='bg-danger mr-1 dea-class' pill >{d?.deaClass}</Badge> : null}
                                                                    Qty: <b>{d.qty}</b> &nbsp; - &nbsp;
                                                                    {d.drugLabelName}
                                                                </b>
                                                            </Col>
                                                            <Col xs={4}>
                                                                By:<b>{d.phName}</b>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className='pl-2'>
                                                                <div className='pl-2 drug-note'>
                                                                    {d.newRx && <b className='mr-1'>{d.newRx}</b>}
                                                                    {d.serialNo &&
                                                                        <>
                                                                            Serial No:
                                                                            <Badge title='serial number' className='bg-secondary mx-1 dea-class' pill >
                                                                                {d.serialNo}
                                                                            </Badge>
                                                                        </>}
                                                                    {d.packSlipMsg ? `*** ${d.packSlipMsg} ***` : ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                                )
                                            })}
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>)
                })}
            </div>
        </div>
    )
}

export default OpenDepositSlip


{/* <div>{`https://devapi.srx365.sprxdev.com/srxdash/packingslipdetailsopen?facID=${facID}&toteID=${toteID}&auth=${auth}`}</div>
<div>{facID}</div>
<div>{toteID}</div>
<div>{auth}</div>
<div>{JSON.stringify(toteDetailed)}</div> */}